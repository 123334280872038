import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from 'sweetalert2';

import { DropDown, AlertUser } from "../../";
import { axiosInstance } from "../../../../config/axios";

function CustomerList() {

    const branchInfo = useSelector(store => store.branch);

    const [search, setSearch] = useState('');
    let [pageNo, setPageNo] = useState(0);
    const [pageInfo, setpageInfo] = useState({
        label: '0-0 of 0',
        total: 0
    });
    const [data, setData] = useState([]);

    const fetchFreshData = () => {
        setPageNo(0);
        fetchData();
    };

    const fetchData = () => {
        axiosInstance
        .post('/people/customer/list-data', {
            search, pageNo
        }).then(function (res) {
            if (res.data.status === 'success') {
                setData(res.data.data.data);
                setpageInfo({
                    total: res.data.data.noofpages,
                    label: `${res.data.data.offset}-${res.data.data.limit} of ${res.data.data.total}`
                });
            }
        }).catch(e => console.log(e));
    };

    useEffect(() => {
        fetchFreshData();
    }, [search, pageNo]);

    const [checkedData, setCheckedData] = useState([]);
    const checkItems = (e)=> {
        let tempIds = JSON.parse(JSON.stringify(checkedData));
        if(e.target.checked) {
            if (!tempIds.includes(e.target.value)) 
                tempIds.push(e.target.value);
        } else {
            const index = tempIds.indexOf(e.target.value);
            if (index !== -1) 
            tempIds.splice(index, 1);
        }
        setCheckedData(tempIds);
    };

    const [ajaxResponse, setAjaxResponse] = useState({});
    useEffect(() => {
        setTimeout(() => {
            setAjaxResponse({});
        }, 5000);
    }, [ajaxResponse]);

    const deleteVendor = ()=> {
        if(checkedData.length > 0) {
            Swal.fire({
                title: "Are you sure?",
                text: "You wan't to delete !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "Cancel",
            }).then((result) => {
                if (result.isConfirmed) {
                    axiosInstance.post("/people/vendor/vendor-delete", {
                        id: checkedData
                    }).then(function (res) {
                        setAjaxResponse({ status: res.data.status, message: res.data.message });
                        fetchFreshData();
                    }).catch(err => console.log(err));
                }
            });
        }
    };

    const dropDownAction = [
        { icon: 'fe fe-trash-2', text: 'Delete', callback: deleteVendor }
    ];

    return <>
        <div className="section-body content-area mb-0">
            <div className="container-fluid">
                <div className="row border-bottom">
                    <div className="col col-sm-8 input-group d-flex justify-content-start">
                        <div className="col-md-3 col-11 px-0 input-icon table-head-search">
                            <input type="text" className="form-control" placeholder="Search..." value={search} onChange={(e)=>setSearch(e.target.value)} />
                            <span className="input-icon-addon"><i className="fe fe-search"></i></span>
                        </div>
                        <span className="input-group-append"><Link to="/people/vendor/create-vendor" className="btn c-red text-white"> <i className="fe fe-plus"></i> New Vendor</Link></span>
                        <DropDown color="c-lgt-blue" icon="fe fe-sliders" items={dropDownAction} />
                    </div>
                    <div className="col-2 col-sm-4 d-flex justify-content-end mb-1 table-nav mt-1">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            if (pageNo > 0) setPageNo(--pageNo);
                        }}>
                            <i className="fe fe-chevron-left pr-sm-1 pr-2"></i>
                        </a>
                        <span className="px-1 d-none d-sm-block">{pageInfo.label}</span>
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            if (pageNo < pageInfo.total) setPageNo(++pageNo);
                        }}>
                            <i className="fe fe-chevron-right pl-sm-1 pl-2"></i>
                        </a>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body px-1">
                        <div className="table-responsive ast-table">
                            <table className="table table-striped table-vcenter mb-0 text-nowrap">
                                <thead>
                                    <tr>
                                        <th><span className="table-checkbox-head">&nbsp;</span> Name</th>
                                        <th>Phone Number</th>
                                        <th>Email</th>
                                        <th>No Of Visits</th>
                                        <th>Total</th>
                                        <th>Credit</th>
                                        <th>Last Purchased</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data.map((el, i)=>(
                                    <tr key={el.id}>
                                        <td>
                                            <span className="d-flex">
                                                <label className="custom-control custom-checkbox mb-0">
                                                    <input type="checkbox" className="custom-control-input" value={el.id} onChange={checkItems} />
                                                    <span className="custom-control-label">&nbsp;</span>
                                                </label>
                                                <Link to={`/people/customer/customer-details/${el.code}`} className="invo-txt">{el.name}</Link>
                                            </span>
                                        </td>
                                        <td>{el.phone}</td>
                                        <td>{el.email}</td>
                                        <td>{el.visit}</td>
                                        <td>{parseFloat(el.total).toFixed(branchInfo.Branch_DecimalPoints)}</td>
                                        <td className={el.credit < 0 ? 'text-red' : ''}>{Math.abs(el.credit).toFixed(branchInfo.Branch_DecimalPoints)}</td>
                                        <td>{el.lastVisit}</td>
                                    </tr>
                                ))}
                                {data.length == 0 && <tr><td colSpan={4}><p className="text-center py-4 mb-0">No Data Found</p></td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AlertUser {...ajaxResponse} />
    </>;
}

export default CustomerList;
