import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { axiosInstance } from "../../../config/axios";
import { selectStyle, reportFilter } from '../../../config/constants'
import "react-datepicker/dist/react-datepicker.css";

function ReportGeneralLedgerInfo() {

    const location = useLocation();
    const qp = new URLSearchParams(location.search);

    let defaultVal = {
        duration: { value: 'custom', label: 'Custom'},
        startDate: null,
        endDate: null,
        account: qp.get('account'),
    }

    let dateString = qp.get('start');
    if(dateString) {
        var [day, month, year] = dateString.split("-");
        defaultVal.startDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    }
    dateString = qp.get('end');
    if(dateString) {
        var [day, month, year] = dateString.split("-");
        defaultVal.endDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    }

    const [filter, setFilter] = useState(defaultVal);
    const [data, setData] = useState([]);
    const [activeDate, setActiveDate] = useState({
        startDate: null,
        endDate: null
    })
    const [totalAmnt, setTotalAmnt] = useState({
        opening: 0,
        closing: 0,
        totDebit: 0,
        totCredit: 0
    })
    const fetchData = () => {
        axiosInstance.post("/reports/accounts/general-ledger-info", filter)
        .then(function (response) {
            if(response.data.status == 'success') {
                setData(response.data.data.rows);
                setActiveDate({
                    startDate: response.data.startDate,
                    endDate: response.data.endDate
                });
                setTotalAmnt({
                    opening: response.data.opening,
                    closing: response.data.closing,
                    totDebit: data.reduce((total, itm)=> { return total + parseFloat(itm.debit) }, 0),
                    totCredit: data.reduce((total, itm)=> { return total + parseFloat(itm.credit) }, 0)
                });
            }
        }).catch(err=>console.log(err));
    };

    useEffect(() => {
        fetchData();
    }, [filter]);

    return <>
        <div className="row">
            <div className="col col-md-12 input-group d-flex justify-content-start">
                <div className="col-md-4 col-11 px-0 input-icon table-head-search py-0">
                    <Select
                        placeholder="Select"
                        options={reportFilter}
                        styles={selectStyle}
                        value={filter.duration}
                        onChange={(val)=>{
                            setFilter({...filter, ...{duration: val, startDate: null, endDate: null}})
                        }}
                    />
                </div>
                <div className="col-md-4 col-11 px-0 input-icon table-head-search py-0 pl-1">
                    <DatePicker className="form-control" 
                    selectsRange={true}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Date"
                    startDate={filter.startDate} endDate={filter.endDate}
                    onChange={(update) => {
                        setFilter({...filter, ...{ startDate:update[0], endDate:update[1]}})
                    }} isClearable={true} disabled={filter.duration.value == 'custom' ? false : true} />
                </div>
                <span className="input-group-append dropdown"><button className="btn c-lgt-blue text-white" type="button">Download &nbsp; <i className="fe fe-download"></i></button></span>
            </div>
        </div>
        <div className="card">
            <div className="card-status c-blue top-layer"></div>
            <div className="card-body px-1 h-80-scroll">
                <div className="table-responsive ast-table h-100">
                    <table className="table table-striped table-vcenter mb-0 text-nowrap">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Account</th>
                                <th>Transaction <br/> Details</th>
                                <th>Transaction <br/> Type</th>
                                <th>Transaction</th>
                                <th>Referance</th>
                                <th>Debit</th>
                                <th>Credit</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.length > 0 && (<tr>
                            <td>As On {activeDate.startDate}</td>
                            <td colSpan={5}>Opening Balance</td>
                            <td>{totalAmnt.opening > 0 ? totalAmnt.opening.toFixed(2) : ''}</td>
                            <td>{totalAmnt.opening < 0 ? (totalAmnt.opening * -1).toFixed(2) : ''}</td>
                            <td>{totalAmnt.opening < 0 ? (totalAmnt.opening * -1).toFixed(2) : (totalAmnt.opening > 0 ? totalAmnt.opening.toFixed(2) : '')}</td>
                        </tr>)}
                        {data.map((item, i)=>(
                            <tr key={i}>
                                <td>{item.transdate}</td>
                                <td>{item.account}</td>
                                <td>{item.remarks}</td>
                                <td>{item.transType}</td>
                                <td>{item.transaction}</td>
                                <td>{item.referance}</td>
                                <td>{item.debit > 0 ? item.debit : ''}</td>
                                <td>{item.credit > 0 ? item.credit : ''}</td>
                                <td>{item.debit > 0 ? item.debit : item.credit}</td>
                            </tr>
                        ))}
                        {data.length > 0 && (<tr>
                            <td></td>
                            <td colSpan={5}>Total Debits & Credits ({activeDate.startDate} to {activeDate.endDate})</td>
                            <td>{totalAmnt.totDebit.toFixed(2)}</td>
                            <td>{totalAmnt.totCredit.toFixed(2)}</td>
                            <td></td>
                        </tr>)}
                        {data.length > 0 && (<tr>
                            <td>As On {activeDate.endDate}</td>
                            <td colSpan={5}>Closing Balance</td>
                            <td>{totalAmnt.closing > 0 ? totalAmnt.closing.toFixed(2) : ''}</td>
                            <td>{totalAmnt.closing < 0 ? (totalAmnt.closing * -1).toFixed(2) : ''}</td>
                            <td>{totalAmnt.closing < 0 ? (totalAmnt.closing * -1).toFixed(2) : (totalAmnt.closing > 0 ? totalAmnt.closing.toFixed(2) : '')}</td>
                        </tr>)}
                        {data.length <= 0 && <tr><td colSpan="9"><p className="w-100 text-center pt-5">No Data Found</p></td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>;
}

export default ReportGeneralLedgerInfo;
