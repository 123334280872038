import Dashboard from './Views/Dashboard';
import ChartOfAccounts from './Views/Accounts/ChartOfAccounts';
import ManualJournal from './Views/Accounts/ManualJournal';
import UpdateJournal from './Views/Accounts/UpdateJournal';
import UpdateAccount from './Views/Accounts/UpdateAccount';
import AccountSettings from './Views/Accounts/AccountSettings';
import JournalReport from './Views/Accounts/JournalReport';
import ReportsView from './Views/Reports/ReportsView';
import Audit from './Views/Inventory/Audit/Audit';
import { BranchList, BranchView, BranchUpdate, DeviceList } from './Views/Settings';
import { Options, Hsncode, Category, ItemList, ItemView } from './Views/Catalogue';
import { StockList, StockUpdate, StockInfo, PurchaseList, PO, Purchase, PurchaseInfo } from './Views/Inventory'
import { VendorsList, VendorInfo, VendorUpdate, VendorInvoices, VendorPaymentHistory, VendorPayment, VendorOrders, CustomerList, CustomerInfo, UserList, UserInfo, UserUpdate } from './Views/People'

let accntRoutes = [
    {
        path: "/accounts/manual-journals",
        name: 'manual-journals',
        exact: true,
        pageTitle: "Manual Journal",
        component: ManualJournal
    },
    {
        path: "/accounts/create-journal",
        name: 'create-journal',
        exact: true,
        pageTitle: "New Journal",
        component: UpdateJournal
    },
    {
        path: "/accounts/journal/:slug",
        name: 'edit-journal',
        exact: true,
        pageTitle: "Update Journal",
        component: UpdateJournal
    },
    {
        path: "/accounts/chart-of-accounts",
        name: 'acc-accounts',
        exact: true,
        pageTitle: "Chart Of Accounts",
        component: ChartOfAccounts
    },
    {
        path: "/accounts/new-account",
        name: 'new-account',
        exact: true,
        pageTitle: "Create Account",
        component: UpdateAccount
    },
    {
        path: "/account/:slug",
        name: 'new-account',
        exact: true,
        pageTitle: "Create Account",
        component: UpdateAccount
    },
    {
        path: "/accounts/settings",
        name: 'acc-settings',
        exact: true,
        pageTitle: "Settings",
        component: AccountSettings
    },
    {
        path: "/accounts/journal-report",
        name: 'journal-report',
        exact: true,
        pageTitle: "Journal Report",
        component: JournalReport
    }
];

let catalogueRoutes = [
    {
        path: "/catalogue/options",
        name: 'options',
        exact: true,
        pageTitle: "Options",
        component: Options
    },
    {
        path: "/catalogue/hsncode",
        name: 'hsncode',
        exact: true,
        pageTitle: "HSN Code",
        component: Hsncode
    },
    {
        path: "/catalogue/categories",
        name: 'categories',
        exact: true,
        pageTitle: "Category",
        component: Category
    },
    {
        path: "/catalogue/item",
        name: 'item',
        exact: true,
        pageTitle: "Catalogue",
        subTitle: "Items",
        component: ItemList
    },
    {
        path: "/catalogue/item/create-item",
        name: 'item',
        exact: true,
        pageTitle: "Create New Item",
        component: ItemView
    }
];

let stockRoutes = [
    {
        path: "/inventory/stock",
        name: 'stock-list',
        exact: true,
        pageTitle: "Inventory",
        subTitle: "Stock",
        component: StockList
    },
    {
        path: "/inventory/stock/create-stock",
        name: 'stock-update',
        exact: true,
        pageTitle: "Crerate New Stock Item",
        subTitle: "",
        component: StockUpdate
    },
    {
        path: "/inventory/stock/stock-details/:id",
        name: 'stock-info',
        exact: true,
        pageTitle: "Item Details",
        subTitle: "",
        component: StockInfo
    },
];

let purchaseRoutes = [
    {
        path: "/inventory/purchase",
        name: 'purchase-list',
        exact: true,
        pageTitle: "Purchase",
        subTitle: "",
        component: PurchaseList
    },
    {
        path: "/inventory/purchase-order/create-po",
        name: 'create-po',
        exact: true,
        pageTitle: "Create Purchase Order",
        subTitle: "",
        component: PO
    },
    {
        path: "/inventory/purchase-order/draft/:id",
        name: 'draft-po',
        exact: true,
        pageTitle: "Create Purchase Order",
        subTitle: "",
        component: PO
    },
    {
        path: "/inventory/purchase/create-grn",
        name: 'create-grn',
        exact: true,
        pageTitle: "Create New Bill",
        subTitle: "",
        component: Purchase
    },
    {
        path: "/inventory/purchase/purchase-details/:id",
        name: 'view-grn',
        exact: true,
        pageTitle: "Purchase Details",
        subTitle: "",
        component: PurchaseInfo
    },
    {
        path: "/inventory/purchase/draft-grn/:id",
        name: 'draft-grn',
        exact: true,
        pageTitle: "Create New Bill",
        subTitle: "",
        component: Purchase
    },
];

let settingsRoutes = [
    {
        path: "/",
        name: 'dashboard',
        exact: true,
        pageTitle: "Dashboard",
        component: Dashboard
    },
    {
        path: "/reports/accounts/:slug",
        name: 'reports-accounts',
        exact: true,
        pageTitle: "Reports",
        component: ReportsView
    },
    {
        path: "/inventory/audit",
        name: 'audit',
        exact: true,
        pageTitle: "Audit",
        component: Audit
    },
    {
        path: "/settings/branch",
        name: 'branch',
        exact: true,
        pageTitle: "Branches",
        component: BranchList
    },
    {
        path: "/settings/branch-info/:id",
        name: 'branch-view',
        exact: true,
        pageTitle: "Branch",
        component: BranchView
    },
    {
        path: "/settings/create-branch",
        name: 'create-branch',
        exact: true,
        pageTitle: "Create New Branch",
        component: BranchUpdate
    },
    {
        path: "/settings/branch-update/:id",
        name: 'branch-update',
        exact: true,
        pageTitle: "Branch",
        component: BranchUpdate
    },
    {
        path: "/settings/devices",
        name: 'devices',
        exact: true,
        pageTitle: "Devices",
        component: DeviceList
    }
];

let peopleRoutes = [
    {
        path: "/people/vendor",
        name: 'vendor-list',
        exact: true,
        pageTitle: "Vendors",
        subTitle: "",
        component: VendorsList
    },
    {
        path: "/people/vendor/vendor-details/:id",
        name: 'vendor-details',
        exact: true,
        pageTitle: "Vendor Details",
        subTitle: "",
        component: VendorInfo
    },
    {
        path: "/people/vendor/create-vendor",
        name: 'create-vendor',
        exact: true,
        pageTitle: "Vendor Details",
        subTitle: "",
        component: VendorUpdate
    },
    {
        path: "/people/vendor/update-vendor/:id",
        name: 'update-vendor',
        exact: true,
        pageTitle: "Update Vendor",
        subTitle: "",
        component: VendorUpdate
    },
    {
        path: "/people/vendor/invoices/:id",
        name: 'vendor-invoices',
        exact: true,
        pageTitle: "Invoices",
        subTitle: "",
        component: VendorInvoices
    },
    {
        path: "/people/vendor/payment-history/:id",
        name: 'vendor-payment-history',
        exact: true,
        pageTitle: "Payment History",
        subTitle: "",
        component: VendorPaymentHistory
    },
    {
        path: "/people/vendor/payment/:id",
        name: 'vendor-payment',
        exact: true,
        pageTitle: "Payment",
        subTitle: "",
        component: VendorPayment
    },
    {
        path: "/people/vendor/pending-orders/:id",
        name: 'vendor-pending-orders',
        exact: true,
        pageTitle: "Pending Orders",
        subTitle: "",
        component: VendorOrders
    },
    {
        path: "/people/customer",
        name: 'customer',
        exact: true,
        pageTitle: "Customers",
        subTitle: "",
        component: CustomerList
    },
    {
        path: "/people/customer/customer-details/:id",
        name: 'customer-details',
        exact: true,
        pageTitle: "Customer",
        subTitle: "",
        component: CustomerInfo
    },
    {
        path: "/people/user",
        name: 'users',
        exact: true,
        pageTitle: "Users",
        subTitle: "",
        component: UserList
    },
    {
        path: "/people/user/user-details/:id",
        name: 'user-details',
        exact: true,
        pageTitle: "User",
        subTitle: "",
        component: UserInfo
    },
    {
        path: "/people/user/create-user",
        name: 'create-user',
        exact: true,
        pageTitle: "Create New User",
        subTitle: "",
        component: UserUpdate
    },
    {
        path: "/people/user/update-user/:id",
        name: 'update-user',
        exact: true,
        pageTitle: "Update user",
        subTitle: "",
        component: UserUpdate
    }
];

const Routes = [
    ...accntRoutes,
    ...settingsRoutes,
    ...catalogueRoutes,
    ...stockRoutes,
    ...purchaseRoutes,
    ...peopleRoutes,
];

export default Routes;