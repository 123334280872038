import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function DropDown(props) {
    const items = props.items;
    const color = props.color;
    const icon = props.icon;
    const [show, setShow] = useState(false);

    const handleBodyClick = () => setShow(false);

    useEffect(() => {
        document.body.addEventListener("click", handleBodyClick);
        return () => {
            document.body.removeEventListener("click", handleBodyClick);
        };
    }, []);

    return (
        <span className={`input-group-append dropdown ${show ? `show` : ""}`}>
            <button
                className={`btn ${color}`}
                type="button"
                onClick={() => setShow(!show)}
            >
                <i className={icon}></i>
                <div
                    className={`dropdown-menu dropdown-menu-right dropdown-menu-arrow ${
                        show ? `show` : ""
                    }`}
                >
                    {items.map((itm, i) =>
                        itm.title ? (
                            <div key={i}>
                                <small className="py-0 pl-4 my-0">
                                    {itm.text}
                                </small>
                                <div className="dropdown-divider my-0 py-0"></div>
                            </div>
                        ) : (
                            itm.callback ? 
                            <span
                                key={i}
                                className="dropdown-item"
                                onClick={itm.callback}
                            >
                                <i className={`dropdown-icon ${itm.icon}`}></i>{" "}
                                {itm.text}
                            </span> : <Link
                                key={i}
                                className="dropdown-item"
                                to={itm.url}
                            >
                                <i className={`dropdown-icon ${itm.icon}`}></i>{" "}
                                {itm.text}
                            </Link>
                        )
                    )}
                </div>
            </button>
        </span>
    );
}

export default DropDown;
