import React, { useState, useEffect } from "react";
import Autosuggest from "react-autosuggest";

import { axiosInstance } from "../../../config/axios";

const AutosuggestVendor = (props) => {

    const { onSelect, onSearch, title, wrapper, required, error, defaultVal } = props;

    const [value, setValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            axiosInstance
            .get(`/inventory/search-vendor?search=${value}`)
            .then(function (res) {
                if (res.data.status === "success") {
                    setSuggestions(res.data.data);
                }
            })
            .catch((e) => console.log(e));
        };

        if (value.trim() !== "") {
            fetchData();
        } else {
            setSuggestions([]);
        }
    }, [value]);

    const onChange = (event, { newValue }) => {
        setValue(newValue);
        onSearch(newValue);
    };

    const getSuggestionValue = (suggestion) => suggestion.label;

    const renderSuggestion = (suggestion) => <div>{suggestion.label}</div>;

    const inputProps = {
        placeholder: "Search",
        value: defaultVal ? (defaultVal.label ? defaultVal.label : "") : '',
        onChange,
        className: 'form-control'
    };

    return (
        <div className={wrapper}>
            <div className="form-group">
                <label className="form-label-small">{title} {required && <span className="red">*</span>}</label>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={() => {}} // No need to handle this manually now
                    onSuggestionsClearRequested={() => setSuggestions([])}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    onSuggestionSelected={onSelect}
                    inputProps={inputProps}
                />
                {error && (<small className="lbl-error">{error}</small>)}
            </div>
        </div>
        
    );
};

export default AutosuggestVendor;
