import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { axiosInstance } from "../../../config/axios";

function ChartOfAccounts() {
    
    const [dataSearch, setDataSearch] = useState("");
    const [data, setData] = useState([]);
    let [pageNo, setPageNo] = useState(0);
    const [noOfPages, setNoOfPages] = useState(0);
    const [pageTab, setPageTab] = useState(1);
    const [pagination, setPagination] = useState("0-0 of 0")

    const fetchData = () => {
        axiosInstance.post("/accounts/accounts", {
            search: dataSearch,
            status: pageTab,
            pageNo: pageNo,
        }).then(function (response) {
            setNoOfPages(response.data.data.noofpages);
            setData(response.data.data.rows);
            setPagination(`${response.data.data.offset}-${response.data.data.limit} of ${response.data.data.count}`)
        }).catch(function (error) {
            console.log(error);
        });
    };

    useEffect(() => {
        fetchData();
    }, [dataSearch, pageNo, pageTab]);

    useEffect(() => {
        setDataSearch("")
        setData([])
        setPageNo(0)
        setNoOfPages(0)
        setPagination("0-0 of 0")
    }, [pageTab])

    return (
        <div>
            <div className="section-body">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center horizontal-nav">
                        <ul className="nav nav-tabs page-header-tab">
                            <li
                                className="nav-item"
                                onClick={() => setPageTab(1)}
                            >
                                <a
                                    className={
                                        pageTab == 1
                                            ? "nav-link active"
                                            : "nav-link"
                                    }
                                >
                                    Active
                                </a>
                            </li>
                            <li
                                className="nav-item"
                                onClick={() => setPageTab(0)}
                            >
                                <a
                                    className={
                                        pageTab == 0
                                            ? "nav-link active"
                                            : "nav-link"
                                    }
                                >
                                    Inactive
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="section-body content-area">
                <div className="container-fluid">
                    <div className="tab-content">
                        <div
                            className={
                                pageTab == 1
                                    ? "tab-pane fade show active"
                                    : "tab-pane fade"
                            }
                            role="tabpanel"
                        >
                            <div className="row">
                                <div className="col col-sm-8 input-group d-flex justify-content-start">
                                    <div className="col-md-4 col-11 px-0 input-icon table-head-search">
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={(e) =>
                                                setDataSearch(e.target.value)
                                            }
                                            value={dataSearch}
                                            placeholder="Search..."
                                        />
                                        <span className="input-icon-addon">
                                            <i className="fe fe-search"></i>
                                        </span>
                                    </div>
                                    <span className="input-group-append">
                                        <Link
                                            to="/accounts/new-account"
                                            className="btn c-blue text-white"
                                        >
                                            Add New
                                        </Link>
                                    </span>
                                    <span className="input-group-append">
                                        <button
                                            className="btn c-red text-white"
                                            type="button"
                                        >
                                            <i className="fe fe-filter"></i>
                                        </button>
                                    </span>
                                </div>
                                <div className="col-2 col-sm-4 d-flex justify-content-end mb-1 table-nav">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        if (pageNo > 0) setPageNo(--pageNo)
                                    }}>
                                        <i className="fe fe-chevron-left pr-sm-1 pr-2"></i>
                                    </a>
                                    <span className="px-1 d-none d-sm-block">
                                        {pagination}
                                    </span>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        if (pageNo < noOfPages) setPageNo(++pageNo)
                                    }}>
                                        <i className="fe fe-chevron-right pl-sm-1 pl-2"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body px-1">
                                    <div className="table-responsive ast-table">
                                        <table className="table table-striped table-vcenter mb-0 text-nowrap">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <span className="table-checkbox-head">
                                                            &nbsp;
                                                        </span>
                                                        Account Name
                                                    </th>
                                                    <th>Account Code</th>
                                                    <th>Account Type</th>
                                                    <th>Parent Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((data, i) => (
                                                    <tr key={i}>
                                                        <td className="d-flex">
                                                            <label className="custom-control custom-checkbox mb-0">
                                                                <input type="checkbox"
                                                                    className="custom-control-input"
                                                                />
                                                                <span className="custom-control-label">
                                                                    &nbsp;
                                                                </span>
                                                            </label>
                                                            <span>
                                                                <Link to={`/account/${data.Accnt_Id}`} className="invo-txt">{data.Accnt_Name}</Link>
                                                            </span>
                                                        </td>
                                                        <td>{data.Accnt_Code}</td>
                                                        <td>{data.AcType.AcType_Name}</td>
                                                        <td></td>
                                                    </tr>
                                                ))}
                                                {data.length == 0 && (<tr><td colSpan="4"><p className="text-center pt-4">No Data Found</p></td></tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={
                                pageTab == 0
                                    ? "tab-pane fade show active"
                                    : "tab-pane fade"
                            }
                            role="tabpanel"
                        >
                            <div className="row">
                                <div className="col col-sm-8 input-group d-flex justify-content-start">
                                    <div className="col-md-4 col-11 px-0 input-icon table-head-search">
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={(e) =>
                                                setDataSearch(e.target.value)
                                            }
                                            value={dataSearch}
                                            placeholder="Search..."
                                        />
                                        <span className="input-icon-addon">
                                            <i className="fe fe-search"></i>
                                        </span>
                                    </div>
                                    <span className="input-group-append">
                                        <button
                                            className="btn c-red text-white"
                                            type="button"
                                        >
                                            <i className="fe fe-filter"></i>
                                        </button>
                                    </span>
                                </div>
                                <div className="col-2 col-sm-4 d-flex justify-content-end mb-1 table-nav">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        if (pageNo > 0) setPageNo(--pageNo)
                                    }}>
                                        <i className="fe fe-chevron-left pr-sm-1 pr-2"></i>
                                    </a>
                                    <span className="px-1 d-none d-sm-block">
                                        {pagination}
                                    </span>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        if (pageNo < noOfPages) setPageNo(++pageNo)
                                    }}>
                                        <i className="fe fe-chevron-right pl-sm-1 pl-2"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body px-2">
                                    <div className="table-responsive ast-table">
                                        <table className="table table-striped table-vcenter mb-0 text-nowrap">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <span className="table-checkbox-head">
                                                            &nbsp;
                                                        </span>
                                                        Account Name
                                                    </th>
                                                    <th>Account Code</th>
                                                    <th>Account Type</th>
                                                    <th>Parent Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((data, i) => (
                                                    <tr key={i}>
                                                        <td className="d-flex">
                                                            <label className="custom-control custom-checkbox mb-0">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                />
                                                                <span className="custom-control-label">
                                                                    &nbsp;
                                                                </span>
                                                            </label>
                                                            <span>
                                                                <Link to="/accounts/new-account" className="invo-txt">{data.Accnt_Name}</Link>
                                                            </span>
                                                        </td>
                                                        <td></td>
                                                        <td>{data.AcType.AcType_Name}</td>
                                                        <td></td>
                                                    </tr>
                                                ))}
                                                {
                                                    data.length == 0 && (<tr><td colSpan="4"><p className="text-center pt-4">No Data Found</p></td></tr>)
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    fixNavbar: state.settings.isFixNavbar,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ChartOfAccounts);
