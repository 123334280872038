import React from 'react'
import InputText from "../../InputText"

function BranchAccounts(props) {
    return (
        <div className="row bank-row">
            <InputText wrapper="col-6 col-md" value={props.data.Bank_Name} name="Bank_Name" title="Bank Name" required={true} onChange={(e)=>props.onValueChange(e, props.index)} error={props.error && props.error.Bank_Name} />
            <InputText wrapper="col-6 col-md" value={props.data.Bank_AccNo} name="Bank_AccNo" title="Acc. No." required={true} onChange={(e)=>props.onValueChange(e, props.index)} error={props.error && props.error.Bank_AccNo} />
            <InputText wrapper="col-6 col-md" value={props.data.Bank_AccHolder} name="Bank_AccHolder" title="Name" required={true} onChange={(e)=>props.onValueChange(e, props.index)} error={props.error && props.error.Bank_AccHolder} />
            <InputText wrapper="col-6 col-md" value={props.data.Bank_IFSC} name="Bank_IFSC" title="IFSC" required={false} onChange={(e)=>props.onValueChange(e, props.index)} />
            <InputText wrapper="col-6 col-md" value={props.data.Bank_Swift} name="Bank_Swift" title="SWIFT Code" required={false} onChange={(e)=>props.onValueChange(e, props.index)} />
            <div className="col-1 col-md-1 text-center pt-4 close-btn"><span className="c-pointer" onClick={()=> props.onRemove(props.index) }><i className="fe fe-minus-circle text-red"></i></span></div>
        </div>
    )
}

export default BranchAccounts