import React, {useState} from "react";
import { useSelector } from "react-redux";
import { InputText, InputSelect, InputSelectVal } from '../../';

function ChannelPrice(props) {

    const branchInfo = useSelector((state)=>{
        return state.branch
    });

    const [charges, setCharges] = useState([]);

    const { channel, taxclass, igstclass, data, onChnlRowChange, removeItem, index } = props;

    const onChannelChange = (name, val)=>{
        if(name === 'channelId') setCharges(val.charge);
        onChnlRowChange(name, val, index);
    };
    
    return (<div className="row mt-2">

        <InputSelectVal wrapper="col-6 col-md" title="Select Channel" required={true} value={data.channelId} name="channelId" onSelect={onChannelChange} data={channel} error={data.errChannel} />
        
        <InputSelect wrapper={branchInfo.View_Tax ? "col-6 col-md" : "d-none"} title={branchInfo.Branch_TaxType == 1 ? "GST Class" : "Tax Class"} required={false} value={data.taxId} name="taxId" onSelect={onChannelChange} data={taxclass} />
        
        <InputSelect wrapper={branchInfo.View_Tax && branchInfo.Branch_TaxType ? "col-6 col-md" : "d-none"} title="IGST Class" required={false} value={data.taxId} name="igstId" onSelect={onChannelChange} data={igstclass} />
        
        <InputSelect wrapper={branchInfo.View_Charges ? "col-6 col-md" : "d-none"} title="Charges" required={false} value={data.chId} name="chId" onSelect={onChannelChange} data={data.channelId ? data.channelId.charge : charges} />

        <InputText wrapper="col-5 col-md" title="Selling Price" required={true} type="digits" value={data.price} name="price" onChange={(e)=>onChannelChange(e.target.name, e.target.value)} error={data.errPrice} />
        <InputText wrapper={branchInfo.Allow_Duplicate ? "col-5 col-md" : "d-none"} title="Selling Price 2" required={false} type="digits" value={data.price2} name="price2" onChange={(e)=>onChannelChange(e.target.name, e.target.value)} />

        <div className="col-1 col-lg-1 text-center pt-4 close-btn"> 
            <a href="#" className="c-pointer" onClick={(e)=>{
                e.preventDefault();
                removeItem(index);
            }}><i className="fe fe-minus-circle text-red"></i></a> 
        </div>
    </div>);
}

export default ChannelPrice;
