import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import Swal from 'sweetalert2';

import { axiosInstance } from '../../../../../config/axios';

import { DropDown, AlertUser } from '../../../';

function PurchaseInfo() {

    const { id } = useParams();

    const branchInfo = useSelector((state)=>{
        return state.branch
    });

    const [ajaxResponse, setAjaxResponse] = useState({});
    useEffect(() => {
        setTimeout(() => {
            setAjaxResponse({});
        }, 5000);
    }, [ajaxResponse]);
    
    const [data, setData] = useState({ items: [] });

    useEffect(() => {
        axiosInstance
        .get(`/inventory/purchase/view-grn?id=${id}`)
        .then(function (res) {
            if (res.data.status === 'success') {
                setData(res.data.data);
            }
        }).catch(e => console.log(e));
    }, []);
    
    const testFun = () => {};

    const deleteGRN = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You wan't to delete !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                axiosInstance
                .get(`/inventory/purchase/delete-grn?id=${data.Purchase_Id}`)
                .then(function (res) {
                    if (res.data.status === 'success') {
                        
                    }
                    setAjaxResponse({ status: res.data.status, message: res.data.message });
                }).catch(e => console.log(e));
            }
        });
    };

    const grnAction = [
        { icon: 'fe fe-printer', text: 'Print', callback: testFun },
        { icon: 'fe fe-trash-2', text: 'Delete', callback: deleteGRN },
    ];
    const grnPrint = [
        { icon: 'fa fa-file-excel-o', text: 'Download Purchase', callback: testFun },
        { icon: 'fa fa-file-excel-o', text: 'Download Refunded', callback: testFun },
    ];
    
    return <>
        <div className="section-body">
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-sm-8 input-group d-flex justify-content-start">
                        <span className="input-group-append"><a href="#" className="btn c-red text-white px-4">Print</a></span>
                        <span className="input-group-append"><a href="#" className="btn c-blue text-white px-4">Return</a></span>
                        <DropDown color="c-lgt-blue text-white" icon="fe fe-sliders" items={grnAction} />
                        <DropDown color="bg-white" icon="fe fe-download" items={grnPrint} />
                    </div>
                </div>
                <div className="card">
                    <div className="card-body px-1 pb-0">
                        <div className="table-responsive ast-table t-h-10">
                            <table className="table table-striped table-vcenter mb-0 text-nowrap">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Invoice ID</th>
                                        <th>PO ID</th>
                                        <th>Total Item</th>
                                        <th>Total Qty</th>
                                        <th>Total Amount</th>
                                        <th>Amount Due</th>
                                        <th>Vendor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.Purchase_Id && <tr>
                                        <td>{data.Purchase_Date}</td>
                                        <td>{data.Purchase_VendInvoice}</td>
                                        <td>{data.PO_Invoice}</td>
                                        <td>{data.NoOfItems}</td>
                                        <td>{data.TotalQnty}</td>
                                        <td>{parseFloat(data.TotalAmount).toFixed(branchInfo.Branch_DecimalPoints)}</td>
                                        <td>{parseFloat(data.AmountDue).toFixed(branchInfo.Branch_DecimalPoints)}</td>
                                        <td>{data.Vendor}</td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="row">
                            <div className="col col-sm-8 input-group d-flex justify-content-start pt-2">
                                <h6 className="table-head pl-2">INVOICE ITEMS</h6>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-status bg-success"></div>
                            <div className="card-body pb-1 pt-2 px-1">
                                <div className="row">
                                    <div className="table-responsive ast-table">
                                        <table className="table table-striped table-vcenter mb-0 text-nowrap">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Item Name</th>
                                                    <th>Quantity</th>
                                                    <th>FOC</th>
                                                    {branchInfo.View_Tax && <th>Tax</th>}
                                                    <th>Price</th>
                                                    <th>Cost Price/WAC</th>
                                                    <th>MRP</th>
                                                    <th>Batch No</th>
                                                    <th>Mfg. Date</th>
                                                    <th>Exp. Date</th>
                                                    <th className="border-right">Serial No.</th>
                                                    
                                                    {(branchInfo.View_Tax && branchInfo.Branch_TaxType) && <><th className="bg-light">CGST</th>
                                                    <th className="bg-light">SGST</th>
                                                    <th className="bg-light">IGST</th></>}
                                                    {(branchInfo.View_Tax && !branchInfo.Branch_TaxType) && <th className="bg-light">VAT</th>}
                                                    <th className="bg-light">CESS</th>
                                                    <th className="bg-light">Tax Total</th>
                                                    <th className="bg-light">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.items.map((el, i)=>(
                                                <tr key={el.PurchaseItem_Id}>
                                                    <td>{i + 1}</td>
                                                    <td>{el.variant.Variant_Name}</td>
                                                    <td>{parseFloat(el.Purchase_Quantity)}</td>
                                                    <td>{parseFloat(el.Purchase_FOC)}</td>
                                                    {branchInfo.View_Tax && <td>{el.tax ? el.tax.Tax_Title : '-'}</td>}
                                                    <td>{parseFloat(el.Purchase_Rate).toFixed(branchInfo.Branch_DecimalPoints)}</td>
                                                    <td>{parseFloat(el.Purchase_Cost).toFixed(branchInfo.Branch_DecimalPoints)}</td>
                                                    <td>{parseFloat(el.Purchase_MRP).toFixed(branchInfo.Branch_DecimalPoints)}</td>
                                                    <td>{el.Purchase_BatchNo ? el.Purchase_BatchNo : '-'}</td>
                                                    <td>{el.Purchase_MfgDate ? el.Purchase_MfgDate : '-'}</td>
                                                    <td>{el.Purchase_ExpDate ? el.Purchase_ExpDate : '-'}</td>
                                                    <td className="border-right">{el.Purchase_SerialNo ? el.Purchase_SerialNo : '-'}</td>

                                                    {(branchInfo.View_Tax && branchInfo.Branch_TaxType) && <><td className="bg-light">{el.CGST_Amnt ? parseFloat(el.CGST_Amnt).toFixed(branchInfo.Branch_DecimalPoints) : '-'}</td>
                                                    <td className="bg-light">{el.SGST_Amnt ? parseFloat(el.SGST_Amnt).toFixed(branchInfo.Branch_DecimalPoints) : '-'}</td>
                                                    <td className="bg-light">{el.IGST_Amnt ? parseFloat(el.IGST_Amnt).toFixed(branchInfo.Branch_DecimalPoints) : '-'}</td></>}
                                                    {(branchInfo.View_Tax && !branchInfo.Branch_TaxType) && <td className="bg-light">{el.VAT_Amnt ? parseFloat(el.VAT_Amnt).toFixed(branchInfo.Branch_DecimalPoints) : '-'}</td>}
                                                    <td className="bg-light">{el.Sess_Amnt ? parseFloat(el.Sess_Amnt).toFixed(branchInfo.Branch_DecimalPoints) : '-'}</td>
                                                    <td className="bg-light">{el.Tax_Amnt ? parseFloat(el.Tax_Amnt).toFixed(branchInfo.Branch_DecimalPoints) : '-'}</td>
                                                    <td className="bg-light">{parseFloat(el.Purchase_Amount).toFixed(branchInfo.Branch_DecimalPoints)}</td>
                                                </tr>
                                                ))}
                                                {data.items.length == 0 && <tr><td colSpan="11"><p className="text-center pt-3 text-gray">No Items Found</p></td></tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <AlertUser {...ajaxResponse} />
    </>;
}

export default PurchaseInfo;
