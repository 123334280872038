import React from "react";
import Select from "react-select";
import { selectStyle } from "../../config/constants";

function InputSelectVal(props) {

    const formatOptionLabel = ({ label, subtext }) => (<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <div>{label}</div>
        <div style={{ fontSize: '80%', color: 'gray', marginLeft: '10px', marginTop: '2px' }}>{subtext}</div>
    </div>);

    return (
        <div className={props.wrapper}>
            <div className="form-group">
                <label className="form-label-small w-100">{props.title} {props.required && <span className="red">*</span>}</label>
                <Select 
                    options={props.data} 
                    formatOptionLabel={formatOptionLabel} 
                    styles={selectStyle}
                    menuPosition="fixed"
                    menuPlacement="bottom"
                    value={props.value}
                    onChange={(e)=> props.onSelect(props.name, e)}
                    isMulti={props.multiple}
                />
                {props.error && (<small className="lbl-error">{props.error}</small>)}
            </div>
        </div>
    );
}

export default InputSelectVal;
