import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import { selectStyle } from '../../../config/constants';
import { axiosInstance } from "../../../config/axios";

function UpdateAccount() {
    const location = useLocation();

    const [ajaxResponse, setAjaxResponse] = useState({})

    const [btnLoading, setBtnLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const initialVal = {
        accId: 0,
        parent: false,
        type: null,
        code: '',
        name: '',
        parentAc: null,
        description: ''
    }
    const [formData, setFormData] = useState(initialVal);
    const [error, setError] = useState([]);

    const [expand, setExpand] = useState(false);

    const [acTypes, setAcTypes] = useState([]);
    useEffect(() => {
        axiosInstance
            .get("/accounts/account-type")
            .then(function (response) {
                if (response.data.status === "success") {
                    let tempData = [];
                    response.data.data.forEach((item) => {
                        tempData.push({
                            label: item.label,
                            options: item.AcTypes,
                        });
                    });
                    setAcTypes(tempData);
                }
            })
            .catch((err) => console.error(err.message));
    }, []);

    // Parent Account
    const [accounts, setAccounts] = useState([]);
    const getAccounts = (id) => {
        axiosInstance
            .get("/accounts/accounts-bytype?id=" + id)
            .then(function (response) {
                if (response.data.status === "success")
                    setAccounts(response.data.data);
            })
            .catch((err) => console.error(err.message));
    };

    const [dataSearch, setDataSearch] = useState("");
    const [data, setData] = useState([]);
    let [pageNo, setPageNo] = useState(0);
    const [noOfPages, setNoOfPages] = useState(0);
    const [pagination, setPagination] = useState("0-0 of 0");

    const fetchData = () => {
        axiosInstance
            .post("/accounts/accounts", {
                search: dataSearch,
                pageNo: pageNo,
            })
            .then(function (response) {
                setNoOfPages(response.data.data.noofpages);
                setData(response.data.data.rows);
                setPagination(
                    `${response.data.data.offset}-${response.data.data.limit} of ${response.data.data.count}`
                );
            })
            .catch((err) => console.error(err.message));
    };

    useEffect(() => {
        fetchData();
    }, [dataSearch, pageNo]);

    // get Active account details from db
    const accountInfo = (id) => {
        axiosInstance
            .get("/accounts/account-info?id=" + id)
            .then(function (response) {
                if (response.data.status === "success") {
                    setAccounts(response.data.data.allowedParents);
                    delete response.data.data.allowedParents;
                    setFormData(response.data.data);
                }
            })
            .catch((err) => console.error(err.message));
    };

    useEffect(() => {
        const pathnameSegments = location.pathname.split("/");
        const lastSegment = pathnameSegments[pathnameSegments.length - 1];
        if (lastSegment !== "new-account") accountInfo(lastSegment);
        else setExpand(true);
    }, [location]);

    const validateData = (values) => {
        const err = {};

        if (!values.type) {
            err.type = "Please select";
        }
        if (!values.name) {
            err.name = "Please enter account name";
        }
        if (values.parent && !values.parentAc) {
            err.parent = "Please select";
        }

        return err;
    };

    useEffect(() => {
        if (Object.keys(error).length === 0 && isSubmit) {
            setBtnLoading(true);
            axiosInstance
                .post("/accounts/account-update", {
                    ...formData,
                    ...{
                        parentAc: formData.parentAc
                            ? formData.parentAc.value
                            : null,
                        type: formData.type.value,
                    },
                })
                .then(function (response) {
                    setBtnLoading(false);
                    setIsSubmit(false);
                    if(response.data.status === 'success') setFormData(initialVal);
                    setAjaxResponse(response.data)
                    setTimeout(() => {
                        setAjaxResponse({})
                    }, 500);
                })
                .catch((err) => console.error(err.message));
        }
        // eslint-disable-next-line
    }, [error]);

    return (
        <div className="section-body content-area">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <div className="row">
                            <div className="col col-sm-8 input-group d-flex justify-content-start">
                                <div className="col-10 px-0 input-icon table-head-search">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) =>
                                            setDataSearch(e.target.value)
                                        }
                                        value={dataSearch}
                                        placeholder="Search..."
                                    />
                                    <span className="input-icon-addon">
                                        <i className="fe fe-search"></i>
                                    </span>
                                </div>
                                <span className="input-group-append">
                                    <button
                                        className="btn c-red text-white"
                                        type="button"
                                    >
                                        <i className="fe fe-filter"></i>
                                    </button>
                                </span>
                            </div>
                            <div className="col-2 col-sm-4 d-flex justify-content-end mb-1 pl-0 table-nav">
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (pageNo > 0) setPageNo(--pageNo);
                                    }}
                                >
                                    <i className="fe fe-chevron-left"></i>
                                </a>
                                <span className="d-none d-sm-block">
                                    {pagination}
                                </span>
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (pageNo < noOfPages)
                                            setPageNo(++pageNo);
                                    }}
                                >
                                    <i className="fe fe-chevron-right"></i>
                                </a>
                            </div>
                        </div>
                        <div className="card h-100">
                            <div className="card-body px-1">
                                <div className="table-responsive ast-table">
                                    <table className="table table-striped table-vcenter mb-0 text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <span className="table-checkbox-head">
                                                        &nbsp;
                                                    </span>
                                                    Account Name
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.map((data, i) => (
                                                <tr key={i}>
                                                    <td className="d-flex">
                                                        <label className="custom-control custom-checkbox mb-0">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                            />
                                                            <span className="custom-control-label">
                                                                &nbsp;
                                                            </span>
                                                        </label>
                                                        <span>
                                                            <Link to={`/account/${data.Accnt_Id}`} className="invo-txt"
                                                            >{data.Accnt_Name}</Link>
                                                            <span className="d-block small-txt">
                                                                {data.AcType.AcType_Name}
                                                            </span>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-8">
                        <div className="d-flex justify-content-end">
                            <span className="input-group-append">
                                <Link to="/accounts/new-account" className="btn c-blue text-white px-5 font-weight-bold">
                                    New Account
                                </Link>
                            </span>
                        </div>
                        <div className={`card bg-white ${!expand ? "card-collapsed" : ""}`}>
                            <div className="card-header c-pointer" onClick={() => setExpand(!expand)}>
                                <h3 className="card-title">
                                    {formData.accId != 0
                                        ? formData.name
                                        : "Create New Account"}
                                </h3>
                                <div className="card-options">
                                    <span className="card-options-collapse" data-toggle="card-collapse">
                                        <i className="fe fe-chevron-up" />
                                    </span>
                                </div>
                            </div>
                            <div className="card-body border-top">
                                <div className="row clearfix">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label-small w-100">
                                                Account Type{" "}
                                                <span className="red">*</span>
                                            </label>
                                            <Select
                                                options={acTypes}
                                                styles={selectStyle}
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData,
                                                        type: e,
                                                    });
                                                    getAccounts(e.value);
                                                    setError({
                                                        ...error,
                                                        type: null,
                                                    });
                                                }}
                                                value={formData.type}
                                            />
                                            {error.type && (
                                                <small className="red mb-2">
                                                    {error.type}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label-small">
                                                Account Code
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        code: e.target.value,
                                                    })
                                                }
                                                value={formData.code}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label-small">
                                                Account Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.name}
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData,
                                                        name: e.target.value,
                                                    });
                                                    setError({
                                                        ...error,
                                                        name: null,
                                                    });
                                                }}
                                            />
                                            {error.name && (
                                                <small className="red mb-2">
                                                    {error.name}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 pt-2 pb-3">
                                        <div className="custom-switches-stacked">
                                            <label className="custom-switch m-0">
                                                <span className="custom-switch-description ml-0 mr-4">
                                                    Make this a sub-account
                                                </span>
                                                <input
                                                    type="checkbox"
                                                    className="custom-switch-input"
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            ...{parent: e.target.checked, parentAc: null}
                                                        });
                                                    }}
                                                    checked={formData.parent}
                                                />
                                                <span className="custom-switch-indicator" />
                                            </label>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            formData.parent
                                                ? "col-12"
                                                : "col-12 d-none"
                                        }
                                    >
                                        <div className="form-group">
                                            <label className="form-label-small w-100">
                                                Parent Account{" "}
                                                <span className="red">*</span>
                                            </label>
                                            <Select
                                                options={accounts}
                                                styles={selectStyle}
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData,
                                                        parentAc: e,
                                                    });
                                                    setError({
                                                        ...error,
                                                        parent: null,
                                                    });
                                                }}
                                                value={formData.parentAc}
                                            />
                                            {error.parent && (
                                                <small className="red mb-2">
                                                    {error.parent}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label-small">
                                                Description
                                            </label>
                                            <textarea
                                                rows="3"
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        description:
                                                            e.target.value,
                                                    })
                                                }
                                                className="form-control"
                                                value={formData.description}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center mx-auto pt-3">
                                        {btnLoading ? (
                                            <button
                                                className="btn c-red px-4 text-white"
                                                type="button"
                                            >
                                                Please wait...
                                            </button>
                                        ) : (
                                            <button
                                                className="btn c-red px-4 text-white"
                                                onClick={() => {
                                                    setError(
                                                        validateData(formData)
                                                    );
                                                    setIsSubmit(true);
                                                }}
                                                type="button"
                                            >
                                                Save
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {ajaxResponse.status && <div className="user-alert"><div className={`alert rounded-0 ${ajaxResponse.status === 'success' ? 'alert-success' : 'alert-danger'}`}>{ajaxResponse.message}</div></div>}
        </div>
    );
}

export default UpdateAccount;
