import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2'
import { axiosInstance } from '../../../config/axios';
import { convertToDateString } from '../../../config/constants';
import {InputSelect, InputSelectVal, AlertUser, Modal} from "../";

function DeviceList() {

    let [pageNo, setPageNo] = useState(0);
    const [noOfPages, setNoOfPages] = useState(0);
    const [pagination, setPagination] = useState("0-0 of 0");
    const [ajaxResponse, setAjaxResponse] = useState({});

    const vrStatus = [
        { value: 1, label: 'Active' },
        { value: 0, label: 'Inactive' }
    ];

    const [modalOpen, setModalOpen] = useState(false);

    const [filter, setFilter] = useState({
        status: '',
        branch: [],
    });
    const selectChange = (name, val) => {
        setFilter({ ...filter, [name]: val });
    };

    const [branch, setBranch] = useState([]);
    const [data, setData] = useState([]);
    const [dataSearch, setDataSearch] = useState("");
    const fetchData = () => {
        let vrBranch = filter.branch.map(item => item.value);
        axiosInstance.post("/settings/device-list", {
            search: dataSearch,
            pageNo: pageNo,
            branch: vrBranch,
            status: filter.status
        }).then(function (response) {
            if (response.data.status === 'success') {
                setBranch(response.data.data.branch);
                setData(response.data.data.rows);
                setNoOfPages(response.data.data.noofpages);
                setPagination(`${response.data.data.offset}-${response.data.data.limit} of ${response.data.data.count}`);
            }
        }).catch(err => console.log(err));
    };

    const changeStatus = (e, n, id) => {
        let devStatus = e.target.checked
        axiosInstance.post("/settings/device-status", {
            id: id,
            status: devStatus ? 1 : 0,
        }).then(function (response) {
            if (response.data.status === 'success') {
                const updatedArray = [
                    ...data.slice(0, n), // elements before the nth element
                    { ...data[n], Device_Status: devStatus }, // updated nth element
                    ...data.slice(n + 1), // elements after the nth element
                ];
                setData(updatedArray);
            } else {
                setAjaxResponse(response.data)
                setTimeout(() => {
                    setAjaxResponse({})
                }, 3000);
            }
        }).catch(err => console.log(err));
    }

    useEffect(() => {
        fetchData();
    }, [dataSearch, pageNo]);

    const closeModal = () => {
        fetchData();
        setModalOpen(false);
    };

    const validatePrefix = () => {
        var regex = /^[a-zA-Z]{4}$/;
        return regex.test(active.Device_Prefix);
    }

    const [active, setActive] = useState({});
    const [btnDevice, setBtnDevice] = useState(false);
    const [activeOpen, setActiveOpen] = useState(false);
    const viewDevicePopUp = (row) => {
        setActive(row);
        setActiveOpen(true);
    };
    const ajaxSaveDevice = () => {
        axiosInstance.post("/settings/device-update", {
            id: active.Device_Id,
            name: active.Device_Name,
            prefix: active.Device_Prefix
        }).then(function (response) {
            setBtnDevice(false);
            if(response.data.status === 'success') {
                setActiveOpen(false);
                fetchData();
            }
            setAjaxResponse(response.data)
            setTimeout(() => {
                setAjaxResponse({})
            }, 3000);
        }).catch(err => console.log(err));
    };
    const saveDevice = () => {

        let proceed = true;
        if (!validatePrefix()) proceed = false;
        if (active.Device_Prefix === '') proceed = false;
        if (proceed) {

            setBtnDevice(true);

            // check if prefix changed
            let vrCurrent = data.find(i => i.Device_Id == active.Device_Id);
            if (active.Device_Prefix != vrCurrent.Device_Prefix) {
                Swal.fire({
                    title: "Warning",
                    text: "Are you sure you want to change device prefix?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes"
                }).then((result) => {
                    if (result.isConfirmed) {
                        ajaxSaveDevice();
                    } else {
                        setBtnDevice(false);
                    }
                });
            } else {
                ajaxSaveDevice();
            }
        }
    };

    return (
        <div className="section-body content-area">
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-sm-8 input-group d-flex justify-content-start">
                        <div className="col-md-4 col-11 px-0 input-icon table-head-search">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search"
                                onChange={(e) => setDataSearch(e.target.value)}
                                value={dataSearch}
                            />
                            <span className="input-icon-addon">
                                <i className="fe fe-search"></i>
                            </span>
                        </div>
                        <span className="input-group-append"><button className="btn c-blue text-white" onClick={() => setModalOpen(true)} type="button"><i className="fe fe-filter"></i></button></span>
                        <span className="input-group-append dropdown">
                            <button className="btn c-lgt-blue text-white" type="button" data-toggle="dropdown">
                                <i className="fe fe-sliders"></i>
                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                    <a className="dropdown-item" href="#">Delete</a>
                                    <a className="dropdown-item" href="#">Enable</a>
                                    <a className="dropdown-item" href="#">Disable</a>
                                </div>
                            </button>
                        </span>
                        <span className="input-group-append dropdown">
                            <button className="btn bg-white" type="button" data-toggle="dropdown">
                                <i className="fe fe-download"></i>
                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                    <a className="dropdown-item" href="#"><i className="dropdown-icon fa fa-file-excel-o"></i> Download Details</a>
                                </div>
                            </button>
                        </span>
                    </div>
                    <div className="col-2 col-sm-4 d-flex justify-content-end mb-1 table-nav">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            if (pageNo > 0) setPageNo(--pageNo)
                        }}>
                            <i className="fe fe-chevron-left pr-sm-1 pr-2"></i>
                        </a>
                        <span className="px-1 d-none d-sm-block">
                            {pagination}
                        </span>
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            if (pageNo < noOfPages) setPageNo(++pageNo)
                        }}>
                            <i className="fe fe-chevron-right pl-sm-1 pl-2"></i>
                        </a>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body px-1">
                        <div className="table-responsive ast-table">
                            <table className="table table-striped table-vcenter mb-0 text-nowrap">
                                {data.length > 0 && (<thead>
                                    <tr>
                                        <th><span className="table-checkbox-head">&nbsp;</span>Prefix</th>
                                        <th>Device Name</th>
                                        <th>Branch</th>
                                        <th>Last Login</th>
                                        <th>App Ver.</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>)}
                                <tbody>
                                    {data.map((itm, i) => (
                                        <tr key={i}>
                                            <td className="d-flex">
                                                <label className="custom-control custom-checkbox mb-0">
                                                    <input type="checkbox" className="custom-control-input" />
                                                    <span className="custom-control-label"> &nbsp; </span>
                                                </label>
                                                <span> <span className="invo-txt" onClick={() => viewDevicePopUp(itm)}>{itm.Device_Prefix}</span> </span>
                                            </td>
                                            <td>{itm.Device_Name ? itm.Device_Name : 'N/A'}</td>
                                            <td>{itm.branch ? itm.branch.Branch_Name : 'N/A'}</td>
                                            <td>{convertToDateString(itm.Last_Online)}</td>
                                            <td>{itm.App_Ver}</td>
                                            <td><i className={itm.Device_Status ? 'fa fa-circle green' : 'fa fa-circle red'}></i> {itm.Device_Status ? 'Active' : 'Inactive'}</td>
                                            <td>
                                                <label className="custom-switch m-0">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        onChange={(e) => {
                                                            changeStatus(e, i, itm.Device_Id);
                                                        }}
                                                        checked={itm.Device_Status}
                                                    />
                                                    <span className="custom-switch-indicator" />
                                                </label>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {data.length <= 0 && <p className="w-100 text-center pt-5">No Data Found</p>}
                        </div>
                    </div>
                </div>
            </div>

            <AlertUser {...ajaxResponse} />

            <Modal title="FILTER" itemwidth="modal-sm" isOpen={modalOpen} onClose={() => setModalOpen(false)}>
                <div className="modal-body">
                    <div className="row">
                        <InputSelect wrapper="col-12" title="Status" required={false} name="status" value={filter.status} data={vrStatus} onSelect={selectChange} />
                        <InputSelectVal wrapper="col-12" title="Branch" required={false} name="branch" value={filter.branch} data={branch} multiple={true} onSelect={selectChange} />
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => {
                        closeModal()
                    }}>
                        Search
                    </button>
                </div>
            </Modal>

            <Modal title="USER DEVICE" itemwidth="modal-md" isOpen={activeOpen} onClose={() => setActiveOpen(false)}>
                <div className="modal-body">
                    <div className="row">
                        <div className="table-responsive ast-table">
                            <table className="table table-borderless table-vcenter mb-0 text-nowrap">
                                <tbody>
                                    <tr>
                                        <td>Device Name</td>
                                        <td><input type="text" className="form-control" value={active && (active.Device_Name ? active.Device_Name : '')} name="Device_Name" onChange={(e) => setActive({ ...active, [e.target.name]: e.target.value })} /></td>
                                    </tr>
                                    <tr>
                                        <td>Prefix</td>
                                        <td>
                                            <input type="text" className="form-control" value={active && active.Device_Prefix} name="Device_Prefix" onChange={(e) => {
                                                const newValue = e.target.value.replace(/[^A-Za-z]/g, '').slice(0, 4).toUpperCase();
                                                setActive({ ...active, [e.target.name]: newValue });
                                            }} />
                                            {(!validatePrefix() || active.Device_Prefix === '') && <small className="lbl-error">Please enter value</small>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Device Type</td>
                                        <td>{active && active.Device_Model}</td>
                                    </tr>
                                    <tr>
                                        <td>Device Id</td>
                                        <td>{active && active.Device_UniqId}</td>
                                    </tr>
                                    <tr>
                                        <td>Last Login</td>
                                        <td>{active ? convertToDateString(active.Last_Online) : null}</td>
                                    </tr>
                                    <tr>
                                        <td>Location</td>
                                        <td>{active && active.Device_Location}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    {btnDevice ? (<button type="button" className="btn btn-primary">Please wait...</button>) : (<button type="button" className="btn btn-primary" onClick={saveDevice}>Update</button>)}                    
                </div>
            </Modal>
        </div>
    );
}

export default DeviceList;
