import React from "react";

function InputText(props) {
    return (
        <div className={props.wrapper}>
            <div className="form-group">
                <label className="form-label-small">{props.title} {props.required && <span className="red">*</span>}</label>
                <input 
                    type={props.field ? props.field : "text"} 
                    className="form-control"
                    value={props.value}
                    name={props.name}
                    onChange={(e)=>{
                        let txt = e.target.value;
                        if(props.type) {
                            let regex = '';
                            if(props.type === 'decimal') regex = /^\d*\.?\d*$/;
                            else if(props.type === 'digits') regex = /^\d*$/;
                            else if(props.type === 'alphanum') regex = /^[a-zA-Z0-9]*$/;
                            if(!regex.test(txt)) e.target.value = txt.slice(0, -1);
                        }
                        props.onChange(e);
                    }}
                    readOnly={props.readonly}
                />
                {props.error && (<small className="lbl-error">{props.error}</small>)}
            </div>
        </div>
    );
}

export default InputText;
