import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import Swal from 'sweetalert2';

import { DropDown, AlertUser } from "../../";
import { axiosInstance } from "../../../../config/axios";

function VendorInfo() {
    const { id } = useParams();
    const history = useHistory();
    const [data, setData] = useState({});

    const [ajaxResponse, setAjaxResponse] = useState({});
    useEffect(() => {
        setTimeout(() => {
            setAjaxResponse({});
        }, 5000);
    }, [ajaxResponse]);

    useEffect(() => {
        axiosInstance
            .get(`/people/vendor/vendor-details?id=${id}`)
            .then(function (res) {
                if (res.data.status === "success") {
                    setData(res.data.data);
                }
            }).catch((e) => console.log(e));
    }, []);

    const deleteVendor = ()=> {
        Swal.fire({
            title: "Are you sure?",
            text: "You wan't to delete !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                axiosInstance.post("/people/vendor/vendor-delete", {
                    id: [data.Vendor_Id]
                }).then(function (res) {
                    setAjaxResponse({ status: res.data.status, message: res.data.message });
                    history.push('/people/vendor');
                }).catch(err => console.log(err));
            }
        });
    };

    const dropDownAction = [
        { icon: 'fe fe-layers', text: 'Invoices', url: `/people/vendor/invoices/${id}` },
        { icon: 'fe fe-layers', text: 'Payment History', url: `/people/vendor/payment-history/${id}` },
        { icon: 'fe fe-copy', text: 'Payment to be adjusted', url: `/people/vendor/payment-to-adjust/${id}` },
        { icon: 'fe fe-shopping-bag', text: 'Pending Orders', url: `/people/vendor/pending-orders/${id}` },
        { icon: 'fe fe-layers', text: 'Products', url: `/people/vendor/products/${id}` },
        { icon: 'fe fe-trash-2', text: 'Delete', callback: deleteVendor },
    ];

    return (
        <>
            {data.Vendor_Name && (
                <div className="section-body content-area">
                    <div className="container-fluid">
                        <div className="row border-bottom">
                            <div className="col col-sm-8 input-group d-flex justify-content-start">
                                <span className="input-group-append">
                                    <Link to={`/people/vendor/update-vendor/${data.master.Vendor_Code}`} className="btn c-red text-white px-4">
                                        Edit
                                    </Link>
                                </span>
                                <span className="input-group-append">
                                    <Link to={`/people/vendor/payment/${id}`} className="btn c-blue text-white px-4">
                                        Add Payment
                                    </Link>
                                </span>
                                <DropDown color="c-lgt-blue" icon="fe fe-sliders" items={dropDownAction} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="card">
                                    <div className="card-header pt-3 border-top">
                                        <h6 className="p-0 m-0">
                                            <i className="fa fa-long-arrow-right text-red varient-button pr-2"></i>{" "}
                                            Vendor Details
                                        </h6>
                                    </div>
                                    <div className="card-body pt-2 pb-0">
                                        <div className="table-responsive">
                                            <table className="table table-borderless table-vcenter">
                                                <tbody>
                                                    <tr>
                                                        <td>Vendor Name</td>
                                                        <td>{data.Vendor_Name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Phone</td>
                                                        <td>{data.Vendor_Phone}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Email</td>
                                                        <td>{data.Vendor_Email}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Address</td>
                                                        <td>{data.Vendor_Address}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>City</td>
                                                        <td>{data.Vendor_City}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>State</td>
                                                        <td>{data.Vendor_State}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Country</td>
                                                        <td>{data.Vendor_Country}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pincode</td>
                                                        <td>{data.Vendor_ZIP}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header border-bottom pt-3">
                                        <h6 className="p-0 m-0">
                                            <i className="fa fa-long-arrow-right text-red varient-button pr-2"></i>{" "}
                                            TAX
                                        </h6>
                                    </div>
                                    <div className="card-body pt-2 pb-0">
                                        <div className="table-responsive">
                                            <table className="table table-borderless table-vcenter">
                                                <tbody>
                                                    <tr>
                                                        <td>GST Number</td>
                                                        <td>{data.Vendor_GST ? data.Vendor_GST : 'N/A'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>TIN</td>
                                                        <td>{data.Vendor_TIN ? data.Vendor_TIN : 'N/A'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header border-bottom pt-3">
                                        <h6 className="p-0 m-0">
                                            <i className="fa fa-long-arrow-right text-red varient-button pr-2"></i>{" "}
                                            REGULATORY INFORMATION
                                        </h6>
                                    </div>
                                    <div className="card-body pt-2 pb-0">
                                        <div className="table-responsive">
                                            <table className="table table-borderless table-vcenter">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Whole Sale Drug Licence
                                                        </td>
                                                        <td>{data.Vendor_Drug ? 'Yes' : 'No'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Insecticide Licence
                                                        </td>
                                                        <td>{data.Vendor_Insecticide ? 'Yes' : 'No'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Liquor Licence</td>
                                                        <td>{data.Vendor_Liquor ? 'Yes' : 'No'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="card">
                                    <div className="card-status c-lgt-blue"></div>
                                    <div className="card-header border-bottom pt-3">
                                        <h6 className="p-0 m-0">
                                            <i className="fa fa-long-arrow-right text-red varient-button pr-2"></i>{" "}
                                            Contact Info
                                        </h6>
                                    </div>
                                    <div className="card-body pt-2 pb-0">
                                        {data.contact.length > 0 ? <div className="table-responsive ast-table">
                                            <table className="table table-vcenter mb-0 text-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Designation</th>
                                                        <th>Phone</th>
                                                        <th className="inw-m">Email</th>
                                                        <th className="inw-s">Primary Contact</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.contact.map((ob, key)=>(
                                                    <tr key={ob.VendorCnt_Id}>
                                                        <td>{ob.VendorCnt_Name}</td>
                                                        <td>{ob.VendorCnt_Desig}</td>
                                                        <td>{ob.VendorCnt_Phone}</td>
                                                        <td>{ob.VendorCnt_Email}</td>
                                                        <td className="text-center">{key == 0 ? <i class="fa fa-check pr-2 green pt-1"></i> : ''}</td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div> : <div className="row"><p className="text-center w-100 pt-3 pb-2">No records found</p></div>}
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header border-bottom pt-3">
                                        <h6 className="p-0 m-0">
                                            <i className="fa fa-long-arrow-right text-red varient-button pr-2"></i>{" "}
                                            Account Information
                                        </h6>
                                    </div>
                                    <div className="card-body pt-2 pb-0">
                                        {data.accounts ? <div className="table-responsive">
                                            <table className="table table-borderless table-vcenter">
                                                <tbody>
                                                    <tr>
                                                        <td className="inw-m">Account Name</td>
                                                        <td>{data.accounts.VendorAcc_Name ? data.accounts.VendorAcc_Name : 'N/A'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Account Number</td>
                                                        <td>{data.accounts.VendorAcc_No ? data.accounts.VendorAcc_No : 'N/A'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IFSC Code</td>
                                                        <td>{data.accounts.VendorAcc_IFSC ? data.accounts.VendorAcc_IFSC : 'N/A'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>UPI Address</td>
                                                        <td>{data.accounts.VendorAcc_UPI ? data.accounts.VendorAcc_UPI : 'N/A'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Credit Offered</td>
                                                        <td>{data.accounts.VendorAcc_Credit ? 'Yes' : 'No'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> : <div className="row"><p className="text-center w-100 pt-3 pb-2">No records found</p></div>}
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header border-bottom pt-3">
                                        <h6 className="p-0 m-0">
                                            <i className="fa fa-long-arrow-right text-red varient-button pr-2"></i>{" "}
                                            Policies
                                        </h6>
                                    </div>
                                    <div className="card-body pt-2 pb-0">
                                        {(data.Vendor_Return || data.Vendor_Refund || data.Vendor_Pricing) ? <div className="table-responsive ast-table">
                                            <table className="table table-vcenter mb-0 text-nowrap">
                                                <tbody>
                                                    <tr>
                                                        <td className="inw-m">Return Policy
                                                        </td>
                                                        <td>{data.Vendor_Return}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Refund Policy</td>
                                                        <td>{data.Vendor_Refund}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pricing Agreement</td>
                                                        <td>{data.Vendor_Pricing}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> : <div className="row"><p className="text-center w-100 pt-3 pb-2">No records found</p></div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default VendorInfo;
