const host = window.location.host
const hostarr = host.split('.')
    .slice(0, host.includes('localhost') ? -1 : -2);
let subdom = `console`;
if (hostarr.length > 0) subdom = hostarr[0];

export const client = subdom

export const appurl = 'https://api.slatepos.app/console'
// export const appurl = 'http://localhost:3011/console'

export const selectStyle = {
    control: (provided) => ({
        ...provided,
        borderWidth: "1px",
        borderColor: "#E8E9E9",
        borderRadius: "0px",
        maxHeight: "50px"
    }),
    menu: (provided, state) => ({
        ...provided,
        maxHeight: '200px',  // Set the maximum height of the dropdown
        overflowY: 'auto',   // Enable vertical scrolling
    }),
};

export const reportFilter = [
    { value: 'today', label: 'Today' },
    { value: 'this-week', label: 'This Week' },
    { value: 'this-month', label: 'This Month' },
    { value: 'this-quarter', label: 'This Quarter' },
    { value: 'this-year', label: 'This Year' },
    { value: 'yesterday', label: 'Yesterday' },
    { value: 'previous-week', label: 'Previous Week' },
    { value: 'previous-month', label: 'Previous Month' },
    { value: 'previous-quarter', label: 'Previous Quarter' },
    { value: 'previous-year', label: 'Previous Year' },
    { value: 'custom', label: 'Custom' }
];

export const convertToDateString = (str) => {
    const date = new Date(str);
    const formattedDate = date.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });

    const formattedTime = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit'
    });
    return `${formattedDate}, ${formattedTime}`;
}