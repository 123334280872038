import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { DropDown, Modal, InputSelectVal, AlertUser } from '../../';
import { axiosInstance } from "../../../../config/axios";

function StockList() {

    const branchInfo = useSelector((store)=>{
        return store.branch;
    });

    const [ajaxResponse, setAjaxResponse] = useState({});
    useEffect(() => {
        setTimeout(() => {
            setAjaxResponse([]);
        }, 3000);
    }, [ajaxResponse])

    const [tab, setTab] = useState(1);
    const [totalPages, setTotalPages] = useState({ active: 0, inactive: 0 });
    const [pageAlabel, setPageAlabel] = useState('0-0 of 0');
    let [pageActive, setPageActive] = useState(0);
    const [pageIlabel, setPageIlabel] = useState('0-0 of 0');
    let [pageInActive, setPageInActive] = useState(0);

    const [activeData, setActiveData] = useState([]);
    const [inactiveData, setInActiveData] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const closeModal = () => setModalOpen(false);

    const [mdlVariant, setMdlVariant] = useState(false);
    const closeMdlVariant = () => setMdlVariant(false);
    const [mdlPricing, setMdlPricing] = useState(false);
    const closeMdlPricing = () => setMdlPricing(false);
    const [mdlStock, setMdlStock] = useState(false);
    const closeMdlStock = () => setMdlStock(false);
    const [activeItem, setActiveItem] = useState({});

    const [filter, setFilter] = useState({
        search: '',
        insearch: '',
        category: [],
        inventory: '',
        channel: ''
    });
    const [mdlFilter, setMdlFilter] = useState({
        category: [],
        channel: [],
    });

    const selectChange = (name, val) => {
        setFilter({ ...filter, [name]: val });
    };

    const testFun = ()=> console.log('hi');
    const inActivateItems = ()=> changeItemStatus(0);
    const activateItems = ()=> changeItemStatus(1);

    const activeStatus = [
        { icon: 'fe fe-eye-off', text: 'Inactive', callback: inActivateItems },
        { icon: 'fe fe-printer', text: 'Print Barcode', callback: testFun }
    ];
    const activeDownload = [
        { title: true, text: 'Products' },
        { icon: 'fa fa-file-excel-o', text: 'Download Products', callback: testFun },
        { title: true, text: 'Template' },
        { icon: 'fa fa-file-excel-o', text: 'Download Template', callback: testFun }
    ];
    const activeUpload = [
        { title: true, text: 'Upload' },
        { icon: 'fa fa-file-excel-o', text: 'Upload New Products', callback: testFun }
    ];
    const inActiveStatus = [
        { icon: 'fe fe-eye', text: 'Active', callback: activateItems },
        { icon: 'fe fe-printer', text: 'Print Barcode', callback: testFun }
    ];

    const [checkedData, setCheckedData] = useState([]);
    const checkItems = (e)=> {
        let tempIds = JSON.parse(JSON.stringify(checkedData));
        if(e.target.checked) {
            if (!tempIds.includes(e.target.value)) 
                tempIds.push(e.target.value);
        } else {
            const index = tempIds.indexOf(e.target.value);
            if (index !== -1) 
            tempIds.splice(index, 1);
        }
        setCheckedData(tempIds);
    };    

    const fetchData = ()=> {

        // empty checked data
        setCheckedData([]);

        let category = [];
        let subcat = [];
        if(filter.category) {
            filter.category.forEach(el => {
                if(el.isCat) category.push(el.value);
                else subcat.push(el.value);
            });
        }

        axiosInstance.post("/inventory/item-list", {
            search: tab == 1 ? filter.search : filter.insearch,
            category: category,
            subcat: subcat,
            status: tab,
            pageNo: tab ? pageActive : pageInActive
        }).then(function (res) {
            if (res.data.status === 'success') {
                if(tab == 1) {
                    setActiveData(res.data.data.items);
                    setInActiveData([]);
                    setTotalPages({...totalPages, active: res.data.data.noofpages});
                    setPageAlabel(`${res.data.data.offset}-${res.data.data.limit} of ${res.data.data.total}`);
                } else {
                    setInActiveData(res.data.data.items);
                    setActiveData([]);
                    setTotalPages({...totalPages, inactive: res.data.data.noofpages});
                    setPageIlabel(`${res.data.data.offset}-${res.data.data.limit} of ${res.data.data.total}`);
                }
            } else {
                if(tab == 1) {
                    setActiveData([]);
                    setTotalPages({...totalPages, active: 0});
                    setTotalPages({...totalPages, active: res.data.data.noofpages});
                    setPageAlabel('0-0 of 0')
                }
            }
        }).catch(err => console.log(err));
    };

    useEffect(() => {
        axiosInstance.get("/inventory/item-filter")
        .then(function (res) {
            if (res.data.status === 'success') {
                setMdlFilter({
                    ...mdlFilter,
                    ...{category: res.data.category, channel: res.data.channel}
                });

                let chnl = '';
                if(res.data.channel[0]) {
                    chnl = res.data.channel[0].value;
                }
                setFilter({ ...filter, channel: chnl });
            }
        }).catch(err => console.log(err));
    }, []);

    const fetchFreshData = () => {
        setPageActive(0);
        fetchData();
    };

    // change item status
    const changeItemStatus = (status)=>{

        if(checkedData.length > 0) {
            axiosInstance.post("/inventory/update-status", {
                items: checkedData,
                status
            }).then(function (res) {
                if (res.data.status === 'success') {
                    fetchFreshData();
                    setAjaxResponse({ status: 'success', message: res.data.message });
                }
            }).catch(err => console.log(err));
        }
    };

    useEffect(() => {
        fetchData();
    }, [filter.search, pageActive]);

    useEffect(() => {
        fetchData();
    }, [filter, tab]);

    return (<>
    <div className="section-body">
        <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center horizontal-nav">
                <ul className="nav nav-tabs page-header-tab">
                    <li className="nav-item"><span className={`nav-link ${tab == 1 ? 'active' : ''}`} onClick={()=>setTab(1)}>Active</span></li>
                    <li className="nav-item"><span className={`nav-link ${tab == 0 ? 'active' : ''}`} onClick={()=>setTab(0)}>Inactive</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div className="section-body content-area">
        <div className="container-fluid">
            <div className="tab-content">
                <div className={`tab-pane fade ${tab == 1 ? 'show active' : ''}`} role="tabpanel">
                    <div className="row border-bottom">
                        <div className="col col-sm-8 input-group d-flex justify-content-start">
                            <div className="col-md-3 col-11 px-0 input-icon table-head-search">
                                <input type="text" className="form-control" placeholder="Search for" value={filter.search} onChange={(e)=>setFilter({...filter, search: e.target.value})} />
                                <span className="input-icon-addon"><i className="fe fe-search"></i></span>
                            </div>
                            <span className="input-group-append"><Link to="/inventory/stock/create-stock" className="btn c-blue text-white"><i className="fe fe-plus"></i> Add New</Link></span>
                            <DropDown color="text-white c-lgt-blue" icon="fe fe-sliders" items={activeStatus} />
                            <span className="input-group-append"><button onClick={()=>setModalOpen(true)} className="btn c-red text-white" type="button"><i className="fe fe-filter"></i></button></span>
                            <DropDown color="bg-white" icon="fe fe-download" items={activeDownload} />
                            <DropDown color="bg-white border-left" icon="fe fe-upload" items={activeUpload} />
                        </div>
                        <div className="col-2 col-sm-4 d-flex justify-content-end mb-1 table-nav">
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                if (pageActive > 0) setPageActive(--pageActive)
                            }}><i className="fe fe-chevron-left pr-sm-1 pr-2"></i></a>
                            <span className="px-1 d-none d-sm-block">{pageAlabel}</span>
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                if (pageActive < totalPages.active) {
                                    setPageActive(++pageActive);
                                }
                            }}><i className="fe fe-chevron-right pl-sm-1 pl-2"></i></a>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive ast-table">
                                <table className="table table-striped table-vcenter mb-0 text-nowrap">
                                    <thead>
                                        <tr>
                                            <th><span className="table-checkbox-head">&nbsp;</span> Item Name</th>
                                            <th>Cost Price</th>
                                            <th>Total Quantity</th>
                                            <th>Units</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {activeData.map((itm, i)=>(
                                        <tr key={itm.id}>
                                            <td className="d-flex justify-content-between">
                                                <span className="d-flex">
                                                    <label className="custom-control custom-checkbox mb-0">
                                                        <input type="checkbox" className="custom-control-input" value={itm.id} onChange={checkItems} />
                                                        <span className="custom-control-label">&nbsp;</span>
                                                    </label>
                                                    <Link to={`/inventory/stock/stock-details/${itm.id}`} className="invo-txt">{itm.name}</Link>
                                                </span>
                                                {itm.variants.length > 1 && (<span onClick={()=>{
                                                    setActiveItem(itm);
                                                    setMdlVariant(true);
                                                }} className="mx-3 c-pointer"><i className="fe fe-plus-circle varient-button"></i></span>)}                   
                                            </td>
                                            <td>
                                                {itm.variants[0] ? parseFloat
                                            (itm.variants[0].cost).toFixed(branchInfo.Branch_DecimalPoints) : 'N/A'}
                                            {itm.variants.length > 1 && (<span onClick={()=>{
                                                setActiveItem(itm);
                                                setMdlPricing(true);
                                            }} className="c-pointer"><i className="fe fe-arrow-up-right"></i></span>)}
                                            </td>
                                            <td>
                                            {itm.variants[0] ? itm.variants[0].quantity.total : 'N/A'}
                                                {itm.variants.length > 1 && (<span onClick={()=>{
                                                setActiveItem(itm);
                                                setMdlStock(true);
                                            }} className="c-pointer"><i className="fe fe-arrow-up-right"></i></span>)}</td>
                                            <td>{itm.unit}</td>
                                            <td>
                                            {itm.variants[0] ? (itm.variants[0].quantity.total <= 0 ? (<span className="text-red"><i className="fe fe-alert-circle text-red pr-1"></i> Out of stock</span>) : (itm.variants[0].quantity.total < itm.variants[0].threshold ? (<span className="text-orange"><i className="fe fe-alert-circle text-orange pr-1"></i> Below threshold</span>) : (<span><i className="fa fa-check green pr-1"></i> Available</span>))) : 'N/A'}
                                            </td>
                                        </tr>
                                    ))}
                                    {activeData.length == 0 && (<tr><td colSpan={5}><p className="text-center pt-3 text-gray">No Data Found</p></td></tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`tab-pane fade ${tab == 0 ? 'show active' : ''}`} role="tabpanel">
                    <div className="row border-bottom">
                        <div className="col col-sm-8 input-group d-flex justify-content-start">
                            <div className="col-md-3 col-11 px-0 input-icon table-head-search">
                                <input type="text" className="form-control" placeholder="Search for" value={filter.insearch} onChange={(e)=>setFilter({...filter, insearch: e.target.value})} />
                                <span className="input-icon-addon"><i className="fe fe-search"></i></span>
                            </div>
                            <span className="input-group-append"><Link to="/inventory/stock/create-stock" className="btn c-blue text-white"><i className="fe fe-plus"></i> Add New</Link></span>
                            <DropDown color="text-white c-lgt-blue" icon="fe fe-sliders" items={inActiveStatus} />
                            <span className="input-group-append"><button onClick={()=>setModalOpen(true)} className="btn c-red text-white" type="button"><i className="fe fe-filter"></i></button></span>
                        </div>
                        <div className="col-2 col-sm-4 d-flex justify-content-end mb-1 table-nav mt-1">
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                if (pageInActive > 0) setPageInActive(--pageInActive)
                            }}><i className="fe fe-chevron-left pr-sm-1 pr-2"></i></a>
                            <span className="px-1 d-none d-sm-block">{pageIlabel}</span>
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                if (pageInActive < totalPages.inactive) {
                                    setPageInActive(++pageInActive);
                                }
                            }}><i className="fe fe-chevron-right pl-sm-1 pl-2"></i></a>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive ast-table">
                                <table className="table table-striped table-vcenter mb-0 text-nowrap">
                                    <thead>
                                        <tr>
                                            <th><span className="table-checkbox-head">&nbsp;</span> Item Name</th>
                                            <th>Product Code</th>
                                            <th>Selling Price</th>
                                            <th>Inventory Type</th>
                                            <th>Favourite</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {inactiveData.map((itm, i)=>(
                                        <tr key={itm.id}>
                                            <td className="d-flex justify-content-between">
                                                <span className="d-flex">
                                                    <label className="custom-control custom-checkbox mb-0">
                                                        <input type="checkbox" className="custom-control-input" value={itm.id} onChange={checkItems} />
                                                        <span className="custom-control-label">&nbsp;</span>
                                                    </label>
                                                    {itm.name}
                                                </span>
                                                {itm.variants.length > 1 && (<span onClick={()=>{
                                                    setActiveItem(itm);
                                                    setMdlVariant(true);
                                                }} className="mx-3 c-pointer"><i className="fe fe-plus-circle varient-button"></i></span>)}                   
                                            </td>
                                            <td>
                                                {itm.variants[0] ? parseFloat
                                            (itm.variants[0].cost).toFixed(branchInfo.Branch_DecimalPoints) : 'N/A'}
                                            {itm.variants.length > 1 && (<span onClick={()=>{
                                                setActiveItem(itm);
                                                setMdlPricing(true);
                                            }} className="c-pointer"><i className="fe fe-arrow-up-right"></i></span>)}
                                            </td>
                                            <td>
                                            {itm.variants[0] ? itm.variants[0].quantity.total : 'N/A'}
                                                {itm.variants.length > 1 && (<span onClick={()=>{
                                                setActiveItem(itm);
                                                setMdlStock(true);
                                            }} className="c-pointer"><i className="fe fe-arrow-up-right"></i></span>)}</td>
                                            <td>{itm.unit}</td>
                                            <td>
                                            {itm.variants[0] ? (itm.variants[0].quantity.total <= 0 ? (<span className="text-red"><i className="fe fe-alert-circle text-red pr-1"></i> Out of stock</span>) : (itm.variants[0].quantity.total < itm.variants[0].threshold ? (<span className="text-orange"><i className="fe fe-alert-circle text-orange pr-1"></i> Below threshold</span>) : (<span><i className="fa fa-check green pr-1"></i> Available</span>))) : 'N/A'}
                                            </td>
                                        </tr>
                                    ))}
                                    {inactiveData.length == 0 && (<tr><td colSpan={5}><p className="text-center pt-3 text-gray">No Data Found</p></td></tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Modal title="FILTER" itemwidth="modal-sm" isOpen={modalOpen} onClose={closeModal}>
        <div className="modal-body">
            <div className="row">
                <InputSelectVal wrapper="col-12" title="Category" required={false} name="category" value={filter.category} data={mdlFilter.category} multiple={true} onSelect={selectChange} />
            </div>
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={()=>{
                closeModal();
                fetchFreshData();
            }}>
                Search
            </button>
        </div>
    </Modal>
        
    {activeItem.name && (
    <Modal title={`VARIENTS | ${activeItem.name}`} itemwidth="modal-xl" isOpen={mdlVariant} onClose={closeMdlVariant}>
        <div className="modal-body">
            <div className="row">
                <div className="table-responsive">
                    <table className="table table-striped table-vcenter mb-0 text-nowrap">
                        <thead>
                            <tr>
                                <th>SKU</th>
                                <th>Name</th>
                                <th>UPC</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {activeItem.variants.map((itm, i)=>(
                            <tr key={i}>
                                <td>{itm.sku}</td>
                                <td>{itm.name}</td>
                                <td>{itm.upc}</td>
                                <td>{itm.quantity.total <= 0 ? 'Out Of Stock' : 'Available'}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </Modal>
    )}

    {activeItem.name && (
    <Modal title={`VARIENTS | ${activeItem.name}`} itemwidth="modal-xl" isOpen={mdlPricing} onClose={closeMdlPricing}>
        <div className="modal-body">
            <div className="row">
                <div className="table-responsive">
                    <table className="table table-striped table-vcenter mb-0 text-nowrap">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Cost</th>
                                <th>MRP</th>
                                {mdlFilter.channel.map((c, i)=>(
                                <th key={i}>{c.label}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                        {activeItem.variants.map((itm, i)=>(
                            <tr key={i}>
                                <td>{itm.name}</td>
                                <td>{itm.cost}</td>
                                <td>{itm.mrp}</td>
                                {mdlFilter.channel.map((c, i)=>{
                                    let price = itm.pricing.find(o=>o.Channel_Id == c.value);
                                    return (<td key={i}>{price ? parseFloat(price.ItemC_Price).toFixed(branchInfo.Branch_DecimalPoints) : '-'}</td>)
                                })}
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </Modal>
    )}

    {activeItem.name && (
    <Modal title={`VARIENTS | ${activeItem.name}`} itemwidth="modal-xl" isOpen={mdlStock} onClose={closeMdlStock}>
        <div className="modal-body">
            <div className="row">
                <div className="table-responsive">
                    <table className="table table-striped table-vcenter mb-0 text-nowrap">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th className="text-center">Store Qty</th>
                                {mdlFilter.channel.map((c, i)=>(
                                    (c.allocation === 1 || c.isPrimary === 1) ?
                                    (<th className="text-center" key={i}>{c.label}</th>) : null
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                        {activeItem.variants.map((itm, i)=>(
                            <tr key={i}>
                                <td>{itm.name}</td>
                                <td className="text-center">{itm.quantity[0]}</td>
                                {mdlFilter.channel.map((c, i)=>(
                                    (c.allocation === 1 || c.isPrimary === 1) ? (<td className="text-center">{itm.quantity[c.value] ? itm.quantity[c.value] : '-'}</td>) : null
                                ))}
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </Modal>
    )}
    <AlertUser {...ajaxResponse} />
    </>);
}

export default StockList;
