import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { InputText, InputSelectVal, AlertUser } from '../../..';
import { AutosuggestItem, AutosuggestVendor } from '../..';
import { axiosInstance } from "../../../../../config/axios";

function PO() {

    const history = useHistory();
    
    const { id } = useParams();
    const [activePO, setActivePO] = useState(id ? id : null);

    const branchInfo = useSelector((store)=>{
        return store.branch;
    });

    const [ajaxResponse, setAjaxResponse] = useState({});
    useEffect(() => {
        setTimeout(() => {
            setAjaxResponse({});
        }, 10000);
    }, [ajaxResponse]);

    const [loading, setLoading] = useState({
        item: false,
        po: false
    });
    const [error, setError] = useState({});
    const [variant, setVariant] = useState([]);
    const [formData, setFormData] = useState({
        item: null,
        variant: null,
        prevPrice: '',
        qnty: '',
        askPrice: '',
    });

    const [formPO, setFormPO] = useState({
        poid: null,
        vendor: {},
        expData: new Date().toISOString().split('T')[0],
        message: ''
    });

    const [data, setData] = useState([]);

    const inputChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
        setError({...error, [e.target.name]: ''});
    }

    const onItemSearch = (newValue)=>{
        setFormData({...formData, item: newValue});
        setError({...error, item: ''});
    };
    const onItemSelected = (e, { suggestion }) => {
        setVariant(suggestion.variant);
        setError({...error, variant: ''});
    };

    const onSelect = (name, val)=>{
        setFormData({...formData, ...{[name]: val, prevPrice: parseFloat(val.cost).toFixed(branchInfo.Branch_DecimalPoints) }});
        console.log(val);
    };

    const addItem = ()=>{

        let proceed = true;
        let err = {};

        if(!formData.item) {
            err.item = 'Please select item';
            proceed = false;
        } if(!formData.variant) {
            err.variant = 'Please select variant';
            proceed = false;
        } if(!formData.qnty) {
            err.qnty = 'Please enter quantity';
            proceed = false;
        } if(!formData.askPrice) {
            err.askPrice = 'Please enter price';
            proceed = false;
        }

        setError(err);

        if(proceed) {
            setLoading({...loading, item: true});
            axiosInstance
            .post('/inventory/purchase/po/add-po-items', {
                poid: formPO.poid,
                variant: formData.variant.value,
                prevPrice: formData.prevPrice,
                qnty: formData.qnty,
                askPrice: formData.askPrice,
            }).then(function (res) {
                setLoading({...loading, item: false});
                if (res.data.status === 'success') {
                    setData(res.data.items);
                    setFormData({item: null, variant: null, prevPrice: '', qnty: '', askPrice: ''});
                }
                setAjaxResponse({ status: res.data.status, message: res.data.message });
            }).catch(e => console.log(e));
        }
    };

    const removeItem = (id)=> {
        axiosInstance
        .post('/inventory/purchase/po/delete-po-item', {
            poid: formPO.poid,
            id: id
        }).then(function (res) {
            if (res.data.status === 'success') {
                setData(res.data.items);
            }
        }).catch(e => console.log(e));
    };

    const changeValue = (e, id) => {

        let tempData = [...data];
        tempData = tempData.map(item => 
            item.Temp_Id === id ? { ...item, [e.target.name]: e.target.value } : item
        );
        setData(tempData);

        if(e.target.value) {
            axiosInstance
            .post('/inventory/purchase/po/update-po-items', {
                poid: formPO.poid,
                id: id,
                value: e.target.value,
                field: e.target.name,
            }).then(function (res) {
                if (res.data.status === 'success') {
                    setData(res.data.items);
                } else {
                    setAjaxResponse({ status: res.data.status, message: res.data.message });
                }
            }).catch(e => console.log(e));
        }
    };  
    
    const onVendorSearch = (newValue)=>{
        setFormPO({...formPO, vendor: { ...formPO.vendor, label: newValue }});
        setError({...error, vendor: ''});
    };
    const onVendorSelected = (e, { suggestion }) => {
        setFormPO({...formPO, vendor: suggestion});
        setError({...error, vendor: ''});
    };

    const inputVendorChange = (e) => {
        setFormPO({
            ...formPO,
            vendor: {
                ...formPO.vendor,
                [e.target.name]: e.target.value
            }
        });
    };

    const poInputChange = (e) => {
        setFormPO({...formPO, [e.target.name]: e.target.value});
        setError({...error, [e.target.name]: ''});
    };

    const savePO = (status) => {

        let proceed = true;
        if(!formPO.vendor.value) {
            proceed = false;
            setError({...error, vendor: "Please select vendor"})
        }

        if(data.length <= 0) {
            proceed = false;
            setAjaxResponse({ status: 'error', message: 'Please select PO Items' });
        }

        data.forEach(el => {
            if(!el.PO_Quantity || !el.PO_Rate) {
                if(proceed) {
                    proceed = false;
                    setAjaxResponse({ status: 'error', message: 'Please enter valid quantity and cost price' });
                }
            }
        });

        if(proceed) {
            setLoading({...loading, po: true});
            axiosInstance
            .post('/inventory/purchase/po/update-po', {
                ...formPO, 
                status: status
            }).then(function (res) {
                setLoading({...loading, po: false});
                if (res.data.status === 'success') {
                    history.push('/inventory/purchase');
                }
                setAjaxResponse({ status: res.data.status, message: res.data.message });
            }).catch(e => console.log(e));
        }
    };

    // get activePO Info
    useEffect(() => {
        if(activePO) {
            axiosInstance
            .get(`/inventory/purchase/po/po-info?id=${activePO}`)
            .then(function (res) {
                if (res.data.status === 'success') {
                    setData(res.data.items);
                    setFormPO({
                        poid: res.data.result.PO_Id,
                        vendor: {
                            email: res.data.result.PO_Email ? res.data.result.PO_Email : '',
                            label: res.data.result.vendor.Vendor_Name ? res.data.result.vendor.Vendor_Name : '',
                            phone: res.data.result.PO_Phone ? res.data.result.PO_Phone : '',
                            value: res.data.result.vendor.Vendor_Id,
                        },
                        expData: res.data.result.PO_ExpectedOn ? res.data.result.PO_ExpectedOn : '',
                        message: res.data.result.PO_Remarks ? res.data.result.PO_Remarks : ''
                    });
                }
            }).catch(e => console.log(e));
        } else {
            axiosInstance
            .get(`/inventory/purchase/po/list-po-items?poid=${formPO.poid}`)
            .then(function (res) {
                if (res.data.status === 'success') {
                    setData(res.data.items);
                }
            }).catch(e => console.log(e));
        }
    }, []);

    return (
        <>
            <div className="section-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="col-12 bg-white mb-2 px-0 py-2">
                                <div className="d-flex">
                                    <div className="col border-right pt-2 text-center">
                                        <small className="mb-0">
                                            Total Amount
                                        </small>
                                        <h4 className="font-30 font-weight-bold text-col-blue">
                                            {data.reduce((total, ob)=>{
                                                return total + (parseFloat(ob.PO_Rate ? ob.PO_Rate : 0) * parseFloat(ob.PO_Quantity ? ob.PO_Quantity : 0))
                                            }, 0).toFixed(branchInfo.Branch_DecimalPoints)}
                                        </h4>
                                    </div>
                                    <div className="col text-center pt-2">
                                        <small className="mb-0">
                                            Total Products
                                        </small>
                                        <h4 className="font-30 font-weight-bold text-col-blue">{data.length}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <h6>ADD ITEM</h6>

                                        <AutosuggestItem onSelect={onItemSelected} onSearch={onItemSearch} title="Item Name" wrapper="col-12 form-group pt-3 border-top mb-0" required={true} error={error.item} />

                                        <InputSelectVal wrapper="col-6 col-md" title="Variant" required={true} value={formData.variant} name="variant" onSelect={onSelect} data={variant} error={error.variant} />

                                        <InputText wrapper="col-12 form-group mb-0" title="Quantity" required={true} value={formData.qnty} name="qnty" onChange={inputChange} type="digits" error={error.qnty} />

                                        <InputText wrapper="col-12 form-group mb-0" title="Prev. Price" value={formData.prevPrice} readonly={true} />

                                        <InputText wrapper="col-12 form-group mb-0" title="Ask Price" required={true} value={formData.askPrice} name="askPrice" onChange={inputChange} type="digits" error={error.askPrice} />

                                        <div className="text-center mx-auto">
                                            {loading.item ? <button className="btn c-blue px-4 text-white" type="button" disabled>Please wait...</button> : <button className="btn c-blue px-4 text-white" type="button" onClick={addItem}>ADD ITEM</button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col col-sm-8 input-group d-flex justify-content-start">
                                    {loading.po ? (<span className="input-group-append">
                                        <button type="button" className="btn c-red text-white" disabled>
                                            Please wait...
                                        </button>
                                    </span>) : (<>
                                        <span className="input-group-append">
                                            <button type="button" className="btn c-red text-white" onClick={()=>savePO(1)}>
                                                <i className="fe fe-send pr-2"></i>{" "}
                                                Place Order
                                            </button>
                                        </span>
                                        <span className="input-group-append">
                                            <button type="button" className="btn c-blue text-white" onClick={()=>savePO(0)}>
                                                <i className="fe fe-save pr-2"></i>{" "}
                                                Save Draft
                                            </button>
                                        </span>
                                    </>)}                                    
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body px-1">
                                    <div className="table-responsive ast-table">
                                        <table className="table table-striped table-vcenter mb-0 text-nowrap">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Item</th>
                                                    <th>Prev. Price</th>
                                                    <th>Ask Price</th>
                                                    <th>Qty</th>
                                                    <th>Total</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {data.map((itm, i)=>(
                                                <tr key={itm.Temp_Id}>
                                                    <td>{i + 1}</td>
                                                    <td className="w35">{itm.Variant_Name}</td>
                                                    <td>{parseFloat(itm.PO_PrevCost).toFixed(branchInfo.Branch_DecimalPoints)}</td>
                                                    <td className="inw-s"><input type="number" className="form-control" value={itm.PO_Rate} name="PO_Rate" onChange={(e)=>changeValue(e, itm.Temp_Id)} /></td>
                                                    <td className="inw-s"><input type="number" className="form-control" value={itm.PO_Quantity} name="PO_Quantity" onChange={(e)=>changeValue(e, itm.Temp_Id)} /></td>
                                                    <td>{(parseFloat(itm.PO_Quantity ? itm.PO_Quantity : 0) * parseFloat(itm.PO_Rate ? itm.PO_Rate : 0)).toFixed(branchInfo.Branch_DecimalPoints)}</td>
                                                    <td><span className="c-pointer" onClick={()=>removeItem(itm.Temp_Id)}><i className="fe fe-x-circle varient-button red"></i></span></td>
                                                </tr>
                                            ))}
                                            {data.length == 0 && <tr><td colSpan={7}><p className="text-center pt-3">No Data Found</p></td></tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header pt-3">
                                    <p className="card-title mr-4 font-weight-bold">
                                        <i className="fa fa-long-arrow-right text-red varient-button pr-2"></i>{" "}
                                        VENDOR DETAILS
                                    </p>
                                </div>
                                <div className="card-body pb-0">
                                    <div className="row">
                                        <AutosuggestVendor onSelect={onVendorSelected} onSearch={onVendorSearch} title="Vendor Name" wrapper="col-6 col-lg-4 form-group mb-0" required={true} error={error.vendor} defaultVal={formPO.vendor} />

                                        <InputText wrapper="col-6 col-lg-4 form-group mb-0" title="Email" required={false} value={formPO.vendor.email} name="email" onChange={inputVendorChange} />

                                        <InputText wrapper="col-6 col-lg-4 form-group mb-0" title="Phone" required={false} value={formPO.vendor.phone} name="phone" onChange={inputVendorChange} />

                                        <InputText wrapper="col-6 col-lg-4 form-group mb-0" title="Expected date of completion" required={false} value={formPO.expData} name="expData" onChange={poInputChange} field="date" />

                                        <InputText wrapper="col form-group mb-0" title="Message" required={false} value={formPO.message} name="message" onChange={poInputChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AlertUser {...ajaxResponse} />
        </>
    );
}

export default PO;
