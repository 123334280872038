import React from "react";

function AlertUser(props) {

    const { status, message } = props;
    
    return status ? (<div className="user-alert">
        <div className={`alert rounded-0 ${status === 'success' ? 'alert-success' : 'alert-danger'}`}>{message}</div>
    </div>) : null;
}

export default AlertUser;
