import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { InputText, AlertUser, InputSelect, InputSelectVal, InputCheckBox, Modal } from '../../';
import ChannelPrice from "./ChannelPrice";
import { axiosInstance } from "../../../../config/axios";

import { selectStyle } from "../../../../config/constants";

function StockUpdate() {

    const expiryDates = [
        {value: 0, label: 'Date'},
        {value: 1, label: 'Date After Days'},
        {value: 2, label: 'Date After Weeks'},
        {value: 3, label: 'Date After Month'},
        {value: 4, label: 'Date After Year'},
    ];

    const [loading, setLoading] = useState(false);
    const [ajaxResponse, setAjaxResponse] = useState({});
    useEffect(() => {
        setTimeout(() => {
            setAjaxResponse({});
        }, 5000);
    }, [ajaxResponse]);

    const branchInfo = useSelector((state)=>{
        return state.branch
    });

    const tempChannel = {
        tempId: Math.floor(Math.random() * 90000),
        channelId: {
            value: 0
        },
        chId: '',
        qnty: '',
    };

    const initialVal = {
        itemId: 0,
        title: '',
        hasvariant: false,
        tracking: 3,
        status: true,
        variant: {
            title: '',
            expdtype: 0,
            expcnt: '',
            pricing: [tempChannel]
        },
    };

    const [error, setError] = useState({});
    const [data, setData] = useState(initialVal);
    const [formData, setFormData] = useState({
        category: [],
        channel: [],
        taxclass: [],
        igstclass: [],
        metric: [],
        tracking: [],
        hsncode: [],
        accSales: [],
        accPurchase: [],
    });

    const inputChange = (e)=> {
        setData({...data, [e.target.name]: e.target.value});
        setError({...error, [e.target.name]: ''});
    };
    const inputVariantChange = (e)=> {
        setData({...data, variant: {...data.variant, [e.target.name]: e.target.value}});
        setError({...error, [e.target.name]: ''});
    };
    const inputVariantSelect = (name, val)=> {
        setData({...data, variant: {...data.variant, [name]: val}});
        setError({...error, [name]: ''});
    };
    const selectChange = (name, val) => {
        setData({...data, [name]: val});
        setError({...error, [name]: ''});
    };
    const chkChange = (e) => {
        setData({...data, [e.target.name]: e.target.checked ? 1 : 0});
    };
    
    const [mdlMetric, setMdlMetric] = useState({
        status: false,
        label1: '',
        label2: '',
        conversion: false,
        conversionRate: '',
        secondary: ''
    });
    const setMetricVals = (status) => {

        let cnvdata = {
            status: status,
            label1: '',
            label2: '',
            secondary: ''
        };
        let metric1 = formData.metric.find(e=>e.value == data.metric);
        let metric2 = formData.metric.find(e=>e.value == data.submetric);

        if(!mdlMetric.conversion) {
            cnvdata.label1 = `01 ${metric1 ? metric1.label : ''}`;
            cnvdata.label2 = metric2 ? metric2.label : '';
            cnvdata.secondary = `${cnvdata.label1} = ${mdlMetric.conversionRate} ${cnvdata.label2}`;
        } else {
            cnvdata.label1 = `01 ${metric2 ? metric2.label : ''}`;
            cnvdata.label2 = metric1 ? metric1.label : '';
            cnvdata.secondary = `01 ${cnvdata.label2} = ${mdlMetric.conversionRate} ${metric2 ? metric2.label : ''}`;
        }
        setMdlMetric({...mdlMetric, ...cnvdata});
    }

    const onChnlRowChange =(name, val, index)=>{
        let pricing = data.variant.pricing;
        let newArray = pricing.map((obj, i) =>{ 
            return i === index ? { ...obj, ...{[name]: val, errChannel: '', errPrice: ''} } : obj;
        });
        setData({...data, variant: { ...data.variant, pricing: newArray }});
    };

    const [card, setCard] = useState({ channel: true, composite: true, purchase: true });

    const createChannelRow = (e)=>{
        e.preventDefault();
        if(data.variant.pricing.length < formData.channel.length) {
            tempChannel.tempId = Math.floor(Math.random() * 90000);
            setData({
                ...data,
                variant: {
                    ...data.variant,
                    pricing: [...data.variant.pricing, tempChannel]
                }
            });
        }
    };

    const removeChannelRow = (i)=> {
        if(data.variant.pricing.length > 1) {
            let pricing = data.variant.pricing;
            pricing.splice(i, 1);
            setData({
                ...data,
                variant: {
                    ...data.variant,
                    pricing: pricing
                }
            });
        }
    };

    useEffect(() => {
        axiosInstance.get("/inventory/item-update-form")
        .then(function (res) {
            if (res.data.status === 'success') {
                setFormData(res.data.data);
            }
        }).catch(err => console.log(err));
    }, []);

    useEffect(() => {
        if(data.metric && data.submetric) {
            setMetricVals(true);
        }
    }, [data.metric, data.submetric, mdlMetric.conversion]);

    Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    useEffect(() => {
        let mfgDate = data.variant.mfgdate;
        let mfgType = data.variant.expdtype;
        let expcnt  = data.variant.expcnt;
        if(mfgDate != "" && mfgType != "" && expcnt != "") {
            expcnt = parseInt(expcnt);
            var originalDate = new Date(mfgDate);
            var daysToAdd = 0;
            if(mfgType == 1) {
                daysToAdd = expcnt;
            } else if(mfgType == 2) {
                daysToAdd = expcnt * 7;
            } else if(mfgType == 3) {
                daysToAdd = expcnt * 31;
            } else if(mfgType == 4) {
                daysToAdd = expcnt * 365;
            }

            var finalDate = originalDate.addDays(daysToAdd);
            setData({...data, variant: {...data.variant, expdate: finalDate.toISOString().split('T')[0]}});
        }
    }, [data.variant.mfgdate, data.variant.expdtype, data.variant.expcnt]);
    
    const saveData = ()=>{
        let proceed = true;
        let tempErr = {};
        if(!data.title) {
            tempErr.title = 'Please enter value.';
            proceed = false;
        } if(!data.metric) {
            tempErr.metric = 'Please select.';
            proceed = false;
        } if(data.submetric) {
            if(!mdlMetric.conversionRate) {
                setAjaxResponse({ status:'error', message: "Please enter Secondary Unit Conversion Rate" });
                proceed = false;
            }
        } if(!data.variant.cost) {
            tempErr.cost = 'Please enter value.';
            proceed = false;
        } if(!data.variant.mrp) {
            tempErr.mrp = 'Please enter value.';
            proceed = false;
        }

        let newPricing = [];
        let totalQnty = 0;
        let vrPricing = data.variant.pricing;
        let newArray = vrPricing.map((obj, i) =>{ 
            if(obj.channelId.value == 0) {
                obj.errChannel = 'Please select';
                proceed = false;
            } if(!obj.price) {
                obj.errPrice = 'Please enter value';
                proceed = false;
            }
            if(obj.channelId.isPrimary || obj.channelId.allocation) {
                totalQnty += parseFloat(obj.qnty);
            } else {
                obj.qnty = 0;
            }
            newPricing.push({
                id: obj.id,
                channelId: obj.channelId.value,
                chId: obj.chId,
                taxId: obj.taxId,
                igstId: obj.igstId,
                price: obj.price,
                price2: obj.price2,
                qnty: obj.qnty
            });
            return obj;
        });
        if(!proceed) setData({...data, variant: { ...data.variant, pricing: newArray }});

        // check opening stock
        if(totalQnty > data.variant.qnty) {
            setAjaxResponse({ status:'error', message: "Please check opening stock" });
            proceed = false;
        }
        setError(tempErr);

        if(proceed) {
            setLoading(true);
            let formData = {
                ...data, 
                ...{
                    variant: { 
                        ...data.variant, 
                        pricing: newPricing 
                    },
                    conversion: mdlMetric.conversion,
                    conversionRate: mdlMetric.conversionRate
                }
            };
            axiosInstance.post("/inventory/stock-update", formData)
            .then(function (res) {
                setLoading(false);
                if (res.data.status === 'success') {
                    setData(initialVal);
                }
                setAjaxResponse({ status:res.data.status, message: res.data.message });
            }).catch(err => console.log(err));
        }
    };
    
    useEffect(() => {
        console.log(data);
    }, [data]);    
    
    const formatOptionLabel = ({ label, subtext }) => (<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <div>{label}</div>
        <div style={{ fontSize: '80%', color: 'gray', marginLeft: '10px', marginTop: '2px' }}>{subtext}</div>
    </div>);

    return (<>
        <div className="section-body">
            <div className="container-fluid">
                <div className="d-flex justify-content-start">
                    <span className="input-group-append"><button type="buton" className="btn c-red text-white px-5 font-weight-bold" onClick={saveData}>SAVE</button></span>
                    <span className="input-group-append"><Link to="/inventory/stock/create-stock" className="btn c-blue text-white"><i className="fe fe-plus"></i></Link></span>
                </div>
                <div className="bg-white col-12 border-right pb-2">
                    <div className="card-status c-red"></div>
                    <div className="col-12 pl-3 border-bottom">
                        <h6 className="pt-3">General</h6>
                    </div>
                    <form>
                        <div className="demo-masked-input">
                            <div className="row clearfix m-2 m-md-4">
                                <InputText wrapper="col-6 col-md-3" title="Item Name" required={true} value={data.title} name="title" onChange={inputChange} error={error.title} />
                                <InputText wrapper={branchInfo.View_SName ? "col-6 col-md-3" : "d-none"} title="Secondary Name" required={false} value={data.shortname} name="shortname" onChange={inputChange} />
                                <InputText wrapper="col-6 col-md-3" title="Item Code" required={false} value={data.itmcode} name="itmcode" type="alphanum" onChange={inputChange} error={error.itmcode} />

                                <InputSelect wrapper="col-6 col-md-3" title="Unit" required={true} value={data.metric} name="metric" onSelect={selectChange} data={formData.metric} error={error.metric} />

                                <div className="col-6 col-md-3">
                                    <div className="form-group">
                                        <label className="form-label-small w-100">Secondary Unit {mdlMetric.secondary && (<small className="float-right c-pointer" onClick={()=>setMdlMetric({...mdlMetric, status: true})}>{mdlMetric.secondary}</small>)}</label>
                                        <div className="d-inline">
                                        <Select options={formData.metric} 
                                            styles={selectStyle} 
                                            menuPosition="fixed"
                                            menuPlacement="bottom"
                                            formatOptionLabel={formatOptionLabel}
                                            value={data.submetric !== '' ? formData.metric.find((i) => i.value === data.submetric) : ""}
                                            onChange={(e)=> selectChange('submetric', e.value)}
                                        />
                                        {error.submetric && (<small className="lbl-error">{error.submetric}</small>)}
                                        </div>
                                    </div>
                                </div>

                                <InputSelectVal wrapper="col-6 col-md-3" title="Category" required={false} value={data.catId} name="catId" onSelect={selectChange} data={formData.category} />
                                
                                <InputSelect wrapper="col-6 col-md-3" title="HSN" required={false} value={data.hsncode} name="hsncode" onSelect={selectChange} data={formData.hsncode} />
                                <InputSelect wrapper="col-6 col-md-3" title="Inventory Tracking" required={true} value={data.tracking} name="tracking" onSelect={selectChange} data={formData.tracking} />

                                <InputSelect wrapper={branchInfo.AllowAccounts ? 'col-6 col-md-3' : 'd-none'} title="Sales Account" required={false} value={data.accSales} name="accSales" onSelect={selectChange} data={formData.accSales} />
                                <InputSelect wrapper={branchInfo.AllowAccounts ? 'col-6 col-md-3' : 'd-none'} title="Purchase Account" required={false} value={data.accPurchase} name="accPurchase" onSelect={selectChange} data={formData.accPurchase} />

                                <InputText wrapper="col-6 col-md-3" title="Description" required={false} value={data.description} name="description" onChange={inputChange} />

                                <InputCheckBox wrapper="col-6 col-lg-4 my-2 pt-4" title="Item Variants" checked={data.hasvariant} name="hasvariant" onCheck={chkChange} />
                                <InputCheckBox wrapper="col-6 col-lg-4 my-2 pt-4" title="Refund Allowed" checked={data.refund} name="refund" onCheck={chkChange} />
                                <InputCheckBox wrapper="col-6 col-lg-4 my-2 pt-4" title="Status" checked={data.status} name="status" onCheck={chkChange} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div className="section-body mt-3">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-3 mb-3 mb-md-0">
                        <div className="bg-white h-100 px-3 pb-4">
                            <h6 className="font-weight-bold pt-4 pb-2 mb-0 border-bottom pl-2">VARIANTS</h6>                            
                        </div>
                    </div>
                    <div className="col-xl-9">
                        <div className="card">
                            <div className={data.hasvariant ? "card-header pt-3 pb-2 pl-0 d-flex" : 'd-none'}>
                                <h6 className="pl-3">Variant Name</h6>
                            </div>
                            <div className="card-body px-3 border-top mb-0 pb-0">
                                <form>
                                    <div className="demo-masked-input px-2">
                                        <div className="row clearfix">
                                            <InputText wrapper={data.hasvariant ? "col-6 col-lg-4" : "d-none"} title="Variant Name" required={data.hasvariant ? true : false} value={data.variant.title} name="title" onChange={inputVariantChange} error={error.vrtitle} />
                                            <InputText wrapper={data.hasvariant && branchInfo.View_SName ? "col-6 col-lg-4" : "d-none"} title="Secondary Name" required={false} value={data.variant.shortname} name="shortname" onChange={inputVariantChange} />
                                            <InputText wrapper={data.hasvariant ? "col-6 col-lg-4" : "d-none"} title="SKU" required={false} type="alphanum" value={data.variant.code} name="code" onChange={inputVariantChange} />

                                            <InputText wrapper="col-6 col-lg-4" title="Threshould" required={false} value={data.variant.threshould} name="threshould" onChange={inputVariantChange} />
                                            <InputText wrapper="col-6 col-lg-4" title="Selling Price Denomination" required={false} value={data.variant.denomination} name="denom" onChange={inputVariantChange} />                                            
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card pb-0 mb-0 px-3">
                            <div className="card-header pt-3 pb-2 pl-0 d-flex">
                                <h6 className="pl-3">SALES & PURCHASE</h6>
                            </div>
                            <div className={`card ${!card.purchase && 'card-collapsed'}`}>
                                <div className="card-header mx-0 pt-2 bg-light">
                                    <p className="card-title font-weight-bold"><i className="fa fa-long-arrow-right text-red varient-button pr-2"></i> PURCHASE INFORMATION</p>
                                    <div className="card-options">
                                        <a className="c-pointer card-options-collapse" onClick={()=>setCard({...card, purchase: !card.purchase})}><i className="fe fe-chevron-up"></i></a>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="card-status bg-success"></div>
                                </div>
                                <div className="card-body px-2 my-0 pb-0">
                                    <form>
                                        <div className="demo-masked-input px-2">
                                            <div className="row clearfix">
                                                <InputText wrapper="col-6 col-md-3" title="UPC" subtitle="Barcode" required={false} type="alphanum" value={data.variant.upc} name="upc" onChange={inputVariantChange} />
                                                <InputText wrapper="col-6 col-md-3" title="Cost Price" required={true} type="decimal" value={data.variant.cost} name="cost" onChange={inputVariantChange} error={error.cost} />
                                                <InputText wrapper="col-6 col-md-3" title="MRP" required={true} type="digits" value={data.variant.mrp} name="mrp" onChange={inputVariantChange} error={error.mrp} />                                            
                                                <InputText wrapper="col-6 col-md-3" title="Opening Stock" required={true} type="digits" value={data.variant.qnty} name="qnty" onChange={inputVariantChange} error={error.qnty} />
                                                
                                                { data.tracking == 1 && (<><InputText wrapper="col-6 col-md-3" title="Batch No" required={true} value={data.variant.batch} name="batch" onChange={inputVariantChange} error={error.batch} />
                                                <InputText field="date" wrapper="col-6 col-md-3" title="Mfg. Date" required={true} value={data.variant.mfgdate} name="mfgdate" onChange={inputVariantChange} error={error.mfgdate} />
                                                <InputSelect wrapper="col-6 col-md-3" title="Exp. Date" required={true} value={data.variant.expdtype} name="expdtype" onSelect={inputVariantSelect} data={expiryDates} error={error.expdtype} />
                                                <div className="col-6 col-md-3">
                                                    <label className="form-label-small" data-toggle="tooltip" data-placement="top" title="Choose Date">Choose Period <span className="red">*</span></label>
                                                    <div className="input-group mb-3">
                                                        <div className={`input-group-prepend ${data.variant.expdtype === 0 ? 'd-none' : ''}`}>
                                                            <input type="number" className="form-control px-1" style={{width: '60px'}} name="expcnt" value={data.variant.expcnt} onChange={inputVariantChange} />
                                                        </div>
                                                        <input type="date" className="form-control px-1" value={data.variant.expdate} name="expdate" onChange={inputVariantChange} />
                                                    </div>
                                                </div></>)}

                                                {data.tracking == 2 && <InputText wrapper="col-6 col-md-3" title="Serial No" required={true} value={data.variant.serial} name="serial" onChange={inputVariantChange} error={error.serial} />}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className={`card ${!card.channel && 'card-collapsed'}`}>
                                <div className="card-header mx-0 pt-2 bg-light">
                                    <p className="card-title font-weight-bold"><i className="fa fa-long-arrow-right text-red varient-button pr-2"></i> SALES INFORMATION</p>
                                    <div className="card-options">
                                        <a href="#" onClick={createChannelRow}><i className="fe fe-plus-circle overlay-ico mr-1"></i></a>
                                        <a className="c-pointer card-options-collapse" onClick={()=>setCard({...card, channel: !card.channel})}><i className="fe fe-chevron-up"></i></a>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="card-status bg-success"></div>
                                </div>
                                <div className="card-body px-2 my-0 pb-0">
                                    {data.variant.pricing.map((el, i)=>(
                                        <ChannelPrice key={el.tempId} index={i} channel={formData.channel} taxclass={formData.taxclass} igstclass={formData.igstclass} onChnlRowChange={onChnlRowChange} data={el} removeItem={removeChannelRow} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal title="CONVERSION RATE" itemwidth="modal-sm" isOpen={mdlMetric.status} onClose={()=>setMdlMetric({...mdlMetric, status: false})}>
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-12 mt-2">
                        <label className="form-label-small"></label>
                        <div className="form-group">
                            <label className="form-label-small w-100">
                                <span>{mdlMetric.label1}</span> 
                                <i className="fe fe-repeat float-right cursor" onClick={()=>{
                                    setMdlMetric({...mdlMetric, conversion: (!mdlMetric.conversion)});
                                }}></i>
                            </label>
                            <div className="input-group">
                                <input type="number" className="form-control text-center" value={mdlMetric.conversionRate} onChange={(e)=>setMdlMetric({...mdlMetric, conversionRate: e.target.value})} />
                                <span className="selectgroup-button w-50">{mdlMetric.label2}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-danger font-weight-bold px-4" onClick={()=>setMetricVals(false)}>Save</button>
            </div>
        </Modal>

        <AlertUser {...ajaxResponse} />
    </>);
}

export default StockUpdate;
