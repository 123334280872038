const initialState = { 

};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SET_BRANCH_INFO':
            return action.payload
        default:
            return state
    }
}