import React, { Component } from 'react';
import { connect } from 'react-redux';

class Dashboard extends Component {

    render() {
        const { fixNavbar } = this.props;
        return (
            <div>
                <div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className={`section-body ${fixNavbar ? "mb-4 mt-3" : "mb-4"}`}>
                                    <h4>Welcome Jason Porter!</h4>
                                    <small>
                                        Measure How Fast You’re Growing Monthly Recurring Revenue.{' '}
                                        <a href="fake_url">Learn More</a>
                                    </small>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);