import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, NavLink, Link } from 'react-router-dom';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';
import { axiosInstance } from '../../config/axios'
import Routes from '../Route';
import { toggleLeftMenuAction } from '../../actions/settingsAction';

const masterNone = {
    display: 'none',
};

const masterBlock = {
    display: 'block',
};

class Menu extends Component {

    constructor(props) {
        super(props);
        this.toggleLeftMenu = this.toggleLeftMenu.bind(this);
        this.toggleSubMenu = this.toggleSubMenu.bind(this);
        this.handler = this.handler.bind(this);
        this.state = {
            isToggleLeftMenu: false,
            isBoxLayout: false,
            parentlink: null,
            childlink: null,
            appmenu: []
        };
    }


    setGlobalBranchInfo = data => {
        // Dispatch your action when a button is clicked
        this.props.setGlobalBranch(data);
      };

    componentDidMount() {
        const { location } = this.props;
        const links = location.pathname.substring(1).split(/-(.+)/);
        const parentlink = links[0];
        const nochildlink = links[1];

        if (parentlink && nochildlink && nochildlink === 'dashboard') {
            this.handler(parentlink, `${parentlink}${nochildlink}`);
        } else if (parentlink && nochildlink && nochildlink !== 'dashboard') {
            this.handler(parentlink, nochildlink);
        } else if (parentlink) {
            this.handler(parentlink, '');
        } else {
            this.handler('hr', 'dashboard');
        }

        axiosInstance.get("/starter")
            .then((response) => {
                this.setState({ appmenu: response.data.appmenu });
                this.setGlobalBranchInfo(response.data.branchInfo)
            }).catch((error) => {
                this.setState({ error: error.message });
            });
    }

    componentDidUpdate(prevprops, prevstate) {
        const { location } = this.props;
        const links = location.pathname.substring(1).split(/-(.+)/);
        const parentlink = links[0];
        const nochildlink = links[1];
        if (prevprops.location !== location) {
            if (parentlink && nochildlink && nochildlink === 'dashboard') {
                this.handler(parentlink, `${parentlink}${nochildlink}`);
            } else if (parentlink && nochildlink && nochildlink !== 'dashboard') {
                this.handler(parentlink, nochildlink);
            } else if (parentlink) {
                this.handler(parentlink, '');
            } else {
                this.handler('hr', 'dashboard');
            }
        }
    }

    handler(parentlink, nochildlink) {
        this.setState({
            parentlink: parentlink,
            childlink: nochildlink,
        });
    }

    toggleLeftMenu(e) {
        console.log(e);
        this.props.toggleLeftMenuAction(e)
    }
    toggleSubMenu(e) {
        let menucClass = ''
        if (e.itemId) {
            const subClass = e.items.map((menuItem, i) => {
                if (menuItem.to === this.props.location.pathname) {
                    menucClass = "in";
                } else {
                    menucClass = "collapse";
                }
                return menucClass
            })
            return subClass
            // return "collapse";
        } else {
            return e.visible ? "collapse" : "metismenu";
        }
    }

    render() {

        const activateMenu = (l1, l2) => {
            const updatedMenu = this.state.appmenu.map((item, i) => {
                if (l2 != 0) {
                    const submenu = { ...item, status: false };
                    submenu.content = submenu.content.map((sub, j) => {
                        return j === l2 ? { ...sub, status: true } : { ...sub, status: false }
                    });
                    return submenu;
                } else {
                    return i === l1 ? { ...item, status: true } : { ...item, status: false }
                }
            });
            this.setState({ appmenu: updatedMenu })
        }

        const { istoggleLeftMenu } = this.props
        let pageHead = Routes.filter((route) => route.path === this.props.location.pathname);
        if (pageHead.length <= 0) {
            var parts = this.props.location.pathname.split("/");
            parts.pop();
            var uristring = parts.join("/");
            pageHead = Routes.filter(route => route.path.includes(uristring));
        }
        const pageHeading = pageHead

        return (
            <div className={`${istoggleLeftMenu ? "offcanvas-active" : ""}`}>
                <div style={this.state.parentlink === 'login' ? masterNone : masterBlock}>
                    <div id="header_top" className="header_top dark">
                        <div className="container">
                            <div className="hleft">
                                <NavLink to="/" onClick={() => this.handler('hr', 'dashboard')} className="header-brand">
                                    <img src="/assets/images/icon.png" width="50%" alt="SALTE POS" />
                                </NavLink>
                                <div className="dropdown">
                                    <a href="#" className="nav-link icon">
                                        <i className="icon-grid" />
                                    </a>
                                    <a href="#" className="nav-link icon">
                                        <i className="fe fe-shopping-cart" />
                                    </a>
                                    <a href="#" className="nav-link icon">
                                        <i className="icon-handbag" />
                                    </a>
                                    <a href="#" className="nav-link icon">
                                        <i className="fe fe-truck" />
                                    </a>
                                    <a href="#" className="nav-link icon">
                                        <i className="icon-user" />
                                    </a>
                                </div>
                            </div>
                            <div className="hright">
                                <div className="dropdown">
                                    <a href="#" className="nav-link icon menu_toggle">
                                        <i className="fe fe-power" />
                                    </a>
                                    <p className="nav-link icon menu_toggle" onClick={() => this.toggleLeftMenu(!istoggleLeftMenu)}>
                                        <i className="fa fa-align-left" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="left-sidebar" className="sidebar ">
                        <h5 className="brand-name">SLATE POS</h5>
                        <nav id="left-sidebar-nav" className="sidebar-nav">
                            <ul className="metismenu">
                                {this.state.appmenu.map((item, i) => {
                                    if (item.content.length > 0) {
                                        let subItem = item.content.map((menu, j) => {
                                            if (/^((http|https):\/\/)/.test(menu.to)) {
                                                return <li key={j}><span><a target='_blank' href={menu.to}>{menu.label}</a></span></li>
                                            } else {
                                                return <li key={j}><span><Link onClick={() => activateMenu(i, j)} to={menu.to}>{menu.label}</Link></span></li>
                                            }
                                        });
                                        return <li className={item.status ? 'active' : ''} key={i}>
                                            <a href="#" onClick={() => activateMenu(i, (item.status ? 1 : 0))} aria-current="page" className="list-a"><i className={item.icon}></i><span>{item.label}</span><i className="caret-left"></i></a>
                                            <ul className={`collapse ${item.status ? 'in' : ''}`}>
                                                {subItem}
                                            </ul>
                                        </li>
                                    } else {
                                        if (/^((http|https):\/\/)/.test(item.to)) {
                                            return <li className={item.status ? 'active' : ''} key={i}><a className="has-arrow arrow-c" target="_blank" href={item.to}><i className={item.icon}></i><span>{item.label}</span></a></li>
                                        } else {
                                            return <li className={item.status ? 'active' : ''} key={i}><Link onClick={() => activateMenu(i, 0)} className="has-arrow arrow-c" to={item.to}><i className={item.icon}></i><span>{item.label}</span></Link></li>
                                        }
                                    }
                                })}
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="page">
                    <Header dataFromParent={this.props.dataFromParent} dataFromSubParent={pageHeading[0] !== undefined ? pageHeading[0].pageTitle : 'SLATE POS'} dataSubTitle={pageHeading[0] !== undefined ? pageHeading[0].subTitle : ''} />
                    <Switch>
                        {Routes.map((layout, i) => {
                            return <Route key={i} exact={layout.exact} path={layout.path} component={layout.component}></Route>
                        })}
                    </Switch>
                    <Footer />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    darkMinSidebar: state.settings.isMinSidebar,
    statisticsOpen: state.settings.isStatistics,
    friendListOpen: state.settings.isFriendList,
    statisticsClose: state.settings.isStatisticsClose,
    friendListClose: state.settings.isFriendListClose,
    istoggleLeftMenu: state.settings.isToggleLeftMenu
})

const mapDispatchToProps = dispatch => ({
    toggleLeftMenuAction: (e) => dispatch(toggleLeftMenuAction(e)),
    setGlobalBranch: (data) => dispatch({
        type: 'SET_BRANCH_INFO',
        payload: data
    })
})
export default connect(mapStateToProps, mapDispatchToProps)(Menu);