import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { InputText, InputSelectVal, InputCheckBox, AlertUser } from "../../"
import { axiosInstance } from "../../../../config/axios";

function UserUpdate() {

    const { id } = useParams();
    const history = useHistory();
    const branchInfo = useSelector(store => store.branch);

    const gender = [
        {value: 1, label: 'Male'},
        {value: 2, label: 'Female'},
        {value: 3, label: 'Other'}
    ];

    const kotaccess = [
        {value: 1, label: 'Kitchen'},
        {value: 2, label: 'Food Server'},
    ];

    const otpmethod = [
        {value: 1, label: 'Email'},
        {value: 0, label: 'SMS'},
    ];

    const [formData, setFormData] = useState({
        gender, kotaccess, otpmethod, channel: [], userrole: [], branch: []
    });
    const [data, setData] = useState({ User_Id: 0 });
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [ajaxResponse, setAjaxResponse] = useState({});
    useEffect(() => {
        setTimeout(() => {
            setAjaxResponse({});
        }, 5000);
    }, [ajaxResponse]);

    const inputChange = (e) => {
        setData({...data, [e.target.name]: e.target.value });
        setError({...error, [e.target.name]: null });
    };
    const onSelect = (name, val)=>{
        setData({...data, [name]: val});
        setError({...error, [name]: null });
    };
    const chkChange = (e) => {
        setData({...data, [e.target.name]: e.target.checked ? 1 : 0});
    };
    const PINChange = (e)=>{
        let val = e.target.value;
        if(val.length <= 4) setData({...data, [e.target.name]: e.target.value });
        setError({...error, [e.target.name]: null });
    };

    useEffect(() => {
        axiosInstance.get(`/people/user/user-form?id=${id ? id : 0}`)
        .then(function (res) {
            if (res.data.status === "success") {
                let vrchannel = res.data.data.channel;
                let vruserrole = res.data.data.userrole;
                let vrbranch = res.data.data.branch;
                setFormData({...formData, ...{
                    channel: vrchannel, userrole: vruserrole, branch: vrbranch
                }});
                if(id) {
                    if(res.data.data.userInfo) {
                        let { webaccess, posaccess, channel, kotaccess, User_Gender, Role_Id, User_Verification, ...usrdata } = res.data.data.userInfo;

                        var vrIds = webaccess.map(el => el.Branch_Id);
                        let vrWeb = vrbranch.filter(el => vrIds.includes(el.value));

                        var vrIds = posaccess.map(el => el.Branch_Id);
                        let vrPos = vrbranch.filter(el => vrIds.includes(el.value));
                        
                        var vrIds = channel.map(el => el.Channel_Id);
                        let vrChnl = vrchannel.filter(el => vrIds.includes(el.value));

                        var vrIds = res.data.data.userInfo.kotaccess.map(el => el.KotAccess_Id);
                        let vrKot = formData.kotaccess.filter(el => vrIds.includes(el.value));

                        var vrGender = gender.find(el => el.value == User_Gender);
                        var vrRoleId = vruserrole.find(el => el.value == Role_Id);
                        var vrOTP = [];
                        if(User_Verification == 2) {
                            vrOTP = otpmethod;
                        } else {
                            vrOTP = otpmethod.find(el => el.value == User_Verification);
                        }

                        usrdata = {...usrdata, ...{
                            channel: vrChnl,
                            webaccess: vrWeb,
                            posaccess: vrPos,
                            kotaccess: vrKot,
                            gender: vrGender,
                            role: vrRoleId,
                            otpmethod: vrOTP,
                        }};

                        setData(usrdata);
                    }
                }
            } else {
                history.push('/people/user');
            }
        }).catch((e) => console.log(e));
    }, []);

    const saveData = () => {
        let proceed = true;
        let err = {};

        if(!data.User_Name) {
            err.User_Name = 'Please enter value';
            proceed = false;
        } if(!data.gender) {
            err.gender = 'Please select value';
            proceed = false;
        } if(!data.User_Phone) {
            err.User_Phone = 'Please enter value';
            proceed = false;
        } if(!data.role) {
            err.role = 'Please select value';
            proceed = false;
        } if(!data.User_Username) {
            err.User_Username = 'Please enter value';
            proceed = false;
        } if(!data.User_Password) {
            err.User_Password = 'Please enter value';
            proceed = false;
        } if(!data.User_PIN) {
            err.User_PIN = 'Please enter value';
            proceed = false;
        } if(!data.otpmethod) {
            err.otpmethod = 'Please select value';
            proceed = false;
        } if(branchInfo.Business_Id == 2 && !data.kotaccess) {
            err.kotaccess = 'Please select value';
            proceed = false;
        } if(data.User_Password.length < 6) {
            err.User_Password = 'Password should be Min. 6 character length.';
            proceed = false;
        }
        
        setError(err);
        if(proceed) {
            setLoading(true);
            axiosInstance
            .post(`/people/user/update-data`, data)
            .then(function (res) {
                if (res.data.status === "success") {
                    setTimeout(() => history.push('/people/user'), 500);
                } else {
                    setLoading(false);
                }
                setAjaxResponse({ status: res.data.status, message: res.data.message });
            }).catch((e) => console.log(e));
        }
    }

    return <>
        <div className="section-body content-area">
            <div className="container-fluid">
                <div className="row border-bottom">
                    <div className="col col-sm-8 input-group d-flex justify-content-start">
                        <span className="input-group-append">
                            {loading ? <button type="button" className="btn c-red text-white px-4" disabled> Please wait...</button> : <button type="button" className="btn c-red text-white px-4" onClick={saveData}> UPDATE</button>}
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0"><i className="fa fa-long-arrow-right text-red varient-button pr-2"></i> USER</h6>
                            </div>
                            <div className="card-body pt-3 pb-3">
                                <div className="row">
                                    <InputText wrapper="col-6" title="Name" required={true} value={data.User_Name} name="User_Name" onChange={inputChange} error={error.User_Name} />
                                    <InputSelectVal wrapper="col-6" title="Gender" required={true} value={data.gender} name="gender" onSelect={onSelect} data={formData.gender} error={error.gender} />
                                    <InputText wrapper="col-6" title="PAN Card Number" required={false} value={data.User_PAN} name="User_PAN" onChange={inputChange} />
                                    <InputText wrapper="col-6" title="Mobile Number" required={true} value={data.User_Phone} name="User_Phone" onChange={inputChange} error={error.User_Phone} />
                                    <InputText wrapper="col-6" title="Address" required={false} value={data.User_Address} name="User_Address" onChange={inputChange} />
                                    <InputText wrapper="col-6" title="City" required={false} value={data.User_City} name="User_City" onChange={inputChange} />
                                    <InputText wrapper="col-6" title="State" required={false} value={data.User_State} name="User_State" onChange={inputChange} />
                                    <InputText wrapper="col-6" title="Country" required={false} value={data.User_Country} name="User_Country" onChange={inputChange} />
                                    <InputText wrapper="col-6" title="ZIP Code" required={false} value={data.User_ZIP} name="User_ZIP" onChange={inputChange} />
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0"><i className="fa fa-long-arrow-right text-red varient-button pr-2"></i> DETAILS</h6>
                            </div>
                            <div className="card-body py-4 pb-0">
                                <div className="row">
                                    <InputSelectVal wrapper="col-6" title="User Role" required={true} value={data.role} name="role" onSelect={onSelect} data={formData.userrole} error={error.role} />
                                    <InputSelectVal wrapper="col-6" title="Channels" required={false} value={data.channel} name="channel" onSelect={onSelect} data={formData.channel} multiple={true} />
                                    <InputSelectVal wrapper="col-6" title="Web Access" required={false} value={data.webaccess} name="webaccess" onSelect={onSelect} data={formData.branch} multiple={true} />
                                    <InputSelectVal wrapper="col-6" title="POS Access" required={false} value={data.posaccess} name="posaccess" onSelect={onSelect} data={formData.branch} multiple={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0"><i className="fa fa-long-arrow-right text-red varient-button pr-2"></i>  SETTINGS</h6>
                            </div>
                            <div className="card-body pt-2 pb-4">
                                <div className="row">
                                    <InputCheckBox wrapper="col-xl-6 mt-4" title="Device Activation Access" checked={data.Device_Activation} name="Device_Activation" onCheck={chkChange} />
                                    <InputCheckBox wrapper="col-xl-6 mt-4" title="Daily Email Report" checked={data.Daily_Email_Report} name="Daily_Email_Report" onCheck={chkChange} />
                                    <InputCheckBox wrapper="col-xl-6 mt-4" title="Daily SMS Report" checked={data.Daily_SMS_Report} name="Daily_SMS_Report" onCheck={chkChange} />
                                    <InputCheckBox wrapper="col-xl-6 mt-4" title="Status" checked={data.Is_Active} name="Is_Active" onCheck={chkChange} />
                                </div>
                            </div>
                        </div>

                        <div className={`card ${branchInfo.Business_Id == 2 ? 'd-flex' : 'd-none'}`}>
                            <div className="card-status c-lgt-blue"></div>
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0"><i className="fa fa-long-arrow-right text-red varient-button pr-2"></i> KOT SETTINGS</h6>
                            </div>
                            <div className="card-body pt-4 pb-4">
                                <div className="row">
                                    <InputSelectVal wrapper="col-6" title="User Access" required={true} value={data.kotaccess} name="kotaccess" onSelect={onSelect} data={formData.kotaccess} error={error.kotaccess} multiple={true} />
                                    <InputCheckBox wrapper="col-xl-6 mt-4 pt-2" title="Manage Products" checked={data.KOT_Products} name="KOT_Products" onCheck={chkChange} />
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-status c-lgt-blue"></div>
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0"><i className="fa fa-long-arrow-right text-red varient-button pr-2"></i> USER LOGIN DETAILS</h6>
                            </div>
                            <div className="card-body pt-4 pb-4">
                                <div className="row">
                                    <InputText wrapper="col-6" title="User Name" required={true} value={data.User_Username} name="User_Username" onChange={inputChange} error={error.User_Username} />
                                    <InputText field="password" wrapper="col-6" title="Passward" required={true} value={data.User_Password} name="User_Password" onChange={inputChange} error={error.User_Password} />
                                    <InputText field="number" wrapper="col-6" title="User PIN" required={true} value={data.User_PIN} name="User_PIN" onChange={PINChange} error={error.User_PIN} />

                                    <InputSelectVal wrapper="col-6" title="Verification Method (OTP)" required={true} value={data.otpmethod} name="otpmethod" onSelect={onSelect} data={formData.otpmethod} error={error.otpmethod} multiple={true} />
                                    <InputCheckBox wrapper="col-xl-6 mt-4 pt-2" title="Login Without PIN" checked={data.Allow_WithoutPIN} name="Allow_WithoutPIN" onCheck={chkChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AlertUser {...ajaxResponse} />
    </>;
}

export default UserUpdate;
