import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { axiosInstance } from "../../../../config/axios";

function BranchView() {

    const { id } = useParams();
    const [data, setData] = useState({});

    useEffect(() => {
        axiosInstance.post("/settings/branch-info", {
            id: id
        }).then(function (response) {
            if(response.data.status === 'success')
            setData(response.data.data);
        }).catch(err => console.log(err));
    }, []);

    return (
        <div className="section-body content-area">
            {data.Branch_Id ? 
            (<div className="container-fluid">
                <div className="row border-bottom">
                    <div className="col col-sm-8 input-group d-flex justify-content-start">
                        <span className="input-group-append"><Link to={'/settings/branch-update/' + data.Branch_Id} className="btn c-red text-white px-4"> Edit Branch</Link></span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-status c-red"></div>
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0">Branch Info</h6>
                            </div>
                            <div className="card-body pt-2 pb-0">
                                <div className="table-responsive">
                                    <table className="table table-borderless table-vcenter">
                                        <tbody>
                                            <tr>
                                                <td>Branch Name</td>
                                                <td>{data.Branch_Name}</td>
                                            </tr>
                                            <tr>
                                                <td>Branch Code</td>
                                                <td>{data.Branch_CustomCode}</td>
                                            </tr>
                                            <tr>
                                                <td>Email Id</td>
                                                <td>{data.Branch_Email}</td>
                                            </tr>
                                            <tr>
                                                <td>Phone No</td>
                                                <td>{data.Branch_Phone}</td>
                                            </tr>
                                            <tr>
                                                <td>Address</td>
                                                <td>{data.Branch_Address}</td>
                                            </tr>
                                            <tr>
                                                <td>Location</td>
                                                <td>{data.Branch_Location}</td>
                                            </tr>
                                            <tr>
                                                <td>City</td>
                                                <td>{data.Branch_City}</td>
                                            </tr>
                                            <tr>
                                                <td>State</td>
                                                <td>{data.Branch_State}</td>
                                            </tr>
                                            <tr>
                                                <td>Country</td>
                                                <td>{data.country.Country_Name}</td>
                                            </tr>
                                            <tr>
                                                <td>Zip Code</td>
                                                <td>{data.Branch_ZIP}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-status c-lgt-blue"></div>
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0">Payment Information</h6>
                            </div>
                            <div className="card-body pt-2 pb-0">
                                <div className="table-responsive">
                                    <table className="table table-borderless table-vcenter">
                                        <tbody>
                                            <tr>
                                                <td>Currency</td>
                                                <td>{data.currency.Currency_Title}</td>
                                            </tr>
                                            <tr>
                                                <td>Number Format</td>
                                                <td>{ data.Branch_DecimalPoints == 1 ? '0.0' : (data.Branch_DecimalPoints == 2 ? '0.00' : '0.000') }</td>
                                            </tr>
                                            <tr>
                                                <td>Time Zone</td>
                                                <td>{data.timezone.Timezone_Title}</td>
                                            </tr>
                                            <tr>
                                                <td>Round Off</td>
                                                <td>{data.Branch_RoundOff}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-status c-lgt-blue"></div>
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0">Tax Information</h6>
                            </div>
                            <div className="card-body pt-2 pb-0">
                                <div className="table-responsive">
                                    <table className="table table-borderless table-vcenter">
                                        <tbody>
                                            <tr>
                                                <td>Tax className</td>
                                                <td>{data.Branch_TaxType == 1 ? 'GST' : 'VAT'}</td>
                                            </tr>
                                            <tr>
                                                <td>GST</td>
                                                <td>{data.Branch_GST}</td>
                                            </tr>
                                            <tr>
                                                <td>TIN</td>
                                                <td>{data.Branch_TIN}</td>
                                            </tr>
                                            <tr>
                                                <td>PAN</td>
                                                <td>{data.Branch_PAN}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
            : null }
        </div>
    );
}

export default BranchView;
