import axios from "axios";
import { appurl } from "./constants";

let reqHeader = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
};
if (window.localStorage.getItem("token")) {
    reqHeader = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.localStorage.getItem("token"),
    };
}

export const axiosInstance = axios.create({
    baseURL: appurl,
    headers: reqHeader,
});