import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import InputText from "../../InputText"
import InputSelect from "../../InputSelect"
import InputCheckBox from "../../InputCheckBox"
import BranchAccounts from "./BranchAccounts"
import { axiosInstance } from "../../../../config/axios";
import { selectStyle } from '../../../../config/constants'

function BranchUpdate() {

    const { id } = useParams();
    const defaultVal = {
        taxType: [
            {value: 1, label: 'GST'},
            {value: 0, label: 'VAT'}
        ],
        noFormat: [
            {value: 1, label: '0.0'},
            {value: 2, label: '0.00'},
            {value: 3, label: '0.000'}
        ],
        roundOff: [
            {value: 0, label: 'None'},
            {value: 1, label: 'Without Decimal'},
            {value: 2, label: '+/- 0.5 Next Amount'},
            {value: 3, label: '> 0.5 Next Amount, < 0.5 Prev Amount, = 0.5 Prev Amount'},
            {value: 4, label: '> 0.5 Next Amount, < 0.5 Prev Amount, = 0.5 Next Amount'},
            {value: 5, label: '> 0.5 Next Amount, < 0.5 Prev Amount, = 0.5 Same Amount'},
        ],
        shortCut: [
            {value: 'Ctrl +', label: 'Ctrl +'},
            {value: 'Alt +', label: 'Alt +'},
            {value: 'Shift +', label: 'Shift +'}
        ]
    };
    const defBank = { Bank_Id: 0, Branch_Id: '', Bank_Name: '', Bank_AccNo: '', Bank_AccHolder: '', Bank_IFSC: '', Bank_Swift: ''};

    const [isSubmit, setIsSubmit] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const [form, setForm] = useState({
        ...{business: [], country: [], currency: [], timezone: [], invoiceType: []},
        ...defaultVal
    });
    const [data, setData] = useState({
        Branch_Name: '',
        Branch_Id: '',
        Branch_CustomCode: '',
        Branch_Email: '',
        Branch_Phone: '',
        Branch_Address: '',
        Branch_Location: '',
        Branch_City: '',
        Branch_State: '',
        Country_Id: '',
        Branch_ZIP: '',
        Business_Id: '',
        Default_Invoice: '',
        View_Tax: false,
        View_Charges: false,
        Allow_Register: false,
        Allow_Register: false,
        Is_Hidden: false,
        Branch_Key: '',
        Allow_Duplicate: false,
        Duplicate_PIN: '',
        Duplicate_Key1: '',
        Duplicate_Key2: '',
        Currency_Id: '',
        Branch_DecimalPoints: '',
        Branch_RoundOff: '',
        Timezone_Id: '',
        Branch_TaxType: '',
        Branch_GST: '',
        Branch_TIN: '',
        Branch_PAN: '',
        View_SName: false,
        bank: [defBank]
    });
    const [error, setError] = useState({});

    useEffect(() => {
        axiosInstance.post("/settings/branch-form", {
            id: id
        }).then(function (response) {
            if(response.data.status === 'success') {
                const { branch, ...vrForm } = response.data.data;
                setForm({...vrForm, ...defaultVal});
                if(branch) setData(branch);
            }
        }).catch(e => console.log(e));
    }, []);

    const resetErrorState = (name) => {
        let newObj = { ...error };
        delete newObj[name];
        setError(newObj)
    }

    const inputChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
        resetErrorState(e.target.name);
    };
    const selectChange = (name, val) => {
        setData({...data, [name]: val});
        resetErrorState(name);
    };
    const chkChange = (e) => {
        setData({...data, [e.target.name]: e.target.checked ? 1 : 0});
        resetErrorState(e.target.name);
    };
    const handlePinChange = (e) => {
        // Allow only numeric input
        const inputValue = e.target.value.replace(/\D/g, '');
        // Limit the input to 4 digits
        const truncatedValue = inputValue.slice(0, 4);
        setData({...data, [e.target.name]: truncatedValue});
    };

    const addBankRow = () => {
        setData({...data, bank: [...data.bank, defBank]});
    };

    const bankTextChange = (e, i) => {
        let allAcc = data.bank;
        let newArray = allAcc.map((obj, index) =>{ 
            if(index === i) {
                return { ...obj, [e.target.name]: e.target.value };
            } else {
                return obj;
            }
        });
        setData({...data, bank: newArray});

        let bankErr = validateBank();
        if(bankErr.length > 0) setError({...error, bank: bankErr});
    };

    const removeAccount = (i) => {
        let allAcc = data.bank;
        allAcc.splice(i, 1);
        setData({...data, bank: allAcc});
    };

    const validateBank = () => {
        let allAcc = data.bank;
        let accError = [];
        allAcc.map((obj, i) => {
            let berr = {};
            if(!obj.Bank_Name) berr.Bank_Name = 'Please enter value';
            if(!obj.Bank_AccNo) berr.Bank_AccNo = 'Please enter value';
            if(!obj.Bank_AccHolder) berr.Bank_AccHolder = 'Please enter value';
            if(Object.keys(error).length > 0) {
                berr.index = i;
                accError.push(berr);
            }
        });

        return accError;
    }

    const validateData = () => {

        let error = {};
        if(!data.Branch_Name) error.Branch_Name = 'Please enter value';
        if(!data.Country_Id) error.Country_Id = 'Please select';
        if(!data.Business_Id) error.Business_Id = 'Please select';
        if(data.Default_Invoice === '') error.Default_Invoice = 'Please select';
        if(data.Is_Hidden && !data.Branch_Key) error.Branch_Key = 'Please enter 4 digit PIN';

        if(data.Allow_Duplicate) {
            if(!data.Duplicate_Key1 || !data.Duplicate_Key2) error.Duplicate_Key = 'Please enter value';
            if(!data.Duplicate_PIN) error.Duplicate_PIN = 'Please enter 4 digit PIN';
        }

        if(!data.Currency_Id) error.Currency_Id = 'Please select';
        if(!data.Branch_DecimalPoints) error.Branch_DecimalPoints = 'Please select';
        if(!data.Branch_RoundOff) error.Branch_RoundOff = 'Please select';
        if(!data.Timezone_Id) error.Timezone_Id = 'Please select';
        if(data.Branch_TaxType === '') error.Branch_TaxType = 'Please select';

        let bankErr = validateBank();
        if(bankErr.length > 0) error.bank = bankErr;

        return error;
    }

    const saveData = () => {
        setIsSubmit(true);
        setError(validateData());
    }

    useEffect(() => {
        setIsSubmit(false)
    }, [data]);

    useEffect(() => {
        if (Object.keys(error).length === 0 && isSubmit) {
            setIsLoading(true);
            setIsSubmit(false);
            axiosInstance.post('/settings/branch-update', data)
            .then(function (response) {
                setIsLoading(false);                
                if(response.data.status === 'success') {
                    
                }
                // setUserAlert(response.data);
            }).catch(e=>console.log(e));
        }
    }, [error])

    return (
        <div className="section-body content-area">
            <div className="container-fluid">
                <div className="row border-bottom">
                    <div className="col col-sm-8 input-group d-flex justify-content-start">
                        <span className="input-group-append">
                            {!loading ? (<button type="button" onClick={saveData} className="btn c-red text-white px-4"> UPDATE</button>) : (<button type="button" className="btn c-red text-white px-4" disabled> Please wait...</button>)}                            
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 mb-3">
                        <div className="card h-100">
                            <div className="card-status c-red"></div>
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0"><i className="fa fa-long-arrow-right text-red varient-button pr-2"></i> Branch Information</h6>
                            </div>
                            <div className="card-body pt-3 pb-3">
                                <div className="row">
                                    <InputText wrapper="col-6" title="Branch Name" required={true} value={data.Branch_Name} name="Branch_Name" onChange={inputChange} error={error.Branch_Name} />
                                    <InputText wrapper="col-6" title="Branch Id" required={false} value={data.Branch_CustomCode} name="Branch_CustomCode" onChange={inputChange} />
                                    <InputText wrapper="col-6" title="Email ID" required={false} value={data.Branch_Email} name="Branch_Email" onChange={inputChange} />
                                    <InputText wrapper="col-6" title="Phone No" required={false} value={data.Branch_Phone} name="Branch_Phone" onChange={inputChange} />
                                    <InputText wrapper="col-6" title="Address" required={false} value={data.Branch_Address} name="Branch_Address" onChange={inputChange} />
                                    <InputText wrapper="col-6" title="Location" required={false} value={data.Branch_Location} name="Branch_Location" onChange={inputChange} />
                                    <InputText wrapper="col-6" title="City" required={false} value={data.Branch_City} name="Branch_City" onChange={inputChange} />
                                    <InputText wrapper="col-6" title="State" required={false} value={data.Branch_State} name="Branch_State" onChange={inputChange} />
                                    <InputSelect wrapper="col-6" title="Country" required={true} value={data.Country_Id} name="Country_Id" onSelect={selectChange} data={form.country} error={error.Country_Id} />
                                    <InputText wrapper="col-6" title="ZIP Code" required={false} value={data.Branch_ZIP} name="Branch_ZIP" onChange={inputChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="card">
                            <div className="card-status c-blue"></div>
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0"><i className="fa fa-long-arrow-right text-red varient-button pr-2"></i> Manage Branch</h6>
                            </div>
                            <div className="card-body py-3 pb-0">
                                <div className="row">
                                    <InputSelect wrapper="col-6" title="Business Type" required={true} value={data.Business_Id} name="Business_Id" onSelect={selectChange} data={form.business} error={error.Business_Id} />
                                    <InputSelect wrapper="col-6" title="Default Invoice" required={true} value={data.Default_Invoice} name="Default_Invoice" onSelect={selectChange} data={form.invoiceType} error={error.Default_Invoice} />
                                    <InputCheckBox wrapper="col-12 col-sm-6 pt-2" title="Enable Tax" checked={data.View_Tax} name="View_Tax" onCheck={chkChange} />
                                    <InputCheckBox wrapper="col-12 col-sm-6 pt-2" title="Enable Charges" checked={data.View_Charges} name="View_Charges" onCheck={chkChange} />
                                    <InputCheckBox wrapper="col-12 col-sm-6 pt-4" title="Enable Daily Register" checked={data.Allow_Register} name="Allow_Register" onCheck={chkChange} />
                                    <div className="col-12">
                                        <div className="row">
                                            <InputCheckBox wrapper="col-12 col-sm-6 pt-4" chkwrapper="mb-3" title="Secure Branch Access" checked={data.Is_Hidden} name="Is_Hidden" onCheck={chkChange} />
                                            <InputText wrapper={(data.Is_Hidden ? '' : 'd-none ') + ' col-12 col-sm-6'} title="Branch PIN" required={true} value={data.Branch_Key} name="Branch_Key" onChange={inputChange} error={error.Branch_Key} />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="row">
                                            <InputCheckBox wrapper="col-12 col-sm-4 pt-4" title="WOT Controller" checked={data.Allow_Duplicate} name="Allow_Duplicate" onCheck={chkChange} />

                                            <div className={(data.Allow_Duplicate ? '' : 'd-none') + ' col-12 col-sm-4'}>
                                                <label className="form-label-small">Keyboard Shortcut <span className="red">*</span></label>
                                                <div className="input-group">
                                                    <div className="input-group-append">
                                                        <Select options={form.shortCut} styles={selectStyle} value={data ? (form.shortCut.find(i => i.value === data.Duplicate_Key1)) : ''} onChange={(e) => setData({...data, Duplicate_Key1: e.value})} />
                                                    </div>
                                                    <input type="text" className="form-control" value={data.Duplicate_Key2} name="Duplicate_Key2" onChange={inputChange} />
                                                    {error.Duplicate_Key && (<small className="lbl-error">{error.Duplicate_Key}</small>)}
                                                </div>
                                            </div>
                                            <InputText wrapper={(data.Allow_Duplicate ? '' : 'd-none') + ' col-12 col-sm-4'} title="Verification PIN" required={true} value={data.Duplicate_PIN} name="Duplicate_PIN" onChange={handlePinChange} error={error.Duplicate_PIN} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-status c-blue"></div>
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0"><i className="fa fa-long-arrow-right text-red varient-button pr-2"></i> Payment Information</h6>
                            </div>
                            <div className="card-body py-3 pb-0">
                                <div className="row">
                                    <InputSelect wrapper="col-6" title="Currency" required={true} value={data.Currency_Id} name="Currency_Id" onSelect={selectChange} data={form.currency} error={error.Currency_Id} />
                                    <InputSelect wrapper="col-6" title="Number Format" required={true} value={data.Branch_DecimalPoints} name="Branch_DecimalPoints" onSelect={selectChange} data={form.noFormat} error={error.Branch_DecimalPoints} />
                                    <InputSelect wrapper="col-6" title="Round Off" required={true} value={data.Branch_RoundOff} name="Branch_RoundOff" onSelect={selectChange} data={form.roundOff} error={error.Branch_RoundOff} />
                                    <InputSelect wrapper="col-6" title="Time Zone" required={true} value={data.Timezone_Id} name="Timezone_Id" onSelect={selectChange} data={form.timezone} error={error.Timezone_Id} />
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-status c-blue"></div>
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0"><i className="fa fa-long-arrow-right text-red varient-button pr-2"></i> Tax Information</h6>
                            </div>
                            <div className="card-body py-3 pb-0">
                                <div className="row">
                                    <InputSelect wrapper="col-6" title="Tax Class" required={true} value={data.Branch_TaxType} name="Branch_TaxType" onSelect={selectChange} data={form.taxType} error={error.Branch_TaxType} />
                                    <InputText wrapper="col-6" title="GST Number" required={false} value={data.Branch_GST} name="Branch_GST" onChange={inputChange} />
                                    <InputText wrapper="col-6" title="TIN" required={false} value={data.Branch_TIN} name="Branch_TIN" onChange={inputChange} />
                                    <InputText wrapper="col-6" title="PAN" required={false} value={data.Branch_PAN} name="Branch_PAN" onChange={inputChange} />
                                    <InputCheckBox wrapper={(data.Branch_TaxType == 1 || data.Branch_TaxType === '' ? 'd-none' : '') + ' col-12 col-sm-6'} title="Enable Arabic" checked={data.View_SName} name="View_SName" onCheck={chkChange} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-status c-red"></div>
                            <div className="card-header border-bottom pt-3">
                                <p className="card-title font-weight-bold"><i className="fa fa-long-arrow-right text-red varient-button pr-2"></i> Bank Information</p>
                                <div className="card-options">
                                    <span className="c-pointer" onClick={addBankRow}><i className="fe fe-plus-circle overlay-ico mr-1"></i></span>
                                    <a href="#" className="card-options-collapse" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a>
                                </div>
                            </div>
                            <div className="card-body pt-3 pb-3">
                                {data.bank.map((itm, i)=>(
                                    <BranchAccounts key={i} index={i} data={itm} onRemove={removeAccount} onValueChange={bankTextChange} error={error.bank && error.bank.find((obj) => i == obj.index )} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BranchUpdate;
