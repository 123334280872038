import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { axiosInstance } from "../../../config/axios";
import { DropDown, InputText, AlertUser, InputSelect } from '../';

function Category() {

    const [tab, setTab] = useState(0);
    const [ajaxResponse, setAjaxResponse] = useState({});
    useEffect(() => {
        setTimeout(() => {
            setAjaxResponse([]);
        }, 3000);
    }, [ajaxResponse]);

    const [error, setError] = useState({});
    const [search, setSearch] = useState({ cat: '', sub: '' });
    const [loading, setLoading] = useState({ cat: false, sub: false, mv: false });
    const [totalPages, setTotalPages] = useState({ cat: 0, sub: 0 });
    
    let [pageNoCat, setPageNoCat] = useState(0);
    const [checkedCat, setCheckedCat] = useState([]);
    const [category, setCategory] = useState({ id: 0, title: ''});
    const [allCat, setAllCat] = useState([]);
    const [catPage, setCatPage] = useState('');
    
    let [pageNoSub, setPageNoSub] = useState(0);
    const [checkedSub, setCheckedSub] = useState([]);
    const [subcat, setSubcat] = useState({ id: 0, title: '', catId: ''});
    const [allSub, setAllSub] = useState([]);
    const [subPage, setSubPage] = useState('');
    const [mainCategory, setMainCategory] = useState([]);

    const [movecat, setMovecat] = useState({ mvFrom: '', mvTo: '', mvSub: '' });
    const [activeSubs, setActiveSubs] = useState([]);

    const catChange = (e) => {
        setCategory({...category, title: e.target.value });
        setError({...error, cat: '' });
    }

    const fetchCategory = () => {
        axiosInstance.post("/catalogue/master/list-category", {
            search: search.cat,
            pageNo: pageNoCat
        }).then(function (res) {
            if (res.data.status === 'success') {
                setAllCat(res.data.data.rows);
                setTotalPages({...totalPages, cat: res.data.data.noofpages});
                setCatPage(`${res.data.data.offset}-${res.data.data.limit} of ${res.data.data.count}`)
            }
        }).catch(err => console.log(err));
    };

    const saveCategory = ()=>{

        let proceed = true;
        if(category.title === "") {
            proceed = false;
            setError({...error, cat: 'Please enter text.'});
        }

        if(proceed) {
            setLoading({...loading, cat: true});
            axiosInstance.post("/catalogue/master/update-category", category)
            .then(function (res) {
                setLoading({...loading, cat: false});
                setCategory({ id: 0, title: ''});
                if (res.data.status === 'success') {
                    fetchCategory();
                }
                setAjaxResponse({ status:res.data.status, message: res.data.message });
            }).catch(err => console.log(err));
        }
    };

    const dataCatChecked = (e) => {
        let catId   = e.target.value;
        let newData = checkedCat;
        if(!e.target.checked) newData = newData.filter(el => el !== catId);
        else newData.push(catId);
        setCheckedCat(newData);
    };

    const deleteCategory = () => {
        if(checkedCat.length > 0) {
            Swal.fire({
                title: "Are you sure?",
                text: "You wan't to delete !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "Cancel",
            }).then((result) => {
                if (result.isConfirmed) {
                    axiosInstance.post("/catalogue/master/delete-category", {
                        id: checkedCat
                    }).then(function (res) {
                        if (res.data.status === 'success') {
                            setCheckedCat([]);
                            fetchCategory();
                            setAjaxResponse({ status:res.data.status, message: res.data.message });
                        }
                    }).catch(err => console.log(err));
                }
            });
        }
    };

    const fetchSubcat = () => {
        axiosInstance.post("/catalogue/master/list-subcat", {
            search: search.sub,
            pageNo: pageNoSub
        }).then(function (res) {
            if (res.data.status === 'success') {
                setAllSub(res.data.data.rows);
                setTotalPages({...totalPages, sub: res.data.data.noofpages});
                setSubPage(`${res.data.data.offset}-${res.data.data.limit} of ${res.data.data.count}`)
            }
        }).catch(err => console.log(err));
    };

    const dataSubChecked = (e) => {
        let subId   = e.target.value;
        let newData = checkedSub;
        if(!e.target.checked) newData = newData.filter(el => el !== subId);
        else newData.push(subId);
        setCheckedSub(newData);
    };

    const selectChange = (name, val) => {
        setSubcat({...subcat, [name]: val});
        setError({...error, catId: ''});
    };

    const subChange = (e) => {
        setSubcat({...subcat, title: e.target.value });
        setError({...error, sub: '' });
    }

    const saveSubCategory = ()=>{

        let proceed = true;
        if(subcat.title === "") {
            proceed = false;
            setError({...error, sub: 'Please enter text.'});
        } if(subcat.catId === "") {
            proceed = false;
            setError({...error, catId: 'Please enter text.'});
        }

        if(proceed) {
            setLoading({...loading, sub: true});
            axiosInstance.post("/catalogue/master/update-subcat", subcat)
            .then(function (res) {
                setLoading({...loading, sub: false});
                setSubcat({ id: 0, title: '', catId: ''});
                if (res.data.status === 'success') {
                    fetchSubcat();
                }
                setAjaxResponse({ status:res.data.status, message: res.data.message });
            }).catch(err => console.log(err));
        }
    };

    const deleteSubCategory = () => {
        if(checkedSub.length > 0) {
            Swal.fire({
                title: "Are you sure?",
                text: "You wan't to delete !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "Cancel",
            }).then((result) => {
                if (result.isConfirmed) {
                    axiosInstance.post("/catalogue/master/delete-subcat", {
                        id: checkedSub
                    }).then(function (res) {
                        if (res.data.status === 'success') {
                            setCheckedSub([]);
                            fetchSubcat();
                            setAjaxResponse({ status:res.data.status, message: res.data.message });
                        }
                    }).catch(err => console.log(err));
                }
            });
        }
    };

    const moveCatChange = (name, val)=> {
        setMovecat({...movecat, [name]: val});
        if(name === 'mvFrom') {
            let vrSubs = mainCategory.find(ob=>ob.value == val);
            setActiveSubs(vrSubs.Subcategories)
        }
    };

    const moveSubCat = ()=>{
        let proceed = true;
        let tempErr = {};

        if(movecat.mvFrom == '') {
            tempErr.mvFrom = 'Please choose category';
            proceed = false;
        } if(movecat.mvSub == '') {
            tempErr.mvSub = 'Please choose subcategory';
            proceed = false;
        } if(movecat.mvTo == '') {
            tempErr.mvTo = 'Please choose category';
            proceed = false;
        }

        setError(tempErr);

        if(proceed) {
            setLoading({...loading, mv: true});
            axiosInstance.post("/catalogue/master/move-subcat", movecat)
            .then(function (res) {
                setLoading({...loading, mv: false});
                setMovecat({ mvFrom: '', mvTo: '', mvSub: '' });
                if (res.data.status === 'success') {
                    fetchCategory();
                    fetchSubcat();
                }
                setAjaxResponse({ status:res.data.status, message: res.data.message });
            }).catch(err => console.log(err));
        }
    };

    const catDropdownBtns = [
        {
            icon: 'fe fe-trash-2',
            text: 'Delete',
            callback: deleteCategory
        }
    ];
    const subDropdownBtns = [
        {
            icon: 'fe fe-trash-2',
            text: 'Delete',
            callback: deleteSubCategory
        }
    ];

    useEffect(() => {
        fetchCategory();
    }, [search, pageNoCat]);

    useEffect(() => {
        fetchSubcat();
    }, [search, pageNoSub]);

    useEffect(() => {
        axiosInstance.get("/catalogue/master/list-all-category")
        .then(function (res) {
            if (res.data.status === 'success') {
                setMainCategory(res.data.data);
            }
        }).catch(err => console.log(err));
    }, []);    

    return (<>
        <div className="section-body">
            <div className="container-fluid">
                <div className="d-flex justify-content-between align-items-center horizontal-nav">
                    <ul className="nav nav-tabs page-header-tab">
                        <li className="nav-item"><span className={`nav-link ${tab == 0 ? 'active' : ''}`} onClick={()=>setTab(0)}>Category</span></li>
                        <li className="nav-item"><span className={`nav-link ${tab == 1 ? 'active' : ''}`} onClick={()=>setTab(1)}>Sub Category</span></li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="section-body content-area">
            <div className="container-fluid">
                <div className="tab-content">
                    <div className={`tab-pane fade ${tab == 0 ? 'show active' : ''}`} role="tabpanel">
                        <div className="row">
                            <div className="col-xl-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 border-bottom">
                                                <h6 className="pr-2 pb-1">Category</h6>
                                            </div>
                                            <InputText wrapper="col-12 mt-4" title="Category" required={true} value={category.title} name="title" onChange={catChange} error={error.cat} />
                                            <div className="text-center mx-auto mt-2 mb-2">
                                                {loading.cat ? (<button type="button" className="btn btn-danger px-4" disabled>Please wait...</button>) : (<button type="button" onClick={saveCategory} className="btn btn-danger px-4">UPDATE</button>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row border-bottom">
                                    <div className="col col-sm-8 input-group d-flex justify-content-start">
                                        <div className="col-7 col-md-3 px-0 input-icon table-head-search">
                                            <input type="text" className="form-control" placeholder="Search for..." value={search.cat} onChange={(e)=>setSearch({...search, cat: e.target.value})} />
                                            <span className="input-icon-addon"><i className="fe fe-search"></i></span>
                                        </div>
                                        <DropDown color="text-white c-lgt-blue" icon="fe fe-sliders" items={catDropdownBtns} />
                                    </div>
                                    <div className="col-2 col-sm-4 d-flex justify-content-end mb-1 table-nav">
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            if (pageNoCat > 0) setPageNoCat(--pageNoCat)
                                        }}><i className="fe fe-chevron-left pr-sm-1 pr-2"></i></a>
                                        <span className="px-1 d-none d-sm-block">{catPage}</span>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            if (pageNoCat < totalPages.cat) {
                                                setPageNoCat(++pageNoCat);
                                            } else {
                                            }
                                        }}><i className="fe fe-chevron-right pl-sm-1 pl-2"></i></a>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive ast-table">
                                            <table className="table table-striped table-vcenter mb-0 text-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th><span className="table-checkbox-head">&nbsp;</span> Category Title</th>
                                                        <th className="col-1">Sub Categories</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {allCat.map((itm, i)=>(
                                                    <tr key={itm.Cat_Id}>
                                                        <td className="d-flex">
                                                            <label className="custom-control custom-checkbox mb-0">
                                                                <input type="checkbox" className="custom-control-input" value={itm.Cat_Id} onChange={dataCatChecked} checked={itm.checked} disabled={itm.Subcategories.length > 0 ? true : false} /> <span className="custom-control-label">&nbsp;</span>
                                                            </label>
                                                            <span  className="invo-txt" onClick={()=>{
                                                                if(!loading.cat) setCategory({ id: itm.Cat_Id, title: itm.Cat_Name });
                                                            }}>{itm.Cat_Name}</span>
                                                        </td>
                                                        <td className="text-center">{itm.Subcategories.length}</td>
                                                    </tr>
                                                ))}
                                                {allCat.length <= 0 && (<tr><td className="text-center py-4" colSpan={2}>No Data Found</td></tr>)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`tab-pane fade ${tab == 1 ? 'show active' : ''}`} role="tabpanel">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="card-body" id="frmSubcat">
                                        <div className="row">
                                            <h6>Sub Category</h6>
                                            <InputSelect wrapper="col-12 mt-2" title="Category" required={true} value={subcat.catId} name="catId" onSelect={selectChange} data={mainCategory} error={error.catId} />
                                            <InputText wrapper="col-12 mt-2" title="Sub Category Title" required={true} value={subcat.title} name="title" onChange={subChange} error={error.sub} />
                                            <div className="text-center mx-auto">
                                                {loading.sub ? (<button type="button" className="btn btn-danger px-4" disabled>Please wait...</button>) : (<button type="button" onClick={saveSubCategory} className="btn btn-danger px-4">UPDATE</button>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="card-status c-lgt-blue"></div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <h6 className="pr-2">Move Sub Category</h6>
                                            <InputSelect wrapper="col-12 form-group pt-3 border-top" title="Move sub category from" required={true} value={movecat.mvFrom} name="mvFrom" onSelect={moveCatChange} data={mainCategory} error={error.mvFrom} />
                                            <InputSelect wrapper="col-12 form-group pt-3 border-top" title="Choose Sub Category" required={true} value={movecat.mvSub} name="mvSub" onSelect={moveCatChange} data={activeSubs} error={error.mvSub} />
                                            <InputSelect wrapper="col-12 form-group pt-3 border-top" title="Destination Category" required={true} value={movecat.mvTo} name="mvTo" onSelect={moveCatChange} data={mainCategory} error={error.mvTo} />
                                            <div className="text-center mx-auto">
                                                {loading.mv ? (<button type="button" className="btn btn-danger px-4" disabled>Please wait...</button>) : (<button type="button" onClick={moveSubCat} className="btn btn-danger px-4">MOVE <i className="fa fa-long-arrow-right ml-2"></i></button>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row border-bottom">
                                    <div className="col col-sm-8 input-group d-flex justify-content-start">
                                        <div className="col-7 col-md-3 px-0 input-icon table-head-search">
                                            <input type="text" className="form-control" placeholder="Search for..." value={search.sub} onChange={(e)=>setSearch({...search, sub: e.target.value})} />
                                            <span className="input-icon-addon"><i className="fe fe-search"></i></span>
                                        </div>
                                        <DropDown color="text-white c-lgt-blue" icon="fe fe-sliders" items={subDropdownBtns} />
                                    </div>
                                    <div className="col-2 col-sm-4 d-flex justify-content-end mb-1 table-nav">
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            if (pageNoSub > 0) setPageNoSub(--pageNoSub)
                                        }}><i className="fe fe-chevron-left pr-sm-1 pr-2"></i></a>
                                        <span className="px-1 d-none d-sm-block">{subPage}</span>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            if (pageNoSub < totalPages.sub) setPageNoSub(++pageNoSub);
                                        }}><i className="fe fe-chevron-right pl-sm-1 pl-2"></i></a>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive ast-table">
                                            <table className="table table-striped table-vcenter mb-0 text-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th className="col"><span className="table-checkbox-head">&nbsp;</span> Sub Category</th>
                                                        <th className="col">Category</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {allSub.map((itm, i)=>(
                                                    <tr key={itm.Sub_Id}>
                                                        <td className="d-flex">
                                                            <label className="custom-control custom-checkbox mb-0">
                                                                <input type="checkbox" className="custom-control-input" value={itm.Sub_Id} onChange={dataSubChecked} /> <span className="custom-control-label">&nbsp;</span>
                                                            </label>
                                                            <span  className="invo-txt" onClick={()=>{
                                                                if(!loading.sub) setSubcat({ id: itm.Sub_Id, title: itm.Sub_Name, catId: itm.Category.Cat_Id });
                                                            }}>{itm.Sub_Name}</span>
                                                        </td>
                                                        <td>{itm.Category.Cat_Name}</td>
                                                    </tr>
                                                ))}
                                                {allSub.length <= 0 && (<tr><td className="text-center py-4" colSpan={2}>No Data Found</td></tr>)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AlertUser {...ajaxResponse} />
    </>);
}

export default Category;
