import React from "react";

function TabCredit() {
    return <>
        <div className="row border-bottom">
            <div className="col col-sm-8 input-group d-flex justify-content-start">
                <div className="col-md-3 col-11 px-0 input-icon table-head-search">
                    <input type="text" className="form-control" placeholder="Search for..." />
                    <span className="input-icon-addon"><i className="fe fe-search"></i></span>
                </div>
                <span className="input-group-append"><button className="btn c-red text-white" type="button" data-toggle="modal" data-target="#mdlSort"><i className="fe fe-filter"></i></button></span>
            </div>
            <div className="col-2 col-sm-4 d-flex justify-content-end mb-1 table-nav mt-1">
                <a href="#"><i className="fe fe-chevron-left pr-sm-1 pr-2"></i></a>
                <span className="px-1 d-none d-sm-block"></span>
                <a href="#"><i className="fe fe-chevron-right pl-sm-1 pl-2"></i></a>
            </div>
        </div>
        <div className="card">
            <div className="card-body">
                <div className="table-responsive ast-table">
                    <table className="table table-striped table-vcenter mb-0 text-nowrap">
                        <thead>
                            <tr>
                                <th className="inw-m"><span className="table-checkbox-head">&nbsp;</span> Invoice</th>
                                <th className="inw-m">Date</th>
                                <th>Income</th>
                                <th>Expense</th>
                                <th>Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>;
}

export default TabCredit;
