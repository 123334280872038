import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { axiosInstance } from "../../../../config/axios";
import { selectStyle } from '../../../../config/constants';
import Modal from "../../Modal";
import AuditLog from './AuditLog';
import DiscardLog from './DiscardLog';

function Audit() {
    
    const [pageTab, setPageTab] = useState(0);

    const [dataFilter, setDataFilter] = useState({ search: "", category: [] });
    const [formdata, setFormData] = useState({ category: [], reason: [] });
    
    const [activeData, setActiveData] = useState({});
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({ pageNo: 0, noOfPages: 0, paging: "0-0 of 0" });

    const [mdlFilter, setMdlFilter] = useState(false);
    const [mdlActive, setMdlActive] = useState(false);
    const [mdlComment, setMdlComment] = useState(false);

    const fetchData = () => {
        axiosInstance.post("/inventory/audit/list-stock", {
            ...dataFilter,
            pageNo: pagination.pageNo,
        }).then(function (response) {
            if(response.data.status === 'success') {
                setPagination({...pagination, ...{
                    noOfPages: response.data.data.noofpages,
                    paging: `${response.data.data.offset}-${response.data.data.limit} of ${response.data.data.count}`
                }});
                setData(response.data.data.rows);
            }
        }).catch(function (error) {
            console.log(error);
        });
    };

    useEffect(() => {
        fetchData();
    }, [dataFilter, pagination.pageNo]);

    useEffect(() => {
        axiosInstance
        .get(`/inventory/audit/formdata`)
        .then(function (response) {
            if (response.data.status === "success") {
                setFormData(response.data.data);
            }
        })
        .catch(e => console.error(e.message));
    }, []);

    const [userAlert, setUserAlert] = useState({});
    const [remarks, setRemarks] = useState("");
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const submitData = () => {
        const result = data.filter((obj) => obj.isChanged == true);
        if(remarks == "") {
            setError({...error, remarks: 'Please enter remarks.'});
        } else {
            if(result.length <= 0) {
                setError({...error, remarks: 'Please update stock.'});
            } else {

                setLoading(true);

                let tempData = [];
                result.map(itm => {
                    let pricing = [];
                    itm.pricing.map(row => {
                        if(parseFloat(row.Item_Available) != parseFloat(row.newQnty)) {
                            pricing.push({
                                Channel_Id: row.Channel_Id,
                                Item_Available: row.Item_Available,
                                newQnty: row.newQnty
                            });
                        } 
                    });
                    tempData.push({
                        ItemS_Id: itm.ItemS_Id,
                        ItemS_Available: itm.ItemS_Available,
                        newQnty: itm.newQnty,
                        reason: (itm.reason ? itm.reason.val : null),
                        pricing: pricing
                    })
                });

                axiosInstance.post("/inventory/audit/update-stock", { data: tempData, remarks: remarks })
                .then(res=>{
                    setLoading(false);
                    setUserAlert(res.data);
                    if(res.data.status === 'success') {
                        fetchData();
                        setRemarks("");
                        setMdlComment(false);
                    }
                }).catch(e=>console.log(e));
            }
        }
    }
    
    return (
        <div>
            <div className="section-body">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center horizontal-nav">
                        <ul className="nav nav-tabs page-header-tab">
                            <li className="nav-item" onClick={() => setPageTab(0)}>
                                <a className={ pageTab == 0 ? "nav-link active" : "nav-link" }>Audit</a>
                            </li>
                            <li className="nav-item" onClick={() => setPageTab(1)}>
                                <a className={ pageTab == 1 ? "nav-link active" : "nav-link" }>Audit Log</a>
                            </li>
                            <li className="nav-item" onClick={() => setPageTab(2)}>
                                <a className={ pageTab == 2 ? "nav-link active" : "nav-link" }>Discarded Items</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="section-body content-area">
                <div className="container-fluid">
                    <div className="tab-content">
                        <div className={`tab-pane fade ${pageTab == 0 ? 'show active' : ''}`} role="tabpanel">
                            <div className="row">
                                <div className="col col-sm-8 input-group d-flex justify-content-start">
                                    <div className="col-md-4 col-11 px-0 input-icon table-head-search">
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={(e) =>{
                                                setPagination({...pagination, pageNo:0});
                                                setDataFilter({...dataFilter, search:e.target.value});
                                            }}
                                            value={dataFilter.search}
                                            placeholder="Search..."
                                        />
                                        <span className="input-icon-addon"><i className="fe fe-search"></i></span>
                                    </div>
                                    <span className="input-group-append">
                                        <button type="button" onClick={()=>{setMdlComment(true); setError({remarks: ""});}} className="btn c-blue text-white">UPDATE</button>
                                    </span>
                                    <span className="input-group-append">
                                        <button onClick={() => setMdlFilter(true)} className="btn c-red text-white" type="button"><i className="fe fe-filter"></i></button>
                                    </span>
                                </div>
                                <div className="col-2 col-sm-4 d-flex justify-content-end mb-1 table-nav">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        if (pagination.pageNo > 0) setPagination({...pagination, pageNo:--pagination.pageNo})
                                    }}>
                                        <i className="fe fe-chevron-left pr-sm-1 pr-2"></i>
                                    </a>
                                    <span className="px-1 d-none d-sm-block">
                                        {pagination.paging}
                                    </span>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        if (pagination.pageNo < pagination.noOfPages) setPagination({...pagination, pageNo:++pagination.pageNo})
                                    }}>
                                        <i className="fe fe-chevron-right pl-sm-1 pl-2"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body px-1">
                                    <div className="table-responsive ast-table">
                                        <table className="table table-striped table-vcenter mb-0 text-nowrap">
                                            <thead>
                                                <tr>
                                                    <th>Stock</th>
                                                    <th>UPC</th>
                                                    <th>Batch No</th>
                                                    <th>Mfg. Date</th>
                                                    <th>Exp. Date</th>
                                                    <th className="col-1">Expected Qty</th>
                                                    <th className="col-1">Actual Qty</th>
                                                    <th className="col-3">Reason</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {data.map((item, i)=>{

                                                let changedVal = (item.newQnty && parseFloat(item.newQnty)) + item.pricing.reduce((total, k) => { 
                                                    return total + ( k.newQnty ? parseFloat(k.newQnty) : 0 )
                                                 }, 0);

                                                return <tr key={i}>
                                                    <td>{item.variant.Variant_Name}</td>
                                                    <td>{item.ItemS_UPC}</td>
                                                    <td>{item.ItemS_BatchNo}</td>
                                                    <td>{item.ItemS_MfgDate}</td>
                                                    <td>{item.ItemS_ExpDate}</td>
                                                    <td>{ item.pricing.reduce((total, row)=> { return total + parseFloat(row.Item_Available)}, 0) + parseFloat(item.ItemS_Available) }</td>
                                                    <td>
                                                        <input type="text" className="form-control w-100" value={changedVal > 0 ? changedVal : ''} onClick={()=>{
                                                            setActiveData({
                                                                ...item,
                                                                ...{
                                                                    newQnty: (item.newQnty ? item.newQnty : item.ItemS_Available),
                                                                    pricing: item.pricing.map(obj => ({
                                                                        ...obj,
                                                                        newQnty: (obj.newQnty ? obj.newQnty : obj.Item_Available)
                                                                    }))
                                                                }
                                                            });
                                                            setMdlActive(true);
                                                        }} readOnly />
                                                    </td>
                                                    <td>
                                                    <Select options={formdata.reason} styles={selectStyle} onChange={(val)=>{
                                                        let tempData = data;
                                                        let index = tempData.findIndex(obj => obj.ItemS_Id === item.ItemS_Id);
                                                        if (index !== -1) {
                                                            tempData.splice(index, 1, {...item, reason: val});
                                                            setData(tempData);
                                                        }
                                                    }} />
                                                    </td>
                                                </tr>
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane fade ${pageTab == 1 ? 'show active' : ''}`} role="tabpanel">
                            <AuditLog />
                        </div>
                        <div className={`tab-pane fade ${pageTab == 2 ? 'show active' : ''}`} role="tabpanel">
                            <DiscardLog formdata={formdata} />
                        </div>
                    </div>
                </div>
            </div>

            <Modal title="FILTER" itemwidth="modal-sm" isOpen={mdlFilter} onClose={()=>setMdlFilter(false)}>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-12">
                            <label className="form-label-small w-100">Category</label>
                            <div className="form-group">
                                <Select options={formdata.category} styles={selectStyle} value={dataFilter.category} onChange={(val)=>{
                                    setPagination({...pagination, pageNo:0});
                                    setDataFilter({...dataFilter, category: val});
                                }} isMulti />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={()=>{
                        fetchData();
                        setMdlFilter(false);
                    }}>Search</button>
                </div>
            </Modal>

            <Modal title={activeData ?  activeData.variant ? activeData.variant.Variant_Name : '' : ''} itemwidth="modal-md" isOpen={mdlActive} onClose={()=>setMdlActive(false)}>
                <div className="modal-body">
                    <div className="row clearfix">
                        <div className="col-7 py-2">Stock Expected Qty</div>
                        <div className="col-5 py-2 pl-3">{activeData ? activeData.ItemS_Available : ''}</div>
                        <div className="col-7 py-2">Stock Actual Qty</div>
                        <div className="col-5 py-2">
                            <input type="text" className="form-control" value={activeData ? activeData.newQnty : ''} onChange={(e)=>setActiveData({...activeData, newQnty: (e.target.value != '' ? e.target.value : 0)})} />
                        </div>
                        {activeData ? activeData.pricing ? activeData.pricing.map((row, i)=>(
                        <div key={i} className="col-12">
                            <div className="row">
                                <div className="col-7 py-2">{row.channel.Channel_Name} Expected Qty</div>
                                <div className="col-5 py-2 pl-3">{parseFloat(row.Item_Available).toFixed(2)}</div>
                                <div className="col-7 py-2">{row.channel.Channel_Name} Actual Qty</div>
                                <div className="col-5 py-2">
                                    <input type="text" className="form-control" value={row.newQnty} onChange={(e)=>{
                                        let pricing = activeData.pricing.map((obj, j) => {
                                            return j === i ? { ...obj, newQnty: (e.target.value != '' ? e.target.value : 0) } : obj;
                                        });
                                        setActiveData({ ...activeData, pricing: pricing });
                                    }} />
                                </div>
                            </div>
                        </div>
                        )) : '' : ''}
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={()=>{
                        // update main data if any stock qnty changed
                        let isChanged = false;
                        if(parseFloat(activeData.ItemS_Available) != parseFloat(activeData.newQnty)) {
                            isChanged = true;
                        }
                        activeData.pricing.map((obj, j) => {
                            if(parseFloat(obj.Item_Available) != parseFloat(obj.newQnty)) {
                                isChanged = true;
                            }
                        });

                        if(isChanged) {
                            let tempData = data;
                            let index = tempData.findIndex(obj => obj.ItemS_Id === activeData.ItemS_Id);
                            if (index !== -1) {
                                tempData.splice(index, 1, {...activeData, isChanged: true});
                                setData(tempData);
                            }
                        }
                        setMdlActive(false);
                    }}>Save</button>
                </div>
            </Modal>

            <Modal title="COMMENTS" itemwidth="modal-sm" isOpen={mdlComment} onClose={()=>setMdlComment(false)}>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <textarea className="form-control" rows="5" value={remarks} onChange={(e)=>{
                                    setRemarks(e.target.value);
                                    setError({remarks: ""});
                                }}></textarea>
                                {error.remarks != "" && <small class="red mb-2">{error.remarks}</small> }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    { loading ? <button type="button" className="btn btn-primary" disabled>Please wait...</button> : <button type="button" className="btn btn-primary" onClick={()=>{
                        submitData();
                    }}>UPDATE</button>}
                </div>
            </Modal>

            {userAlert.status && <div className="user-alert"><div className={`alert rounded-0 ${userAlert.status === 'success' ? 'alert-success' : 'alert-danger'}`}>{userAlert.message}</div></div>}
        </div>
    );
}

const mapStateToProps = (state) => ({
    fixNavbar: state.settings.isFixNavbar,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Audit);
