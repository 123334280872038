import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import TabGeneral from './TabGeneral';
import TabInvoices from './TabInvoices';
import TabCredit from './TabCredit';
import { axiosInstance } from "../../../../config/axios";

function CustomerInfo() {

    const { id } = useParams();
    const history = useHistory();

    const [tab, setTab] = useState(1);
    const [data, setData] = useState({});

    const updateData = (temp) => setData(temp);

    useEffect(() => {
        axiosInstance
        .get(`/people/customer/customer-details?id=${id}`)
        .then(function (res) {
            if (res.data.status === "success") setData({...res.data.data, tempLimit: res.data.data.Cust_CreditLimit});
            else history.push('/people/customer');
        }).catch((e) => console.log(e));
    }, []);

    return <>
        <div className="section-body">
            <div className="container-fluid">
                <div className="d-flex justify-content-between align-items-center horizontal-nav">
                    <ul className="nav nav-tabs page-header-tab">
                        <li className="nav-item"><a onClick={()=>setTab(1)} className={`nav-link ${tab == 1 ? 'active' : ''}`}>Basic Info</a></li>
                        <li className="nav-item"><a onClick={()=>setTab(2)} className={`nav-link ${tab == 2 ? 'active' : ''}`}>Invoices</a></li>
                        <li className="nav-item"><a onClick={()=>setTab(3)} className={`nav-link ${tab == 3 ? 'active' : ''}`}>Credit Payments</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div className="section-body content-area">
            <div className="container-fluid">
                <div className="tab-content">
                    <div className={`tab-pane fade ${tab == 1 ? 'show active' : ''}`} role="tabpanel">
                        {data.Cust_Id && <TabGeneral data={data} updateData={updateData} />}
                    </div>
                    <div className={`tab-pane fade ${tab == 2 ? 'show active' : ''}`} role="tabpanel">
                        <TabInvoices />
                    </div>
                    <div className={`tab-pane fade ${tab == 3 ? 'show active' : ''}`} role="tabpanel">
                        <TabCredit />
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default CustomerInfo;
