import React from "react";

function InputCheckBox(props) {
    return (
        <div className={props.wrapper}>
            <div className={`custom-switches-stacked ${props.chkwrapper ? props.chkwrapper : ''}`}>
                <label className="custom-switch d-flex justify-content-between">
                    <span className="custom-switch-description ml-0">{props.title}</span>
                    <span className="mr-2">
                        <input type="checkbox" className="custom-switch-input" value={props.value} checked={props.checked} name={props.name} onChange={props.onCheck} />
                        <span className="custom-switch-indicator"></span>
                    </span>
                </label>
            </div>
        </div>
    );
}

export default InputCheckBox;
