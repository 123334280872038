import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./App.css";
import Layout from "./components/Shared/Layout";
import Login from "./components/Authentication/login";
import NotFound from "./components/Authentication/404";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { axiosInstance } from "./config/axios";
import { client } from "./config/constants";

function App(props) {

    useEffect(() => {

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = {};
        for (const [key, value] of urlSearchParams) {
            params[key] = value;
        }
        if(params.token) {
            axiosInstance
            .post("/token-login", {token: params.token, client: client})
            .then(function (response) {
                if (response.data.status === "success") {
                    window.localStorage.setItem("token", response.data.token);
                }
            }).catch(function (error) {
                if(!window.localStorage.getItem("token") && window.location.href != (window.location.protocol + "//" + window.location.host + '/login')) {
                    window.location.href = window.location.protocol + "//" + window.location.host + '/login';
                }
            });
        }

        if(!params.token && !window.localStorage.getItem("token") && window.location.href != (window.location.protocol + "//" + window.location.host + '/login')) {
            window.location.href = window.location.protocol + "//" + window.location.host + '/login';
        }
    }, [])
    
    const { darkMode, boxLayout, darkSidebar, iconColor, gradientColor, rtl, fontType } = props
    return (
        <div className={`${darkMode ? "dark-mode" : ""}${darkSidebar ? "sidebar_dark" : ""
            } ${iconColor ? "iconcolor" : ""} ${gradientColor ? "gradient" : ""
            } ${rtl ? "rtl" : ""} ${fontType ? fontType : ""}${boxLayout ? "boxlayout" : ""
            }`}>
            <Router>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/notfound" component={NotFound} />
                    <Route component={Layout} />
                </Switch>
            </Router>
        </div>
    );
}

const mapStateToProps = state => ({
    darkMode: state.settings.isDarkMode,
    darkSidebar: state.settings.isDarkSidebar,
    iconColor: state.settings.isIconColor,
    gradientColor: state.settings.isGradientColor,
    rtl: state.settings.isRtl,
    fontType: state.settings.isFont,
    boxLayout: state.settings.isBoxLayout
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(App)
