import React, { useState, useEffect } from "react";
import { DropDown, InputText, AlertUser } from '../';
import Swal from 'sweetalert2';
import { axiosInstance } from '../../../config/axios';

function Hsncode() {

    const [search, setSearch] = useState('');
    const [data, setData] = useState([]);
    const [checked, setChecked] = useState([]);
    const [ajaxResponse, setAjaxResponse] = useState({});
    useEffect(() => {
        setTimeout(() => {
            setAjaxResponse([]);
        }, 3000);
    }, [ajaxResponse])
    
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ id: 0, title: '' });
    const [error, setError] = useState('');

    const inputChange = (e) => {
        setError('');
        setFormData({...formData, title: e.target.value });
    };

    const dataChecked = (e) => {
        let hsnId   = e.target.value;
        let newData = checked;
        if(!e.target.checked) newData = newData.filter(el => el !== hsnId);
        else newData.push(hsnId);
        setChecked(newData);
    };

    const saveData = () => {
        let proceed = true;
        if(formData.title === '') {
            proceed = false;
            setError('Please enter value');
        }

        if(proceed) {
            setLoading(true);
            axiosInstance.post("/catalogue/master/update-hsn", formData)
            .then(function (res) {
                setLoading(false);
                if (res.data.status === 'success') {
                    setData(res.data.data);
                    setFormData({ id: 0, title: '' });
                }
            }).catch(err => console.log(err));
        }
    };

    const fetchData = ()=>{
        axiosInstance.post("/catalogue/master/list-hsn", {
            search: search
        }).then(function (res) {
            if (res.data.status === 'success') {
                setData(res.data.data);
            }
        }).catch(err => console.log(err));
    };

    const deleteItems = () => {
        if(checked.length > 0) {
            Swal.fire({
                title: "Are you sure?",
                text: "You wan't to delete !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "Cancel",
            }).then((result) => {
                if (result.isConfirmed) {
                    axiosInstance.post("/catalogue/master/delete-hsn", {
                        id: checked
                    }).then(function (res) {
                        if (res.data.status === 'success') {
                            setData(res.data.data);
                            setAjaxResponse({ status:res.data.status, message: res.data.message });
                            setChecked([]);
                        }
                    }).catch(err => console.log(err));
                }
            });
        }
    };

    const dropdownBtns = [
        {
            icon: 'fe fe-trash-2',
            text: 'Delete',
            callback: deleteItems
        }
    ];
    
    useEffect(() => {
        fetchData();
    }, [search]);
    useEffect(() => {
        setChecked([]);
    }, [data]);

    return (
        <div className="section-body content-area">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 border-bottom">
                                        <h6 className="pr-2 pb-1">HSN</h6>
                                    </div>
                                    <InputText wrapper="col-12 mt-4" title="HSN" required={true} value={formData.title} name="title" onChange={inputChange} error={error} />
                                    <div className="text-center mx-auto mt-2 mb-2">
                                        {loading ? (<button type="button" className="btn btn-danger px-4" disabled>Updating...</button>) : (<button type="button" onClick={saveData} className="btn btn-danger px-4">UPDATE</button>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row border-bottom">
                            <div className="col col-sm-8 input-group d-flex justify-content-start">
                                <div className="col-7 col-md-3 px-0 input-icon table-head-search">
                                    <input type="text" className="form-control" placeholder="Search for..." onChange={(e)=>setSearch(e.target.value)} value={search} />
                                    <span className="input-icon-addon"><i className="fe fe-search"></i></span>
                                </div>
                                <DropDown color="text-white c-lgt-blue" icon="fe fe-sliders" items={dropdownBtns} />
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive ast-table">
                                    <table className="table table-striped table-vcenter mb-0 text-nowrap">
                                        <thead>
                                            <tr>
                                                <th><span className="table-checkbox-head">&nbsp;</span>HSN</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {data.map((itm, i)=>(
                                            <tr key={i}>
                                                <td className="d-flex">
                                                    <label className="custom-control custom-checkbox mb-0">
                                                        <input type="checkbox" className="custom-control-input" value={itm.HSN_Id} onChange={dataChecked} />
                                                        <span className="custom-control-label">&nbsp;</span>
                                                    </label>
                                                    <span onClick={()=>{
                                                        if(!loading) setFormData({id: itm.HSN_Id, title: itm.HSN_Code});
                                                    }} className="invo-txt">{itm.HSN_Code}</span>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AlertUser {...ajaxResponse} />
        </div>
    );
}

export default Hsncode;
