import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { DropDown, InputText, AlertUser, InputSelect, InputSelectVal, InputCheckBox } from '../../';
import ChannelPrice from "./ChannelPrice";
import { axiosInstance } from "../../../../config/axios";

function ItemView() {

    const [loading, setLoading] = useState(false);
    const [ajaxResponse, setAjaxResponse] = useState({});
    useEffect(() => {
        setTimeout(() => {
            setAjaxResponse({});
        }, 3000);
    }, [ajaxResponse]);

    const branchInfo = useSelector((state)=>{
        return state.branch
    });

    const tempChannel = {
        tempId: Math.floor(Math.random() * 90000),
        channelId: {
            value: 0
        },
        chId: ''
    };

    /* const initialVal = {
        "itemId": 0,
        "title": "Mixed Juice",
        "hasvariant": 1,
        "status": true,
        "variant": {
            "title": "Mixed Juice 1",
            pricing: [tempChannel],
            "shortname": "Apple",
            "cost": "35",
            "mrp": "40",
            "denom": "321",
            "upc": "dasdsa"
        },
        "shortname": "Apple, Orange",
        "metric": 3,
        "hsncode": 1,
        "accSales": 10,
        "refund": 1,
        "itmcode": "123",
        "description": "dsad"
    }; */

    const initialVal = {
        itemId: 0,
        title: '',
        hasvariant: false,
        status: true,
        variant: {
            title: '',
            pricing: [tempChannel]
        },
    };

    const [error, setError] = useState({});
    const [data, setData] = useState(initialVal);
    const [formData, setFormData] = useState({
        category: [],
        channel: [],
        taxclass: [],
        igstclass: [],
        metric: [],
        hsncode: [],
        accSales: [],
    });

    const inputChange = (e)=> {
        setData({...data, [e.target.name]: e.target.value});
        setError({...error, [e.target.name]: ''});
    };
    const inputVariantChange = (e)=> {
        setData({...data, variant: {...data.variant, [e.target.name]: e.target.value}});
        setError({...error, [e.target.name]: ''});
    };
    const selectChange = (name, val) => {
        setData({...data, [name]: val});
        setError({...error, [name]: ''});
    };
    const chkChange = (e) => {
        setData({...data, [e.target.name]: e.target.checked ? 1 : 0});
    };

    const onChnlRowChange =(name, val, index)=>{
        let pricing = data.variant.pricing;
        let newArray = pricing.map((obj, i) =>{ 
            return i === index ? { ...obj, ...{[name]: val, errChannel: '', errPrice: ''} } : obj;
        });
        setData({...data, variant: { ...data.variant, pricing: newArray }});
    };

    const [card, setCard] = useState({ channel: true, composite: true});

    const createChannelRow = (e)=>{
        e.preventDefault();
        if(data.variant.pricing.length < formData.channel.length) {
            tempChannel.tempId = Math.floor(Math.random() * 90000);
            setData({
                ...data,
                variant: {
                    ...data.variant,
                    pricing: [...data.variant.pricing, tempChannel]
                }
            });
        }
    };

    const removeChannelRow = (i)=> {
        if(data.variant.pricing.length > 1) {
            let pricing = data.variant.pricing;
            pricing.splice(i, 1);
            setData({
                ...data,
                variant: {
                    ...data.variant,
                    pricing: pricing
                }
            });
        }
    };

    useEffect(() => {
        axiosInstance.get("/catalogue/item-update-form")
        .then(function (res) {
            if (res.data.status === 'success') {
                setFormData(res.data.data);
            }
        }).catch(err => console.log(err));
    }, []);
    
    const saveData = ()=>{
        let proceed = true;
        let tempErr = {};
        if(!data.title) {
            tempErr.title = 'Please enter value.';
            proceed = false;
        } if(!data.metric) {
            tempErr.metric = 'Please select.';
            proceed = false;
        } if(!data.variant.cost) {
            tempErr.cost = 'Please enter value.';
            proceed = false;
        } if(!data.variant.mrp) {
            tempErr.mrp = 'Please enter value.';
            proceed = false;
        }

        let newPricing = [];
        let vrPricing = data.variant.pricing;
        let newArray = vrPricing.map((obj, i) =>{ 
            if(obj.channelId.value == 0) {
                obj.errChannel = 'Please select';
                proceed = false;
            } if(!obj.price) {
                obj.errPrice = 'Please enter value';
                proceed = false;
            }
            newPricing.push({
                id: obj.id,
                channelId: obj.channelId.value,
                chId: obj.chId,
                taxId: obj.taxId,
                igstId: obj.igstId,
                price: obj.price,
                price2: obj.price2,
            });
            return obj;
        });
        if(!proceed) setData({...data, variant: { ...data.variant, pricing: newArray }});

        setError(tempErr);

        if(proceed) {
            setLoading(true);
            let formData = {...data, variant: { ...data.variant, pricing: newPricing }};
            axiosInstance.post("/catalogue/item-update", formData)
            .then(function (res) {
                setLoading(false);
                if (res.data.status === 'success') {
                    setData(initialVal);
                }
                setAjaxResponse({ status:res.data.status, message: res.data.message });
            }).catch(err => console.log(err));
        }
    };    

    return (<>
        <div className="section-body">
            <div className="container-fluid">
                <div className="d-flex justify-content-start">
                    <span className="input-group-append"><button type="buton" className="btn c-red text-white px-5 font-weight-bold" onClick={saveData}>SAVE</button></span>
                    <span className="input-group-append"><Link to="/catalogue/item/create-item" className="btn c-blue text-white"><i className="fe fe-plus"></i></Link></span>
                </div>
                <div className="bg-white col-12 border-right pb-2">
                    <div className="card-status c-red"></div>
                    <div className="col-12 pl-3 border-bottom">
                        <h6 className="pt-3">General</h6>
                    </div>
                    <form>
                        <div className="demo-masked-input">
                            <div className="row clearfix m-2 m-md-4">
                                <InputText wrapper="col-6 col-lg-4" title="Item Name" required={true} value={data.title} name="title" onChange={inputChange} error={error.title} />
                                <InputText wrapper={branchInfo.View_SName ? "col-6 col-lg-4" : "d-none"} title="Secondary Name" required={false} value={data.shortname} name="shortname" onChange={inputChange} />
                                <InputText wrapper="col-6 col-lg-4" title="Item Code" required={false} value={data.itmcode} name="itmcode" type="alphanum" onChange={inputChange} error={error.itmcode} />
                                <InputSelectVal wrapper="col-6 col-lg-4" title="Category" required={false} value={data.catId} name="catId" onSelect={selectChange} data={formData.category} />

                                <InputSelect wrapper="col-6 col-lg-4" title="Unit" required={true} value={data.metric} name="metric" onSelect={selectChange} data={formData.metric} error={error.metric} />
                                <InputSelect wrapper="col-6 col-lg-4" title="HSN" required={false} value={data.hsncode} name="hsncode" onSelect={selectChange} data={formData.hsncode} />
                                <InputSelect wrapper={branchInfo.AllowAccounts ? 'col-6 col-lg-4' : 'd-none'} title="Sales Account" required={false} value={data.accSales} name="accSales" onSelect={selectChange} data={formData.accSales} />
                                <InputText wrapper="col-6 col-lg-4" title="Description" required={false} value={data.description} name="description" onChange={inputChange} />

                                <InputCheckBox wrapper="col-6 col-lg-4 my-2 pt-4" title="Item Variants" checked={data.hasvariant} name="hasvariant" onCheck={chkChange} />
                                <InputCheckBox wrapper="col-6 col-lg-4 my-2 pt-4" title="Refund Allowed" checked={data.refund} name="refund" onCheck={chkChange} />
                                <InputCheckBox wrapper="col-6 col-lg-4 my-2" title="Status" checked={data.status} name="status" onCheck={chkChange} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div className="section-body mt-3">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-3 mb-3 mb-md-0">
                        <div className="bg-white h-100 px-3 pb-4">
                            <h6 className="font-weight-bold pt-4 pb-2 mb-0 border-bottom pl-2">VARIANTS</h6>                            
                        </div>
                    </div>
                    <div className="col-xl-9" id="frmVariant">
                        <div className="bg-white px-3 variant-edit-wrap">
                            <div className="card">
                                <div className={data.hasvariant ? "card-header pt-3 pb-2 pl-0" : 'd-none'}>
                                    <h6 className="pl-2">Variant Name</h6>
                                </div>
                                <div className="card-body px-1">
                                    <form>
                                        <div className="demo-masked-input">
                                            <div className="row clearfix">
                                                <InputText wrapper={data.hasvariant ? "col-6 col-lg-4" : "d-none"} title="Variant Name" required={data.hasvariant ? true : false} value={data.variant.title} name="title" onChange={inputVariantChange} error={error.vrtitle} />
                                                <InputText wrapper={data.hasvariant && branchInfo.View_SName ? "col-6 col-lg-4" : "d-none"} title="Secondary Name" required={false} value={data.variant.shortname} name="shortname" onChange={inputVariantChange} />
                                                <InputText wrapper={data.hasvariant ? "col-6 col-lg-4" : "d-none"} title="Variant Code" required={false} type="alphanum" value={data.variant.code} name="code" onChange={inputVariantChange} />

                                                <InputText wrapper="col-6 col-lg-4" title="UPC" subtitle="Barcode" required={false} type="alphanum" value={data.variant.upc} name="upc" onChange={inputVariantChange} />
                                                <InputText wrapper="col-6 col-lg-4" title="Cost Price" required={true} type="decimal" value={data.variant.cost} name="cost" onChange={inputVariantChange} error={error.cost} />
                                                <InputText wrapper="col-6 col-lg-4" title="MRP" required={true} type="digits" value={data.variant.mrp} name="mrp" onChange={inputVariantChange} error={error.mrp} />
                                                <InputText wrapper="col-6 col-lg-4" title="Selling Price Denomination" required={false} value={data.variant.denomination} name="denom" onChange={inputVariantChange} />
                                            </div>
                                        </div>

                                        <div className={`card ${!card.channel && 'card-collapsed'}`}>
                                            <div className="card-header mx-0 pt-2 bg-light">
                                                <p className="card-title font-weight-bold"><i className="fa fa-long-arrow-right text-red varient-button pr-2"></i> Channel Pricing</p>
                                                <div className="card-options">
                                                    <a href="#" onClick={createChannelRow}><i className="fe fe-plus-circle overlay-ico mr-1"></i></a>
                                                    <a className="c-pointer card-options-collapse" onClick={()=>setCard({...card, channel: !card.channel})}><i className="fe fe-chevron-up"></i></a>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="card-status bg-success"></div>
                                            </div>
                                            <div className="card-body px-2 my-0 pb-0">
                                                {data.variant.pricing.map((el, i)=>(
                                                    <ChannelPrice key={el.tempId} index={i} channel={formData.channel} taxclass={formData.taxclass} igstclass={formData.igstclass} onChnlRowChange={onChnlRowChange} data={el} removeItem={removeChannelRow} />
                                                ))}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AlertUser {...ajaxResponse} />
    </>);
}

export default ItemView;
