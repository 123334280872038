import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import VendorContact from "./VendorContact"
import { InputText, InputCheckBox, AlertUser } from "../../"
import { axiosInstance } from "../../../../config/axios";

function VendorUpdate() {

    const { id } = useParams();
    const history = useHistory();

    const tempCnt = {
        id: Math.floor(Math.random() * 90000),
        VendorCnt_Name: '',
        VendorCnt_Desig: '',
        VendorCnt_Phone: '',
        VendorCnt_Email: '',
    };
    const initialVal = {
        Vendor_Id: 0,
        accounts: {},
        contact: [tempCnt]
    };
    const [data, setData] = useState(initialVal);
    const [error, setError] = useState({});
    const [card, setCard] = useState(true);
    const [loading, setLoading] = useState(false);
    const [ajaxResponse, setAjaxResponse] = useState({});
    useEffect(() => {
        setTimeout(() => {
            setAjaxResponse({});
        }, 5000);
    }, [ajaxResponse]);

    const inputChange = (e)=>{
        setData({...data, [e.target.name]: e.target.value});
        setError({...error, [e.target.name]: null});
    };
    const chkChange = (e) => setData({...data, [e.target.name]: e.target.checked ? 1 : 0});

    const accntInChange = (e) => setData({...data, accounts: { ...data.accounts, [e.target.name]: e.target.value }});
    const accntChkChange = (e) => setData({...data, accounts: { ...data.accounts, [e.target.name]: e.target.checked ? 1 : 0 }});

    const addContact = ()=> {
        setData({...data, contact: [...data.contact, tempCnt]});
    };
    const removeContact = (id)=> {
        let vrContact = [...data.contact];
        vrContact = vrContact.filter(obj => obj.id !== id);
        setData({...data, contact: vrContact});
    };
    const onContactChange = (name, val, id)=>{
        let contact = data.contact;
        let newArray = contact.map((obj, i) =>{ 
            return obj.id === id ? { ...obj, [name]: val } : obj;
        });
        setData({...data, contact: newArray });
    };

    const saveData = ()=>{
        let proceed = true;
        let err = {};
        
        if(!data.Vendor_Name) {
            proceed = false;
            err.Vendor_Name = 'Please enter value';
        } if(!data.Vendor_Phone) {
            proceed = false;
            err.Vendor_Phone = 'Please enter value';
        }

        setError(err);

        if(proceed) {
            axiosInstance
            .post('/people/vendor/update-data', data)
            .then(function (res) {
                if (res.data.status === 'success') {
                    setAjaxResponse({ status: res.data.status, message: res.data.message });
                }
                history.push('/people/vendor');
            }).catch();
        }
    };

    useEffect(() => {
        if(id) {
            axiosInstance.get(`/people/vendor/vendor-details?id=${id}`)
            .then(function (res) {
                if (res.data.status === "success") {
                    let tempData = res.data.data;
                    setData({...tempData, accounts: tempData.accounts ? tempData.accounts : {}});
                } else {
                    history.push('/people/vendor');
                }
            }).catch((e) => console.log(e));
        }
    }, []);

    return (<>
        <div className="section-body content-area">
            <div className="container-fluid">
                <div className="row border-bottom">
                    <div className="col col-sm-8 input-group d-flex justify-content-start">
                        <span className="input-group-append">
                            {loading ? <button type="button" className="btn c-red text-white px-4" disabled>Please wait...</button> : <button type="button" className="btn c-red text-white px-4" onClick={saveData}>UPDATE</button>}
                        </span>
                        <span className="input-group-append dropdown">
                            <button
                                className="btn c-lgt-blue text-white"
                                type="button"
                                data-toggle="dropdown"
                            >
                                <i className="fe fe-sliders"></i>
                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                    <a className="dropdown-item">
                                        <i className="dropdown-icon fe fe-trash-2"></i>Delete
                                    </a>
                                </div>
                            </button>
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-5">
                        <div className="card">
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0">
                                    <i className="fa fa-long-arrow-right text-red varient-button pr-2"></i>{" "}
                                    Vendor Information
                                </h6>
                            </div>
                            <div className="card-body pt-3 pb-3">
                                <div className="row">
                                    <InputText wrapper="col-6" title="Vendor Name" required={true} value={data.Vendor_Name} name="Vendor_Name" onChange={inputChange} error={error.Vendor_Name} />
                                    <InputText wrapper="col-6" title="Phone No." required={true} value={data.Vendor_Phone} name="Vendor_Phone" onChange={inputChange} error={error.Vendor_Phone} />
                                    <InputText wrapper="col-6" title="Email ID" required={false} value={data.Vendor_Email} name="Vendor_Email" onChange={inputChange} />
                                    <InputText wrapper="col-6" title="Address" required={false} value={data.Vendor_Address} name="Vendor_Address" onChange={inputChange} />
                                    <InputText wrapper="col-6" title="City" required={false} value={data.Vendor_City} name="Vendor_City" onChange={inputChange} />
                                    <InputText wrapper="col-6" title="State" required={false} value={data.Vendor_State} name="Vendor_State" onChange={inputChange} />
                                    <InputText wrapper="col-6" title="Country" required={false} value={data.Vendor_Country} name="Vendor_Country" onChange={inputChange} />
                                    <InputText wrapper="col-6" title="ZIP Code" required={false} value={data.Vendor_ZIP} name="Vendor_ZIP" onChange={inputChange} />
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0">
                                    <i className="fa fa-long-arrow-right text-red varient-button pr-2"></i>{" "}
                                    Tax Information
                                </h6>
                            </div>
                            <div className="card-body py-3 pb-0">
                                <div className="row">
                                    <InputText wrapper="col-6" title="GST Number" required={false} value={data.Vendor_GST} name="Vendor_GST" onChange={inputChange} />
                                    <InputText wrapper="col-6" title="TIN" required={false} value={data.Vendor_TIN} name="Vendor_TIN" onChange={inputChange} />
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0">
                                    <i className="fa fa-long-arrow-right text-red varient-button pr-2"></i>
                                    Regulatory Information
                                </h6>
                            </div>
                            <div className="card-body py-3 pb-0">
                                <div className="row">
                                    <InputCheckBox wrapper="col-12 col-lg-6 mt-4" title="Wholesale Drug Licence" checked={data.Vendor_Drug} name="Vendor_Drug" onCheck={chkChange} />
                                    <InputCheckBox wrapper="col-12 col-lg-6 mt-4" title="Insecticide Licence" checked={data.Vendor_Insecticide} name="Vendor_Insecticide" onCheck={chkChange} />
                                    <InputCheckBox wrapper="col-12 col-lg-6 mt-4" title="Liquor Licence" checked={data.Vendor_Liquor} name="Vendor_Liquor" onCheck={chkChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7">
                        <div className={`card ${!card && 'card-collapsed'}`}>
                            <div className="card-status c-blue"></div>
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0">
                                    <i className="fa fa-long-arrow-right text-red varient-button pr-2"></i>
                                    Contact Information
                                </h6>
                                <div className="card-options">
                                    <a href="#" onClick={(e)=>{
                                        e.preventDefault();
                                        addContact();
                                    }}>
                                        <i className="fe fe-plus-circle"></i>
                                    </a>
                                    <a className="c-pointer card-options-collapse" onClick={()=>setCard(!card)}>
                                        <i className="fe fe-chevron-up"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="card-body pt-0 pb-4">
                                <div className="row">
                                    <div className="table-responsive ast-table">
                                        <table className="table table-striped table-vcenter mb-0 text-nowrap">
                                            <thead>
                                                <tr>
                                                    <th className="pt-2 pb-2">
                                                        &nbsp;
                                                    </th>
                                                    <th className="t-pd-m">
                                                        Name
                                                    </th>
                                                    <th className="t-pd-m">
                                                        Designation
                                                    </th>
                                                    <th className="t-pd-m">
                                                        Phone
                                                    </th>
                                                    <th className="t-pd-l">
                                                        Email
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.contact.map((row)=>(
                                                    <VendorContact key={row.id} data={row} onRemove={removeContact} onValChange={onContactChange} />
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0">
                                    <i className="fa fa-long-arrow-right text-red varient-button pr-2"></i> Account Information
                                </h6>
                            </div>
                            <div className="card-body pt-4 pb-4">
                                <div className="row">
                                    <InputText wrapper="col-6" title="Account Name" required={false} value={data.accounts.VendorAcc_Name} name="VendorAcc_Name" onChange={accntInChange} />
                                    <InputText wrapper="col-6" title="Account Number" required={false} value={data.accounts.VendorAcc_No} name="VendorAcc_No" onChange={accntInChange} />
                                    <InputText wrapper="col-6" title="IFSC Code" required={false} value={data.accounts.VendorAcc_IFSC} name="VendorAcc_IFSC" onChange={accntInChange} />
                                    <InputText wrapper="col-6" title="UPI Address" required={false} value={data.accounts.VendorAcc_UPI} name="VendorAcc_UPI" onChange={accntInChange} />
                                    <InputCheckBox wrapper="ol-12 col-lg-6 mt-2" title="Credit Offered" checked={data.accounts.VendorAcc_Credit} name="VendorAcc_Credit" onCheck={accntChkChange} />
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0">
                                    <i className="fa fa-long-arrow-right text-red varient-button pr-2"></i>{" "}
                                    Account Information
                                </h6>
                            </div>
                            <div className="card-body pt-4 pb-4">
                                <div className="row">
                                    <InputText wrapper="col-12" title="Return Policy" required={false} value={data.Vendor_Return} name="Vendor_Return" onChange={inputChange} />
                                    <InputText wrapper="col-12" title="Refund Policy" required={false} value={data.Vendor_Refund} name="Vendor_Refund" onChange={inputChange} />
                                    <InputText wrapper="col-12" title="Pricing Agreement" required={false} value={data.Vendor_Pricing} name="Vendor_Pricing" onChange={inputChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AlertUser {...ajaxResponse} />
    </>);
}

export default VendorUpdate;
