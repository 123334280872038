import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { DropDown } from "../../";
import { axiosInstance } from "../../../../config/axios";

function VendorInvoices() {

    const { id } = useParams();
    const branchInfo = useSelector(store => store.branch);

    const [search, setSearch] = useState('');
    let [pageNo, setPageNo] = useState(0);
    const [pageInfo, setpageInfo] = useState({
        label: '0-0 of 0',
        total: 0
    });
    const [data, setData] = useState([]);

    const fetchFreshData = () => {
        setPageNo(0);
        fetchData();
    };

    const fetchData = () => {
        axiosInstance
        .post('/people/vendor/list-invoices', {
            id, search, pageNo
        }).then(function (res) {
            if (res.data.status === 'success') {
                setData(res.data.data.data);
                setpageInfo({
                    total: res.data.data.noofpages,
                    label: `${res.data.data.offset}-${res.data.data.limit} of ${res.data.data.total}`
                });
            }
        }).catch(e => console.log(e));
    };

    useEffect(() => {
        fetchFreshData();
    }, [search, pageNo]);

    const dropDownAction = [
        { icon: 'fe fe-layers', text: 'Invoices', url: `/people/vendor/invoices/${id}` },
        { icon: 'fe fe-layers', text: 'Payment History', url: `/people/vendor/payment-history/${id}` },
        { icon: 'fe fe-copy', text: 'Payment to be adjusted', url: `/people/vendor/payment-to-adjust/${id}` },
        { icon: 'fe fe-shopping-bag', text: 'Pending Orders', url: `/people/vendor/pending-orders/${id}` },
        { icon: 'fe fe-layers', text: 'Products', url: `/people/vendor/products/${id}` },
    ];

    return <>
        <div className="section-body content-area mb-0">
            <div className="container-fluid">
                <div className="row border-bottom">
                    <div className="col col-sm-8 input-group d-flex justify-content-start">
                        <div className="col-md-3 col-11 px-0 input-icon table-head-search">
                            <input type="text" className="form-control" placeholder="Search..." value={search} onChange={(e)=>setSearch(e.target.value)} />
                            <span className="input-icon-addon"><i className="fe fe-search"></i></span>
                        </div>
                        <span className="input-group-append"><Link to={`/people/vendor/payment/${id}`} className="btn c-red text-white"> Add Payment</Link></span>
                        <DropDown color="c-lgt-blue" icon="fe fe-sliders" items={dropDownAction} />
                    </div>
                    <div className="col-2 col-sm-4 d-flex justify-content-end mb-1 table-nav mt-1">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            if (pageNo > 0) setPageNo(--pageNo);
                        }}>
                            <i className="fe fe-chevron-left pr-sm-1 pr-2"></i>
                        </a>
                        <span className="px-1 d-none d-sm-block">{pageInfo.label}</span>
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            if (pageNo < pageInfo.total) setPageNo(++pageNo);
                        }}>
                            <i className="fe fe-chevron-right pl-sm-1 pl-2"></i>
                        </a>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body px-1">
                        <div className="table-responsive ast-table">
                            <table className="table table-striped table-vcenter mb-0 text-nowrap">
                                <thead>
                                    <tr>
                                        <th>Invoice No</th>
                                        <th>PO ID</th>
                                        <th>Total Items</th>
                                        <th>Total Value</th>
                                        <th>Refund Item</th>
                                        <th>Refund Value</th>
                                        <th>Payment Due</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data.map((el, i)=>(
                                    <tr key={el.id}>
                                        <td>
                                            <span className="d-flex">
                                                <Link to={`/inventory/purchase/purchase-details/${el.id}`} className="invo-txt">{el.invoice}</Link>
                                            </span>
                                        </td>
                                        <td>{el.po}</td>
                                        <td>{el.items}</td>
                                        <td>{parseFloat(el.amount).toFixed(branchInfo.Branch_DecimalPoints)}</td>
                                        <td>{el.returnItems}</td>
                                        <td>{parseFloat(el.returnAmnt).toFixed(branchInfo.Branch_DecimalPoints)}</td>
                                        <td>-</td>
                                        <td>{el.purchasedOn}</td>
                                    </tr>
                                ))}
                                {data.length == 0 && <tr><td colSpan={8}><p className="text-center py-4 mb-0">No Data Found</p></td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default VendorInvoices;