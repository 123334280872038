import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { axiosInstance } from "../../../config/axios";
import { selectStyle, reportFilter } from '../../../config/constants'
import "react-datepicker/dist/react-datepicker.css";

function ReportBalanceSheet() {

    const [filter, setFilter] = useState({
        duration: { value: 'this-year', label: 'This Year'},
        startDate: null,
        endDate: null,
    })

    const [data, setData] = useState([]);
    const [pl, setpl] = useState([]);
    const [activeDate, setActiveDate] = useState({
        startDate: null,
        endDate: null
    })
    const fetchData = () => {
        axiosInstance.post("/reports/accounts/balance-sheet", filter)
        .then(function (response) {
            if(response.data.status == 'success') {
                setData(response.data.data);
                setActiveDate({
                    startDate: response.data.startDate,
                    endDate: response.data.endDate
                });
                setpl(response.data.pl)
            }
        }).catch(err=>console.log(err));
    };

    useEffect(() => {
        fetchData();
    }, [filter]);

    const history = useHistory();
    const openAccntTrans = (accntId) => {
        const qp = new URLSearchParams();
        qp.append('account', accntId);
        qp.append('start', activeDate.startDate);
        qp.append('end', activeDate.endDate);
        history.push(`/reports/accounts/general-ledger-info?${qp.toString()}`);
    }

    return <>
        <div className="row">
            <div className="col col-md-12 input-group d-flex justify-content-start">
                <div className="col-md-4 col-11 px-0 input-icon table-head-search py-0">
                    <Select
                        placeholder="Select"
                        options={reportFilter}
                        styles={selectStyle}
                        value={filter.duration}
                        onChange={(val)=>{
                            setFilter({...filter, ...{duration: val, startDate: null, endDate: null}})
                        }}
                    />
                </div>
                <div className="col-md-4 col-11 px-0 input-icon table-head-search py-0 pl-1">
                    <DatePicker className="form-control" 
                    selectsRange={true}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Date"
                    startDate={filter.startDate} endDate={filter.endDate}
                    onChange={(update) => {
                        setFilter({...filter, ...{ startDate:update[0], endDate:update[1]}})
                    }} isClearable={true} disabled={filter.duration.value == 'custom' ? false : true} />
                </div>
                <span className="input-group-append dropdown"><button className="btn c-lgt-blue text-white" type="button">Download &nbsp; <i className="fe fe-download"></i></button></span>
            </div>
        </div>
        <div className="card">
            <div className="card-status c-blue top-layer"></div>
            <div className="card-body px-1 h-80-scroll">
                <div className="table-responsive ast-table h-100">
                    <table className="table table-vcenter mb-0 text-nowrap tbl-journal">
                        <thead>
                            <tr>
                                <th>Account</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.map((item, i)=>(
                        <>
                            <tr className="tbl-grey-head" key={i}>
                                <td colSpan={2}>{item.masterName}</td>
                            </tr>
                            {item.types.map((tp, i)=>(
                                <>
                                <tr className="tbl-sub" key={i}>
                                    <td className="pl-30" colSpan={2}>{tp.typeName}</td>
                                </tr>
                                {tp.accounts.map((ac, i)=>(
                                    <tr>
                                        <td className="pl-60">{ac.account}</td>
                                        <td>{ac.amnt}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td className="pl-30">Total for {tp.typeName}</td>
                                    <td>{tp.total}</td>
                                </tr>
                                </>
                            ))}
                            {item.masterId == 2 && (<tr>
                                <td className="pl-30">Current Year Earnings</td>
                                <td>{pl}</td>
                            </tr>)}
                            <tr className="tbl-sub">
                                <td>Total for {item.masterName}</td>
                                <td>{item.total}</td>
                            </tr>
                        </>
                        ))}
                        </tbody>
                    </table>
                    {data.length <= 0 && <p className="w-100 text-center pt-5">No Data Found</p>}
                </div>
            </div>
        </div>
    </>;
}

export default ReportBalanceSheet;
