import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { axiosInstance } from "../../../../config/axios";

function StockTransaction(props) {

    const { id } = props;


    const [trans, setTrans] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [accTrans, setAccTrans] = useState(true);
    const [filter, setFilter] = useState({
        startDate: null,
        endDate: null,
    });

    const loadFreshData = () => {
        setIsLoaded(true);
        loadTrasn();
    }

    const loadTrasn = () => {
        axiosInstance
        .post(`/inventory/view-item`)
        .then(function (res) {
            if (res.data.status === 'success') {
                setTrans(res.data.data);
            }
        }).catch(e => console.log(e));
    }

  return (
    <div className="section-body">
        <div className="container-fluid">
            <div className={`card ${!accTrans && 'card-collapsed'}`}>
                <div className="card-status c-blue top-layer"></div>
                <div className="card-header py-0 pl-0 m-0">
                    <div className="card-title font-weight-bold">
                        <div className="d-flex">
                            <DatePicker className="form-control" 
                            selectsRange={true}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Select Date"
                            startDate={filter.startDate} endDate={filter.endDate}
                            onChange={(update) => {
                                setFilter({...filter, ...{ startDate:update[0], endDate:update[1]}})
                            }} isClearable={true}  />
                            <span className="input-group-append dropdown"><button className="btn c-lgt-blue text-white" type="button"><i className="fe fe-calendar"></i></button></span>
                        </div>
                    </div>
                    <div className="card-options">
                        <a className="card-options-collapse pt-1 c-pointer" onClick={()=>setAccTrans(!accTrans)}><i className="fe fe-chevron-up"></i></a>
                    </div>
                </div>
                <div className="card-body px-1 py-0 my-0 border-top">
                    <div className="table-responsive ast-table">
                        <table className="table table-vcenter text-nowrap mb-1">
                            <thead className="bg-light">
                                <tr>
                                    <th className="pt-2">Date</th>
                                    <th className="pt-2">Variant</th>
                                    <th className="pt-2">Batch No </th>
                                    <th className="pt-2">Quantity</th>
                                    <th className="pt-2">Entry</th>
                                    <th className="pt-2">Transation</th>
                                    <th className="pt-2">User</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isLoaded && (<tr><td className="py-5 pb-0 text-center" colspan="7"><p><a href="javascript:;" className="text-muted"><i className="icon-refresh mr-1"></i> Load Data</a></p></td></tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default StockTransaction