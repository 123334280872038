import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Modal, InputSelectVal } from '../../../';
import { axiosInstance } from "../../../../../config/axios";

function TabPOLive(props) {

    const { vendor } = props;

    const branchInfo = useSelector((store)=>{
        return store.branch;
    });

    const [filter, setFilter] = useState({
        vendor: null,
        startDate: null,
        endDate: null,
    });
    const selectChange = (name, val) => {
        setFilter({ ...filter, [name]: val });
    };

    const [search, setSearch] = useState('');
    let [pageNo, setPageNo] = useState(0);
    const [pageInfo, setpageInfo] = useState({
        label: '0-0 of 0',
        total: 0
    });
    const [data, setData] = useState([]);

    const [mdlFilter, setMdlFilter] = useState(false);
    const closeModal = () => {
        setMdlFilter(false);
    };

    const fetchFreshData = () => {
        setPageNo(0);
        fetchData();
    };

    const fetchData = () => {
        let vendors = [];
        if(filter.vendor) 
            filter.vendor.forEach(el => { vendors.push(el.value) });
        axiosInstance
        .post('/inventory/purchase/grn-list', {
            search, pageNo, status: 1,
            startDate: filter.startDate ? (new Date(filter.startDate).toLocaleDateString("en-GB")).replace(/\//g, '-') : null,
            endDate: filter.endDate ? (new Date(filter.endDate).toLocaleDateString("en-GB")).replace(/\//g, '-') : null,
             vendors
        }).then(function (res) {
            if (res.data.status === 'success') {
                setData(res.data.data.data);
                setpageInfo({
                    total: res.data.data.noofpages,
                    label: `${res.data.data.offset}-${res.data.data.limit} of ${res.data.data.total}`
                });
            }
        }).catch(e => console.log(e));
    };

    useEffect(() => {
        fetchFreshData();
    }, [search, pageNo]);    

    return (
        <>
            <div className="row border-bottom">
                <div className="col col-sm-8 input-group d-flex justify-content-start">
                    <div className="col-md-3 col-11 px-0 input-icon table-head-search">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            value={search}
                            onChange={(e)=>setSearch(e.target.value)}
                        />
                        <span className="input-icon-addon">
                            <i className="fe fe-search"></i>
                        </span>
                    </div>
                    <span className="input-group-append">
                        <button
                            className="btn c-lgt-blue text-white"
                            type="button"
                            onClick={()=>setMdlFilter(true)}
                        >
                            <i className="fe fe-filter"></i>
                        </button>
                    </span>
                    <span className="input-group-append">
                        <Link to="/inventory/purchase-order/create-po" className="btn c-red text-white">
                            <i className="fe fe-plus"></i> New PO
                        </Link>
                    </span>
                    <span className="input-group-append">
                        <Link to="/inventory/purchase/create-grn" className="btn c-blue text-white">
                            <i className="fe fe-plus"></i> New Bill
                        </Link>
                    </span>
                </div>
                <div className="col-2 col-sm-4 d-flex justify-content-end mb-1 table-nav mt-1">
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        if (pageNo > 0) setPageNo(--pageNo);
                    }}>
                        <i className="fe fe-chevron-left pr-sm-1 pr-2"></i>
                    </a>
                    <span className="px-1 d-none d-sm-block">{pageInfo.label}</span>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        if (pageNo < pageInfo.total) setPageNo(++pageNo);
                    }}>
                        <i className="fe fe-chevron-right pl-sm-1 pl-2"></i>
                    </a>
                </div>
            </div>
            <div className="card">
                <div className="card-body px-1">
                    <div className="table-responsive ast-table">
                        <table className="table table-striped table-vcenter mb-0 text-nowrap">
                            <thead>
                                <tr>
                                    <th>
                                        <span className="table-checkbox-head">
                                            &nbsp;
                                        </span>{" "}
                                        GRN ID
                                    </th>
                                    <th>PO ID</th>
                                    <th>Vendor</th>
                                    <th>Taxes</th>
                                    <th>Total</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                            {data.map((el, i)=>(
                                <tr key={el.id}>
                                    <td>
                                        <span className="d-flex">
                                            <label className="custom-control custom-checkbox mb-0">
                                                <input type="checkbox" className="custom-control-input" />
                                                <span className="custom-control-label">&nbsp;</span>
                                            </label>
                                            <Link to={el.status == 1 ? `/inventory/purchase/purchase-details/${el.id}` : `/inventory/purchase/draft-grn/${el.id}`} className="invo-txt">{el.invoice ? el.invoice : 'N/A'}</Link>
                                        </span>                       
                                    </td>
                                    <td>{el.po ? el.po : 'N/A'}</td>
                                    <td>{el.vendor}</td>
                                    <td>{el.tax ? parseFloat(el.tax).toFixed(branchInfo.Branch_DecimalPoints) : '0.00'}</td>
                                    <td>{parseFloat(el.total).toFixed(branchInfo.Branch_DecimalPoints)}</td>
                                    <td>{el.issuedOn}</td>
                                    <td>{el.status == 1 ? 'Stock Updated' : 'Draft'}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Modal title="FILTER" itemwidth="modal-sm" isOpen={mdlFilter} onClose={()=>setMdlFilter(false)}>
                <div className="modal-body">
                    <div className="row">
                        <InputSelectVal wrapper="col-12" title="Vendor" required={false} name="vendor" value={filter.vendor} data={vendor} multiple={true} onSelect={selectChange} />

                        <div className="col-12">
                            <label className="form-label-small w-100">
                                Date
                            </label>
                            <div className="form-group">
                                <DatePicker className="form-control" 
                                    selectsRange={true}
                                    dateFormat="dd-MM-yyyy"
                                    startDate={filter.startDate} 
                                    endDate={filter.endDate}
                                    onChange={(update) => {
                                        setFilter({...filter, ...{ startDate: update[0], endDate: update[1] }})
                                    }} isClearable={true} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={()=>{
                        closeModal();
                        fetchFreshData();
                    }}>
                        Search
                    </button>
                </div>
            </Modal>
        </>
    );
}

export default TabPOLive;
