import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from 'sweetalert2';

import { InputText, InputSelect, AlertUser } from "../../"
import { axiosInstance } from "../../../../config/axios";

function VendorPayment() {

    const { id } = useParams();
    const qryParams = new URLSearchParams(window.location.search);
    const history = useHistory();
    const branchInfo = useSelector(store => store.branch);

    const [formData, setFormData] = useState({ accounts: [], transmode: []});
    const [data, setData] = useState({
        id: 0,
        date: new Date().toISOString().split('T')[0],
    });
    const [invoice, setInvoice] = useState([]);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [ajaxResponse, setAjaxResponse] = useState({});
    useEffect(() => {
        setTimeout(() => {
            setAjaxResponse({});
        }, 5000);
    }, [ajaxResponse]);

    const inputChange = (e)=>{
        setData({...data, [e.target.name]: e.target.value});
        setError({...error, [e.target.name]: null});
    };
    const selectChange = (name, val) => {
        setData({...data, [name]: val});
        setError({...error, [name]: ''});
    };

    useEffect(() => {
        axiosInstance.get(`/people/vendor/form-payment?id=${id}${qryParams.get('transId') ? `&transId=${qryParams.get('transId')}` : ''}`)
        .then(res => {
            setFormData({ transmode: res.data.transmode, accounts: res.data.accounts, vendorId: res.data.vendorId });
            if(res.data.transaction) {
                setData(res.data.transaction);
                setInvoice(res.data.grnTrans);
            }
        }).catch(()=>history.push('/people/vendor'));
    }, []);

    /************************** Manage GRN Start **************************/
    const [search, setSearch] = useState('');

    const [checkedData, setCheckedData] = useState([]);
    const checkItems = (e)=> {
        let tempIds = JSON.parse(JSON.stringify(checkedData));
        let chkVal = parseInt(e.target.value);
        if(e.target.checked) {
            if (!tempIds.includes(chkVal)) 
                tempIds.push(chkVal);
        } else {
            const index = tempIds.indexOf(chkVal);
            if (index !== -1) 
            tempIds.splice(index, 1);
        }
        setCheckedData(tempIds);
    };

    const deleteInvoice = ()=>{
        if(checkedData.length > 0) {
            let prevData = [...invoice];
            let newData = prevData.filter(obj => !checkedData.includes(obj.id));
            setInvoice(newData);
            setCheckedData([]);
        }
    }

    const onTblDataChange = (e, i) => {
        let inputVal = e.target.value;
        let tempData = [...invoice];
        if(e.target.name === 'amntPaying') {
            let ob = tempData.find(o=>o.id === i);
            if(parseFloat(inputVal) > ob.amntDue) {
                return false;
            }
        }
        tempData = tempData.map(obj => obj.id === i ? {...obj, ...{[e.target.name]: e.target.value, error: null}} : obj);
        setInvoice(tempData);
    };

    useEffect(() => {
        if(search) {
            axiosInstance
            .get(`/people/vendor/search-grn-bypo?vendor=${formData.vendorId}&search=${search}`)
            .then(res => {
                let prevData = [...invoice];
                let tempArray = res.data.data;
                tempArray.forEach(el => {
                    let temp = prevData.find(ob => ob.id == el.id);
                    if(!temp) {
                        prevData.push({
                            ...el, date: new Date().toISOString().split('T')[0]
                        })
                    }
                });
                setInvoice(prevData);
            }).catch();
        }
    }, [search]);    
    /*************************** Manage GRN End ***************************/

    const processPayment = ()=> {
        
        let proceed = true;
        let err = {};
        
        if(!data.amount) {
            proceed = false;
            err.amount = 'Please enter value';
        } if(!data.date) {
            proceed = false;
            err.date = 'Please select date';
        } if(!data.transmode) {
            proceed = false;
            err.transmode = 'Please select';
        } if(branchInfo.AllowAccounts && !data.account) {
            proceed = false;
            err.account = 'Please select';
        }

        let totalPaid = invoice.reduce((total, ob)=>{ return total += parseFloat(ob.amntPaying ? ob.amntPaying : 0) }, 0);
        let paidAmnt = data.amount ? parseFloat(data.amount) : 0;
        if(totalPaid > paidAmnt) {
            proceed = false;
            setAjaxResponse({ status: 'error', message: 'Please check amount' });
        }

        let newArray = [];
        invoice.forEach(el => {
            if(!el.amntPaying) {
                proceed = false;
                newArray.push({...el, error: 'Please enter value'});
            } else {
                newArray.push(el);
            }
        });
        setInvoice(newArray);

        setError(err);

        if(proceed) {
            setLoading(true);
            axiosInstance
            .post('/people/vendor/update-payment', {
                ...data, invoice, vendorId: formData.vendorId
            }).then(res => {
                setLoading(false);
                if(res.data.status === 'success') {
                    history.push(`/people/vendor/vendor-details/${id}`);
                }
                setAjaxResponse({ status: res.data.status, message: res.data.message });
            }).catch();
        }
    };

    const deleteTrans = ()=> {
        Swal.fire({
            title: "Are you sure?",
            text: "You wan't to delete !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                axiosInstance.post("/people/vendor/delete-payment", {
                    id: data.id
                }).then(function (res) {
                    if(res.data.status === 'success') {
                        history.push(`/people/vendor/vendor-details/${id}`);
                    }
                    setAjaxResponse({ status: res.data.status, message: res.data.message });
                }).catch(err => console.log(err));
            }
        });
    }

    return <>
        <div className="section-body content-area">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-3">
                        <div className="col-12 pl-0 d-flex justify-content-start">
                            <span className="input-group-append">
                            {loading ? <button type="button" className="btn c-red text-white" disabled> Please wait...</button> : <button type="button" className="btn c-red text-white" onClick={processPayment}> Process Payment</button>}
                            </span>
                            {data.id != 0 && <span className="input-group-append"><button type="button" className="btn c-blue text-white" onClick={deleteTrans}> <i className="fa fa-trash text-white variant-button pt-1"></i> </button></span>}
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <h6>PAYMENT INFO</h6>
                                    <InputText wrapper="col-12 pt-3 border-top mb-0" title="Amount" field="number" required={true} value={data.amount} name="amount" onChange={inputChange} error={error.amount} />

                                    <InputText wrapper="col-12 d-none" title="Purchase Order" required={false} value={data.po} name="po" onChange={inputChange} error={error.po} />

                                    <InputSelect wrapper={branchInfo.AllowAccounts ? 'col-12' : 'd-none'} title="Paid Through" required={true} value={data.account} name="account" onSelect={selectChange} data={formData.accounts} error={error.account} />

                                    <InputSelect wrapper="col-12" title="Payment Mode" required={true} value={data.transmode} name="transmode" onSelect={selectChange} data={formData.transmode} error={error.transmode} />

                                    <InputText wrapper={`col-12 ${data.transmode != 1 ? 'd-none' : ''}`} title="Cheque No" required={false} value={data.cheque} name="cheque" onChange={inputChange} />
                                    <InputText wrapper={`col-12 ${[3,4].includes(data.transmode) ? '' : 'd-none'}`} title="Bank" required={false} value={data.bank} name="bank" onChange={inputChange} />

                                    <InputText wrapper="col-12" title="Date" field="date" required={true} value={data.date} name="date" onChange={inputChange} error={error.date} />
     
                                    <div className="col-12">
                                        <label className="form-label-small">Comments</label>
                                        <div className="form-group">
                                            <textarea rows="2" className="form-control" name="remarks" value={data.remarks} onChange={inputChange}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="d-flex">
                                <div className="col border-right text-center pt-3 pb-4">
                                    <small className="mb-0">Payment to be Adjusted</small>
                                    <h4 className="font-30 font-weight-bold text-col-blue">0.00</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-9 mt-5 mt-xl-0">
                        <div className="col input-group d-flex justify-content-start pl-0">
                            <div className="col-md-3 col-11 px-0 input-icon table-head-search">
                                <input type="text" className="form-control" placeholder="Search PO" onBlur={(e)=>setSearch(e.target.value)} />
                                <span className="input-icon-addon"><i className="fe fe-search"></i></span>
                            </div>
                            <span className="input-group-append"><button type="button" className="btn c-blue text-white"> Auto Adjust</button></span>
                            <span className="input-group-append"><button type="button" className="btn c-red text-white" onClick={deleteInvoice}> <i className="fa fa-trash text-white variant-button pt-1"></i> </button></span>
                            <span className="input-group-append"><button type="button" className="btn bg-white"> <i className="fa fa-plus text-gray variant-button pt-1"></i> </button></span>
                        </div>
                        <div className="card">
                            <div className="card-status c-blue"></div>
                            <div className="card-body pt-2 px-0">
                                <div className="table-responsive ast-table">
                                    <table className="table table-borderless table-vcenter mb-0 text-nowrap">
                                        <thead>
                                            <tr className="border-bottom">
                                                <th className="t-pd-m">Invoice ID <span className="red">*</span></th>
                                                <th className="t-pd-s">Payment Due</th>
                                                <th className="t-pd-m">Paying Amount <span className="red">*</span></th>
                                                <th className="t-pd-s">Date <span className="red">*</span></th>
                                                <th className="pt-2 pb-2">Comments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {invoice.map((el)=>(
                                            <tr key={el.id}>
                                                <td className="d-flex justify-content-between">
                                                    <span className="d-flex">
                                                        <label className="custom-control custom-checkbox mb-0 pt-2">
                                                            <input type="checkbox" className="custom-control-input" value={el.id} onChange={checkItems} />
                                                            <span className="custom-control-label">&nbsp;</span>
                                                        </label>
                                                        <input type="text" className="form-control w-100" value={el.purchaseInvoice} />
                                                    </span>
                                                </td>
                                                <td className="px-0 inw-s">
                                                    <input type="text" className="form-control w-100" value={el.amntDue} readOnly />
                                                </td>
                                                <td className="px-0 inw-m">
                                                    <input type="number" className="form-control w-100" onChange={(e)=>onTblDataChange(e, el.id)} value={el.amntPaying} name="amntPaying" />
                                                    {el.error && <small className="lbl-error">{el.error}</small>}
                                                </td>
                                                <td className="px-0 inw-s">
                                                    <input type="date" className="form-control w-100" onChange={(e)=>onTblDataChange(e, el.id)} value={el.date} name="date" />
                                                </td>
                                                <td className="px-0 inw-m">
                                                    <input type="text" className="form-control w-100" onChange={(e)=>onTblDataChange(e, el.id)} value={el.remarks} name="remarks" />
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AlertUser {...ajaxResponse} />
    </>;
}

export default VendorPayment;