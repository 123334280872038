import React, { useState, useEffect } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { selectStyle } from '../../../config/constants'
import { axiosInstance } from "../../../config/axios";

function AccountSettings() {
    
    const initialVal = {
        journal: {
            Series_Prefix: '',
            Series_Inc: ''
        },
        accounts: {
            sales: null,
            credit: null,
            purchase: null,
            expense: null,
            expPaid: null,
            petty: null,
            pettyPaid: null,
        }
    };
    const [ajaxResponse, setAjaxResponse] = useState({})
    const [loading, setLoading] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)
    const [error, setError] = useState({})
    const [pageTab, setPageTab] = useState(1);
    const [formData, setFormData] = useState(initialVal);
    const [salesAc, setSalesAc] = useState([]);
    const [purchaseAc, setPurchaseAc] = useState([]);
    const [creditAc, setCreditAc] = useState([]);
    const [expPaidAc, setExpPaidAc] = useState([]);
    const [expAc, setExpAc] = useState([]);

    const [petty, setPetty] = useState([]);
    const [pettyPaid, setPettyPaid] = useState([]);

    useEffect(() => {
        axiosInstance.get("/accounts/settings")
        .then(function (response) {
            if(response.data.status === 'success') {
                let tempData = [];
                response.data.data.forEach((item) => {
                    tempData.push({
                        label: item.label,
                        value: item.value.toString(),
                        options: item.Accounts,
                    });
                });
                let acTypes = response.data.accTypes.sales.split(",");
                let tempArr = tempData.filter(obj => acTypes.includes(obj.value));
                setSalesAc(tempArr);

                acTypes = response.data.accTypes.credit.split(",");
                tempArr = tempData.filter(obj => acTypes.includes(obj.value));
                setCreditAc(tempArr);

                acTypes = response.data.accTypes.purchase.split(",");
                tempArr = tempData.filter(obj => acTypes.includes(obj.value));
                setPurchaseAc(tempArr);

                acTypes = response.data.accTypes.expense.split(",");
                tempArr = tempData.filter(obj => acTypes.includes(obj.value));
                setExpAc(tempArr);
                acTypes = response.data.accTypes.expensepaid.split(",");
                tempArr = tempData.filter(obj => acTypes.includes(obj.value));
                setExpPaidAc(tempArr);
                
                acTypes = response.data.accTypes.petty.split(",");
                tempArr = tempData.filter(obj => acTypes.includes(obj.value));
                setPetty(tempArr);
                acTypes = response.data.accTypes.pettyPaid.split(",");
                tempArr = tempData.filter(obj => acTypes.includes(obj.value));
                setPettyPaid(tempArr);

                setFormData({...formData, ...{journal: response.data.prefix, accounts: response.data.defAccnt}})
            }
        }).catch(err => console.log(err));
    }, []);

    const validate = (values) => {
        const err = {};

        if(!values.journal.Series_Inc) {
            err.journal = "Please enter journal no";
        } if(!values.accounts.sales) {
            err.sales = "Please select account";
        } if(!values.accounts.credit) {
            err.credit = "Please select account";
        } if(!values.accounts.purchase) {
            err.purchase = "Please select account";
        } if(!values.accounts.expense) {
            err.expense = "Please select account";
        } if(!values.accounts.expPaid) {
            err.expPaid = "Please select account";
        } if(!values.accounts.petty) {
            err.petty = "Please select account";
        } if(!values.accounts.pettyPaid) {
            err.pettyPaid = "Please select account";
        }

        return err;
    }

    useEffect(() => {
        if (Object.keys(error).length === 0 && isSubmit) {
            setLoading(true);
            axiosInstance
                .post("/accounts/settings-update", formData)
                .then(function (response) {
                    setLoading(false);
                    setIsSubmit(false);
                    setAjaxResponse(response.data)
                    setTimeout(() => {
                        setAjaxResponse({})
                    }, 500);
                })
                .catch((err) => console.error(err.message));
        }
    }, [error]);

    return (
        <div>
            <div className="section-body">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center horizontal-nav">
                        <ul className="nav nav-tabs page-header-tab">
                            <li className="nav-item" onClick={() => setPageTab(1)} >
                                <a className={ pageTab == 1 ? "nav-link active" : "nav-link" }>
                                    General Settings
                                </a>
                            </li>
                            <li className="nav-item d-none" onClick={() => setPageTab(0)}>
                                <a className={ pageTab == 0 ? "nav-link active" : "nav-link" }>
                                    Inactive
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="section-body content-area">
                <div className="container-fluid">
                    <div className="tab-content">
                        <div className={ pageTab == 1 ? "tab-pane fade show active" : "tab-pane fade" } role="tabpanel">
                            <div className="row">
                                <div className="col col-sm-8 input-group d-flex justify-content-start pl-0 ml-0">
                                    <span className="input-group-append">
                                        <button onClick={()=>{
                                            setError(validate(formData));
                                            setIsSubmit(true);
                                        }} type="button" id="btnSettings" className="btn c-red text-white px-4">Update</button>
                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-5 bg-white px-4 scroll-overflow">
                                    <div className="table-responsive">
                                        <table className="table mb-4 mt-3 table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td className="border-bottom pb-0" colSpan="2">
                                                        <h6 className="c-txt-blue font-weight-bold text-uppercase">Default Accounts</h6>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pb-0" colSpan="2">
                                                        <label>Cash Deposited To</label>
                                                        <div className="form-group">
                                                            <Select
                                                                placeholder="Select"
                                                                options={salesAc}
                                                                styles={selectStyle}
                                                                value={formData.accounts.sales}
                                                                onChange={(val) =>{
                                                                    setFormData({...formData, accounts: {...formData.accounts, sales: val}})
                                                                    setError({...error, sales: null})
                                                                }}
                                                            />
                                                            {error.sales && <small className="red mb-2">{error.sales}</small>}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pb-0" colSpan="2">
                                                        <label>Customer Credit</label>
                                                        <div className="form-group">
                                                            <Select
                                                                placeholder="Select"
                                                                options={creditAc}
                                                                styles={selectStyle}
                                                                value={formData.accounts.credit}
                                                                onChange={(val) =>{
                                                                    setFormData({...formData, accounts: {...formData.accounts, credit: val}})
                                                                    setError({...error, credit: null})
                                                                }}
                                                            />
                                                            {error.credit && <small className="red mb-2">{error.credit}</small>}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pb-0" colSpan="2">
                                                        <label>Purchase </label>
                                                        <div className="form-group">
                                                            <Select
                                                                placeholder="Select"
                                                                options={purchaseAc}
                                                                styles={selectStyle}
                                                                value={formData.accounts.purchase}
                                                                onChange={(val) =>{
                                                                    setFormData({...formData, accounts: {...formData.accounts, purchase: val}})
                                                                    setError({...error, purchase: null})
                                                                }}
                                                            />
                                                            {error.purchase && <small className="red mb-2">{error.purchase}</small>}
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="pb-0" colSpan="2">
                                                        <label>Expense Account </label>
                                                        <div className="form-group">
                                                            <Select
                                                                placeholder="Select"
                                                                options={expAc}
                                                                styles={selectStyle}
                                                                value={formData.accounts.expense}
                                                                onChange={(val) =>{
                                                                    setFormData({...formData, accounts: {...formData.accounts, expense: val}})
                                                                    setError({...error, expense: null})
                                                                }}
                                                            />
                                                            {error.expense && <small className="red mb-2">{error.expense}</small>}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pb-0" colSpan="2">
                                                        <label>Expense Paid Through </label>
                                                        <div className="form-group">
                                                            <Select
                                                                placeholder="Select"
                                                                options={expPaidAc}
                                                                styles={selectStyle}
                                                                value={formData.accounts.expPaid}
                                                                onChange={(val) =>{
                                                                    setFormData({...formData, accounts: {...formData.accounts, expPaid: val}})
                                                                    setError({...error, expPaid: null})
                                                                }}
                                                            />
                                                            {error.expPaid && <small className="red mb-2">{error.expPaid}</small>}
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="pb-0" colSpan="2">
                                                        <label>Pettycash </label>
                                                        <div className="form-group">
                                                            <Select
                                                                placeholder="Select"
                                                                options={petty}
                                                                styles={selectStyle}
                                                                value={formData.accounts.petty}
                                                                onChange={(val) =>{
                                                                    setFormData({...formData, accounts: {...formData.accounts, petty: val}})
                                                                    setError({...error, petty: null})
                                                                }}
                                                            />
                                                            {error.petty && <small className="red mb-2">{error.petty}</small>}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pb-0" colSpan="2">
                                                        <label>Pettycash Paid Through </label>
                                                        <div className="form-group">
                                                            <Select
                                                                placeholder="Select"
                                                                options={pettyPaid}
                                                                styles={selectStyle}
                                                                value={formData.accounts.pettyPaid}
                                                                onChange={(val) =>{
                                                                    setFormData({...formData, accounts: {...formData.accounts, pettyPaid: val}})
                                                                    setError({...error, pettyPaid: null})
                                                                }}
                                                            />
                                                            {error.pettyPaid && <small className="red mb-2">{error.pettyPaid}</small>}
                                                        </div>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-6 bg-white px-4 ml-0 ml-lg-1">
                                    <div className="table-responsive">
                                        <table className="table mb-4 mt-3 table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td className="border-bottom pb-0" colSpan="2">
                                                        <h6 className="c-txt-blue font-weight-bold text-uppercase">Journal
                                                        Prefix</h6>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pt-3">Journal Prefix</td>
                                                    <td className="inw-l pt-3">
                                                        <div className="form-group">
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" onChange={(e)=>setFormData({...formData, journal: {...formData.journal, Series_Prefix: e.target.value}})} value={formData.journal.Series_Prefix} />
                                                                <span className="input-group-append">
                                                                    <input type="text" className="form-control" onChange={(e)=>{
                                                                        setFormData({...formData, journal: {...formData.journal, Series_Inc: e.target.value}})
                                                                        setError({...error, journal: null})
                                                                    }} value={formData.journal.Series_Inc} />
                                                                </span>
                                                            </div>
                                                            <span className="small-txt">Prefix + Series (Eg: A1000)</span>
                                                            {error.journal && <small className="red mb-2"><br />{error.journal}</small>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={ pageTab == 0 ? "tab-pane fade show active" : "tab-pane fade" } role="tabpanel">
                            Tab 2
                        </div>
                    </div>
                </div>
            </div>
            {ajaxResponse.status && <div className="user-alert"><div className={`alert rounded-0 ${ajaxResponse.status === 'success' ? 'alert-success' : 'alert-danger'}`}>{ajaxResponse.message}</div></div>}
        </div>
    );
}

const mapStateToProps = (state) => ({
    fixNavbar: state.settings.isFixNavbar,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
