import React, { useState } from 'react'

import { axiosInstance } from "../../../../config/axios";

import { TabGRN, TabGRNReturn, TabPOCompleted, TabPODeleted, TabPODraft, TabPOLive } from './ListTabs';
import { useEffect } from 'react';

function PurchaseList() {

    const [vendor, setVendor] = useState([]);
    const [tab, setTab] = useState(0);

    useEffect(() => {
        axiosInstance
        .get('/inventory/purchase/list-view')
        .then(function (res) {
            if (res.data.status === 'success') {
                setVendor(res.data.vendor);
            }
        }).catch(e => console.log(e));
    }, []);    

    return (<>
        <div className="section-body">
            <div className="container-fluid">
                <div className="d-flex justify-content-between align-items-center horizontal-nav">
                    <ul className="nav nav-tabs page-header-tab">
                        <li className="nav-item"><span className={`nav-link ${tab == 0 ? 'active' : null}`} onClick={()=>setTab(0)}>Live</span></li>
                        <li className="nav-item"><span className={`nav-link ${tab == 1 ? 'active' : null}`} onClick={()=>setTab(1)}>Draft</span></li>
                        <li className="nav-item"><span className={`nav-link ${tab == 2 ? 'active' : null}`} onClick={()=>setTab(2)}>Completed</span></li>
                        <li className="nav-item"><span className={`nav-link ${tab == 3 ? 'active' : null}`} onClick={()=>setTab(3)}>Bill</span></li>
                        <li className="nav-item"><span className={`nav-link ${tab == 4 ? 'active' : null}`} onClick={()=>setTab(4)}>Bill Return</span></li>
                        <li className="nav-item d-none"><span className={`nav-link ${tab == 5 ? 'active' : null}`} onClick={()=>setTab(5)}>PO Deleted</span></li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="section-body content-area">
            <div className="container-fluid">
                <div className="tab-content">
                    <div className={`tab-pane fade ${tab == 0 ? 'show active' : ''}`} role="tabpanel">
                        <TabPOLive vendor={vendor} />
                    </div>
                    <div className={`tab-pane fade ${tab == 1 ? 'show active' : ''}`} role="tabpanel">
                        <TabPODraft vendor={vendor} />
                    </div>
                    <div className={`tab-pane fade ${tab == 2 ? 'show active' : ''}`} role="tabpanel">
                        <TabPOCompleted vendor={vendor} />
                    </div>
                    <div className={`tab-pane fade ${tab == 3 ? 'show active' : ''}`} role="tabpanel">
                        <TabGRN vendor={vendor} /> 
                    </div>
                    <div className={`tab-pane fade ${tab == 4 ? 'show active' : ''}`} role="tabpanel">
                        <TabGRNReturn vendor={vendor} />
                    </div>
                    <div className={`tab-pane fade ${tab == 5 ? 'show active' : ''}`} role="tabpanel">
                        <TabPODeleted />
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default PurchaseList