import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { axiosInstance } from "../../../../config/axios";
import VariantCard from './VariantCard';
import StockTransaction from './StockTransaction';

function StockInfo() {

    const { id } = useParams();

    const [data, setData] = useState({});

    useEffect(() => {
        axiosInstance
        .get(`/inventory/view-item?id=${id}`)
        .then(function (res) {
            if (res.data.status === 'success') {
                setData(res.data.data);
            }
        }).catch(e => console.log(e));
    }, []);    

    return (<>
        {data.Item_Name && (<>
            <div className="section-body">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between">
                        <span className="input-group-append"><a href="#" className="btn c-red text-white px-3">EDIT ITEM</a></span>
                        <span className="input-group-append"><a href="#" className="btn c-blue text-white px-3">ITEM LOG</a></span>
                    </div>
                    <div className="bg-white col-12 border-right pb-2">
                        <div className="row">
                            <div className="col-12 pl-3 border-bottom">
                                <h6 className="pt-3">General</h6>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="table-responsive px-4">
                                    <table className="table table-borderless table-vcenter mb-0 text-nowrap">
                                        <tbody>
                                            <tr>
                                                <td className="w-50">Item Name</td>
                                                <td>{data.Item_Name}</td>
                                            </tr>
                                            <tr>
                                                <td className="w-25">Item Code</td>
                                                <td>{data.Item_Code}</td>
                                            </tr>
                                            <tr>
                                                <td className="w-25">Item Category</td>
                                                <td>{data.cat ? `${data.cat.Cat_Name} ${data.subcat ? ` > ${data.subcat.Sub_Name}` : null}`  : '-'}</td>
                                            </tr>
                                            <tr>
                                                <td className="w-25">HSN</td>
                                                <td>{data.hsn ? data.hsn.HSN_Code : '-'}</td>
                                            </tr>
                                            <tr>
                                                <td className="w-25">Unit</td>
                                                <td>
                                                    {data.unit ? data.unit.Metric_Name : '-'}
                                                    {data.subunit ? ` | ${data.subunit.Metric_Name}` : '-'}
                                                    {data.subunit && data.unit ? ( <small> (
                                                        {data.Conversion_Mode == 0 ? `1 ${data.unit.Metric_Name} = ${parseFloat(data.Conversion_Rate) + ' ' + data.subunit.Metric_Name}` : `1 ${data.subunit.Metric_Name} = ${parseFloat(data.Conversion_Rate) + ' ' + data.unit.Metric_Name}`}
                                                        {}
                                                    )</small>) : null}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="w-25">Inventory Tracking</td>
                                                <td>{data.tracking ? data.tracking.track : '-'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="table-responsive px-4">
                                    <table className="table table-borderless table-vcenter mb-0 text-nowrap">
                                        <tbody>
                                            <tr>
                                                <td className="w-25">Item Refund</td>
                                                <td>{data.Allow_Refund ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <td className="w-25">Item Variants</td>
                                                <td>{data.Item_VariantStatus ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <td className="w-50">Negative Billing Allowed</td>
                                                <td>{data.Allow_Negative ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <td className="w-25">Status</td>
                                                <td className="">{data.Is_Active ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <td className="w-25">Item Description</td>
                                                <td>{data.Item_Desc}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        {data.Is_Sellable == 1 ? <span className="tag c-green px-3">This is sellable stock item</span> : <span className="tag c-red px-3">This is non sellable stock item</span>}
                    </div>
                    <div className="col-12 pt-4 pb-2">
                        <h6 className="font-weight-bold">PRODUCT DETAILS / VARIANTS</h6>
                    </div>
                    {data.vrmaster.map((el, i)=>(
                        <VariantCard key={el.Variant_Id} data={data} variant={el} />
                    ))}
                </div>
            </div>

            <div className="section-body mt-4">
                <div className="container-fluid">
                    <div className="row border-bottom px-0">
                        <div className="col col-sm-6">
                            <h6 className="font-weight-bold mb-0 pt-2">TRANSACTIONS</h6>
                        </div>
                        <div className="col-2 col-sm-6 d-flex justify-content-end mb-1 table-nav mt-1">
                            <a href="#"><i className="fe fe-chevron-left pr-sm-1 pr-2"></i></a>
                            <span className="px-1 d-none d-sm-block"></span>
                            <a href="#"><i className="fe fe-chevron-right pl-sm-1 pl-2"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            
            <StockTransaction id={id} />

        </>)}        
    </>);
}

export default StockInfo;
