import React, { useState, useEffect } from "react";
import { selectStyle } from '../../../config/constants';
import { axiosInstance } from "../../../config/axios";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Modal from "../Modal";
import "react-datepicker/dist/react-datepicker.css";

function JournalReport() {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const vrStatus = [
        { value: 1, label: "Published" },
        { value: 0, label: "Draft" },
    ];

    const [modalOpen, setModalOpen] = useState(false);
    const closeModal = () => setModalOpen(false);

    const [dataStatus, setDataStatus] = useState({});
    const [data, setData] = useState([]);
    let [pageNo, setPageNo] = useState(0);
    const [noOfPages, setNoOfPages] = useState(0);
    const [pagination, setPagination] = useState("0-0 of 0");

    const fetchData = () => {
        axiosInstance.post("/accounts/journal-report", {
            pageNo: pageNo,
            startDate: startDate ? (new Date(startDate).toLocaleDateString("en-GB")).replace(/\//g, '-') : null,
            endDate: endDate ? (new Date(endDate).toLocaleDateString("en-GB")).replace(/\//g, '-') : null,
            status: (dataStatus ? dataStatus.value : null),
        }).then(function (response) {
            setNoOfPages(response.data.data.noofpages);
            setData(response.data.data.rows);
            setPagination(`${response.data.data.offset}-${response.data.data.limit} of ${response.data.data.count}`);
        }).catch(err=>console.log(err));
    };

    useEffect(() => {
        fetchData();
    }, [pageNo, dateRange]);

    return (
        <div className="section-body content-area">
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-sm-8 input-group d-flex justify-content-start">
                        <div className="col-md-4 col-11 px-0 input-icon table-head-search">
                            <DatePicker className="form-control" 
                                selectsRange={true}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Select Date"
                                startDate={startDate} endDate={endDate}
                                onChange={(update) => {
                                    setDateRange(update);
                                }} isClearable={true} />
                        </div>
                        <span className="input-group-append">
                            <button
                                onClick={() => setModalOpen(true)}
                                className="btn c-blue text-white"
                                type="button"
                            >
                                <i className="fe fe-filter"></i>
                            </button>
                        </span>
                    </div>
                    <div className="col-2 col-sm-4 d-flex justify-content-end mb-1 table-nav">
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                if (pageNo > 0) setPageNo(--pageNo);
                            }}
                        >
                            <i className="fe fe-chevron-left pr-sm-1 pr-2"></i>
                        </a>
                        <span className="px-1 d-none d-sm-block">
                            {pagination}
                        </span>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                if (pageNo < noOfPages) setPageNo(++pageNo);
                            }}
                        >
                            <i className="fe fe-chevron-right pl-sm-1 pl-2"></i>
                        </a>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body px-1 pt-0">
                        <div className="table-responsive ast-table">
                            <table className="table table-striped table-vcenter mb-0 text-nowrap tbl-journal">
                            {data.map((item, i)=>(
                                <thead key={i}>
                                    <tr>
                                        <th>{`${new Date(
                                            item.Journal_Date
                                        ).toLocaleDateString(
                                            "en-GB"
                                        ).replace(/\//g, '-')}`} - <a href="#" className="font-weight-bold">{item.Is_Manual === 0 ? item.Journal_Remarks : 'Journal No. ' + item.Journal_No}</a></th>
                                        <td className="text-right">Debit</td>
                                        <td className="text-right">Credit</td>
                                    </tr>
                                    {item.AcJourDetails.map((det, j)=>(
                                        <tr key={j}>
                                            <td>{det.Account.Accnt_Name}</td>
                                            <td className="text-right">{parseFloat(det.JDet_Debit) != 0 ? parseFloat(det.JDet_Debit).toFixed(2) : ''}</td>
                                            <td className="text-right">{parseFloat(det.JDet_Credit) != 0 ? parseFloat(det.JDet_Credit).toFixed(2) : ''}</td>
                                        </tr>
                                    ))}
                                    <tr className="bg-light-gray">
                                        <th>&nbsp;</th>
                                        <th className="text-right">{(item.AcJourDetails.reduce((sum, obj) => sum + parseFloat(obj.JDet_Debit), 0)).toFixed(2)}</th>
                                        <th className="text-right">{(item.AcJourDetails.reduce((sum, obj) => sum + parseFloat(obj.JDet_Credit), 0)).toFixed(2)}</th>
                                    </tr>
                                </thead>
                            ))}
                            </table>
                            {data.length <= 0 && <p className="w-100 text-center pt-5">No Data Found</p>}
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                title="FILTER"
                itemwidth="modal-sm"
                isOpen={modalOpen}
                onClose={closeModal}
            >
                <div className="modal-body">
                    <div className="row">
                        <div className="col-12">
                            <label className="form-label-small w-100">
                                Status
                            </label>
                            <div className="form-group">
                                <Select options={vrStatus} value={dataStatus} styles={selectStyle} onChange={(val)=>setDataStatus(val)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={()=>{
                        fetchData();
                        closeModal();
                    }}>
                        Search
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default JournalReport;
