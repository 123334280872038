import React from "react";
import Select from "react-select";
import { selectStyle } from "../../config/constants";

function InputSelect(props) {

    const formatOptionLabel = ({ label, subtext }) => (<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <div>{label}</div>
        <div style={{ fontSize: '80%', color: 'gray', marginLeft: '10px', marginTop: '2px' }}>{subtext}</div>
    </div>);

    return (
        <div className={props.wrapper}>
            <div className="form-group">
                <label className="form-label-small w-100">{props.title} {props.required && <span className="red">*</span>}</label>
                <Select options={props.data} 
                    styles={selectStyle} 
                    menuPosition="fixed"
                    menuPlacement="bottom"
                    formatOptionLabel={formatOptionLabel}
                    value={props.data ? (props.value !== '' || props.value !== 0 ? props.data.find((i) => i.value === props.value) : "") : ""}
                    onChange={(e)=> props.onSelect(props.name, e.value)}
                    isMulti={props.multiple}
                />
                {props.error && (<small className="lbl-error">{props.error}</small>)}
            </div>
        </div>
    );
}

export default InputSelect;
