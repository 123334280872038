import React from "react";

function VendorContact(props) {

    const { data, onRemove, onValChange } = props;

    const inputChange = (e) => {
        onValChange(e.target.name, e.target.value, data.id);
    };

    return (
        <tr className="contact-row">
            <td className="px-0">
                <span className="d-flex justify-content-start">
                    <a href="#" onClick={(e)=>{
                        e.preventDefault();
                        onRemove(data.id);
                    }}>
                        <i className="fe fe-minus-circle text-red varient-button"></i>
                    </a>
                </span>
            </td>
            <td className="px-0">
                <input type="text" className="form-control w-100" name="VendorCnt_Name" value={data.VendorCnt_Name} onChange={inputChange} />
            </td>
            <td className="px-0">
                <input type="text" className="form-control w-100" name="VendorCnt_Desig" value={data.VendorCnt_Desig} onChange={inputChange} />
            </td>
            <td className="px-0">
                <input type="text" className="form-control w-100" name="VendorCnt_Phone" value={data.VendorCnt_Phone} onChange={inputChange} />
            </td>
            <td className="px-0 inw-m">
                <input type="text" className="form-control w-100" name="VendorCnt_Email" value={data.VendorCnt_Email} onChange={inputChange} />
            </td>
        </tr>
    );
}

export default VendorContact;
