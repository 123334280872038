import React, { useState, useEffect } from "react";
import { selectStyle } from '../../../config/constants';
import { axiosInstance } from "../../../config/axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Modal from "../Modal";
import "react-datepicker/dist/react-datepicker.css";

function ManualJournal() {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const vrStatus = [
        { value: 1, label: "Published" },
        { value: 0, label: "Draft" },
    ];

    const [modalOpen, setModalOpen] = useState(false);
    const closeModal = () => setModalOpen(false);

    const [dataStatus, setDataStatus] = useState({});
    const [dataSearch, setDataSearch] = useState("");
    const [data, setData] = useState([]);
    let [pageNo, setPageNo] = useState(0);
    const [noOfPages, setNoOfPages] = useState(0);
    const [pagination, setPagination] = useState("0-0 of 0");

    const fetchData = () => {
        axiosInstance.post("/accounts/journal-list", {
            search: dataSearch,
            pageNo: pageNo,
            startDate: startDate ? (new Date(startDate).toLocaleDateString("en-GB")).replace(/\//g, '-') : null,
            endDate: endDate ? (new Date(endDate).toLocaleDateString("en-GB")).replace(/\//g, '-') : null,
            status: (dataStatus ? dataStatus.value : null),
        }).then(function (response) {
            setNoOfPages(response.data.data.noofpages);
            setData(response.data.data.rows);
            setPagination(`${response.data.data.offset}-${response.data.data.limit} of ${response.data.data.count}`);
        }).catch(err=>console.log(err));
    };

    useEffect(() => {
        fetchData();
    }, [dataSearch, pageNo]);

    return (
        <div className="section-body content-area">
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-sm-8 input-group d-flex justify-content-start">
                        <div className="col-md-4 col-11 px-0 input-icon table-head-search">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                onChange={(e) => setDataSearch(e.target.value)}
                                value={dataSearch}
                            />
                            <span className="input-icon-addon">
                                <i className="fe fe-search"></i>
                            </span>
                        </div>
                        <span className="input-group-append">
                            <Link
                                to="/accounts/create-journal"
                                className="btn c-blue text-white"
                            >
                                Add New
                            </Link>
                        </span>
                        <span className="input-group-append">
                            <button
                                onClick={() => setModalOpen(true)}
                                className="btn c-red text-white"
                                type="button"
                            >
                                <i className="fe fe-filter"></i>
                            </button>
                        </span>
                    </div>
                    <div className="col-2 col-sm-4 d-flex justify-content-end mb-1 table-nav">
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                if (pageNo > 0) setPageNo(--pageNo);
                            }}
                        >
                            <i className="fe fe-chevron-left pr-sm-1 pr-2"></i>
                        </a>
                        <span className="px-1 d-none d-sm-block">
                            {pagination}
                        </span>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                if (pageNo < noOfPages) setPageNo(++pageNo);
                            }}
                        >
                            <i className="fe fe-chevron-right pl-sm-1 pl-2"></i>
                        </a>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body px-1">
                        <div className="table-responsive ast-table">
                            <table className="table table-striped table-vcenter mb-0 text-nowrap">
                                <thead>
                                    <tr>
                                        <th>
                                            <span className="table-checkbox-head">
                                                &nbsp;
                                            </span>
                                            Date
                                        </th>
                                        <th>Journal</th>
                                        <th>Referance No</th>
                                        <th>Status</th>
                                        <th>Amount</th>
                                        <th>Credited By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((data, i) => (
                                        <tr key={i}>
                                            <td className="d-flex">
                                                <label className="custom-control custom-checkbox mb-0">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                    />
                                                    <span className="custom-control-label">
                                                        &nbsp;
                                                    </span>
                                                </label>
                                                <span>
                                                    <Link to={`/accounts/journal/${data.Journal_No}`} className="invo-txt">
                                                        {new Date(
                                                            data.Journal_Date
                                                        ).toLocaleDateString(
                                                            "en-GB"
                                                        ).replace(/\//g, '-')}
                                                    </Link>
                                                </span>
                                            </td>
                                            <td>{data.Journal_No}</td>
                                            <td>{data.Journal_Ref}</td>
                                            <td>{data.Journal_Status == 1 ? "Published" : "Draft"}</td>
                                            <td>{data.AcJourDetails.map(item => parseFloat(item.JDet_Debit)).reduce((prev, next) => prev + next)}</td>
                                            <td>{data.User ? data.User.User_Name : ''}</td>
                                        </tr>
                                    ))}
                                    {data.length == 0 && (<tr><td colSpan={6}><p className="text-center pt-4">No Data Found</p></td></tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                title="FILTER"
                itemwidth="modal-sm"
                isOpen={modalOpen}
                onClose={closeModal}
            >
                <div className="modal-body">
                    <div className="row">
                        <div className="col-12">
                            <label className="form-label-small w-100">
                                Status
                            </label>
                            <div className="form-group">
                                <Select options={vrStatus} value={dataStatus} styles={selectStyle} onChange={(val)=>setDataStatus(val)} />
                            </div>
                        </div>
                        <div className="col-12">
                            <label className="form-label-small w-100">
                                Date
                            </label>
                            <div className="form-group">
                                <DatePicker className="form-control" 
                                    selectsRange={true}
                                    dateFormat="dd-MM-yyyy"
                                    startDate={startDate} endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }} isClearable={true} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={()=>{
                        fetchData();
                        closeModal()
                    }}>
                        Search
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default ManualJournal;
