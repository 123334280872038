import React, { useState } from "react";

function VariantCard(props) {

    const { variant, data } = props;
    let tracking = data.Item_Tracking;

    const [view, setView] = useState(true);

    return <div className="bg-white col-12 border-right shadow-sm">
        <div className={`card ${!view && 'card-collapsed'}`}>
            <div className="card-header pt-3">
                <p className="card-title mr-4 font-weight-bold"><i className="fa fa-long-arrow-right text-red varient-button pr-2"></i> {variant.Variant_Name}</p>
                <div className="card-options">
                    <a className="c-pointer"><i className="fe fe-more-vertical"></i></a>
                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                        <a className="dropdown-item c-pointer"> Enable</a>
                        <a className="dropdown-item c-pointer"> Print Barcode</a>
                    </div>
                    <a className="c-pointer card-options-collapse" onClick={()=>setView(!view)}><i className="fe fe-chevron-up"></i></a>
                </div>
            </div>
            <div className="card-body px-1 py-0 my-0 border-top">
                <div className="row">
                    <div className="col-md-4 px-0">
                        <div className="d-flex">
                            <div className="col border-right pt-2 text-center">
                                <small className="mb-0">Total Qty</small>
                                <h4 className="font-30 font-weight-bold text-col-blue counter">{variant.quantity.total}</h4>
                            </div>
                            <div className="col border-right text-center pt-2">
                                <small className="mb-0">Store</small>
                                <h4 className="font-30 font-weight-bold text-col-blue counter">{variant.quantity[0]}</h4>
                            </div>
                            <div className="col border-right text-center pt-2">
                                <small className="mb-0">Catalogue</small>
                                <h4 className="font-30 font-weight-bold text-col-blue counter" data-toggle="tooltip" data-placement="top" data-html="true" title="">{parseFloat(variant.quantity.total) - parseFloat(variant.quantity[0])}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 px-0">
                        <div className="table-responsive ast-table">
                            <table className="table table-vcenter text-nowrap mb-1">
                                <thead className="bg-light">
                                    <tr>
                                        <th className="pt-2">SKU</th>
                                        <th className="pt-2">Threshold</th>
                                        <th className="pt-2">Properties</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{variant.Variant_Code}</td>
                                        <td>{parseFloat(variant.Variant_Threshold)}</td>
                                        <td>-</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
                {variant.dataStock ? variant.dataStock.pricing.length > 0 && (<>
                <div className="bg-light mx-0">
                    <p className="ml-3 py-2 font-weight-bold"><i className="fa fa-angle-right pr-2 text-blue"></i> Channel Pricing</p>
                </div>
                <div className="table-responsive ast-table mt-2">
                    <table className="table table-vcenter text-nowrap">
                        <thead>
                            <th>Batch No</th>
                            <th>Channel</th>
                            <th>Tax Classes</th>
                            <th>Charges</th>
                            <th>Selling Price</th>
                        </thead>
                        <tbody>
                            {variant.dataStock.pricing.map((ch, i)=>(
                            <tr key={ch.ItemCh_Id}>
                                <td>{(tracking == 2 || tracking == 1) ? 
                                (variant.dataStock.ItemS_BatchNo ? variant.dataStock.ItemS_BatchNo : (variant.dataStock.ItemS_BatchNo ? variant.dataStock.ItemS_BatchNo : '-'))
                                : 'Default'}</td>
                                <td>{ch.bchannel.title}</td>
                                <td>{ch.taxclass ? ch.taxclass.title : '-'}</td>
                                <td>{ch.charges ? ch.charges.title : '-'}</td>
                                <td>{parseFloat(ch.ItemC_Price)}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                </>) : null}                                

                <div className="bg-light mx-0 d-flex justify-content-between pb-0 mb-0">
                    <p className="ml-3 pb-0 pt-2 mb-2 font-weight-bold"><i className="fa fa-angle-right pr-2 text-blue"></i> Purchase & Sales</p>                        
                    <span className="input-group-append">
                        <a href="#" className="btn c-blue text-white px-3">TRACK INVENTORY <i className="fe fe-external-link pl-1"></i></a>
                    </span>
                </div>
                <div className="table-responsive ast-table mt-2">
                    <table className="table table-vcenter text-nowrap">
                        <thead>
                            <tr>
                                <th>UPC</th>
                                <th><span>Cost Price</span></th>
                                <th><span>MRP</span></th>
                                <th><span>Total Qty</span></th>
                                <th><span>Stock in Store</span></th>
                                <th><span>Stock in Catalogue</span></th>
                                {tracking == 1 ? 
                                <><th>Batch Number</th><th>Mfg. Date</th><th>Exp. Date</th></> : 
                                (tracking == 2 ? <th>Serial No</th> : null)}
                            </tr>
                        </thead>
                        <tbody>
                            {variant.dataStock ? (<tr>
                                <td>{variant.dataStock.ItemS_UPC}</td>
                                <td>{parseFloat(variant.dataStock.ItemS_Cost)}</td>
                                <td>{parseFloat(variant.dataStock.ItemS_MRP)}</td>
                                <td>{parseFloat(variant.dataStock.ItemS_Available) + parseFloat(variant.dataStock.stkCatalogue)}</td>
                                <td>{parseFloat(variant.dataStock.ItemS_Available)}</td>
                                <td>{parseFloat(variant.dataStock.stkCatalogue)}</td>
                                {tracking == 1 ? 
                                <><td>Batch Number</td><td>Mfg. Date</td><td>Exp. Date</td></> : 
                                (tracking == 2 ? <td>Serial No</td> : null)
                                }
                            </tr>) : (<tr><td><p className="text-center pt-3 text-gray">No Data Found.</p></td></tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>;
}

export default VariantCard;
