import React from "react";
import { Link } from "react-router-dom";

function TabPODeleted() {
    return (
        <>
            <div className="row border-bottom">
                <div className="col col-sm-8 input-group d-flex justify-content-start">
                    <div className="col-md-3 col-11 px-0 input-icon table-head-search">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                        />
                        <span className="input-icon-addon">
                            <i className="fe fe-search"></i>
                        </span>
                    </div>
                    <span className="input-group-append dropdown">
                        <button
                            className="btn c-lgt-blue text-white"
                            type="button"
                            data-toggle="dropdown"
                        >
                            <i className="fe fe-sliders"></i>
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                <a className="dropdown-item" href="#">
                                    <i className="dropdown-icon fe fe-printer"></i>{" "}
                                    Print
                                </a>
                            </div>
                        </button>
                    </span>
                    <span className="input-group-append">
                        <Link to="/inventory/purchase-order/create-po" className="btn c-red text-white">
                            <i className="fe fe-plus"></i> New PO
                        </Link>
                    </span>
                    <span className="input-group-append">
                        <Link to="/inventory/purchase/create-grn" className="btn c-blue text-white">
                            <i className="fe fe-plus"></i> New Bill
                        </Link>
                    </span>
                    <span className="input-group-append dropdown">
                        <button
                            className="btn bg-white"
                            type="button"
                            data-toggle="dropdown"
                        >
                            <i className="fe fe-download"></i>
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                <a className="dropdown-item" href="#">
                                    <i className="dropdown-icon fa fa-file-excel-o"></i>{" "}
                                    Download Excel
                                </a>
                                <a className="dropdown-item" href="#">
                                    <i className="dropdown-icon fa fa-file-pdf-o"></i>{" "}
                                    Download PDF
                                </a>
                            </div>
                        </button>
                    </span>
                </div>
                <div className="col-2 col-sm-4 d-flex justify-content-end mb-1 table-nav mt-1">
                    <a href="#">
                        <i className="fe fe-chevron-left pr-sm-1 pr-2"></i>
                    </a>
                    <span className="px-1 d-none d-sm-block">01-50 of 250</span>
                    <a href="#">
                        <i className="fe fe-chevron-right pl-sm-1 pl-2"></i>
                    </a>
                </div>
            </div>
            <div className="card">
                <div className="card-body px-1">
                    <div className="table-responsive ast-table">
                        <table className="table table-striped table-vcenter mb-0 text-nowrap">
                            <thead>
                                <tr>
                                    <th>
                                        <span className="table-checkbox-head">
                                            &nbsp;
                                        </span>{" "}
                                        PO ID
                                    </th>
                                    <th>Vendor</th>
                                    <th>Status</th>
                                    <th>Delete Date</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody></tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TabPODeleted;
