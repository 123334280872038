import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { axiosInstance } from "../../../config/axios";
import { selectStyle, reportFilter } from '../../../config/constants'
import "react-datepicker/dist/react-datepicker.css";

function ReportProfitLoss() {

    const [filter, setFilter] = useState({
        duration: { value: 'this-month', label: 'This Month'},
        startDate: null,
        endDate: null,
    })
    const [activeDate, setActiveDate] = useState({
        startDate: null,
        endDate: null
    })
    const [data, setData] = useState({
        income: [],
        costgoods: [],
        expense: [],
        nonincome: [],
        nonexpense: [],
        totIncome: 0,
        totCost: 0,
        totExpense: 0,
        totNonIncome: 0,
        totNonExpense: 0,
        grossAmnt: 0,
        opAmnt: 0,
        netAmnt: 0,
    });

    const fetchData = () => {
        axiosInstance.post("/reports/accounts/profit-loss", filter)
        .then(function (response) {
            if(response.data.status == 'success') {
                let vrTotal = {
                    totIncome: response.data.vrIncome.reduce((total, obj)=> total += obj.total, 0),
                    totCost: response.data.vrGoods.reduce((total, obj)=> total += obj.total, 0),
                    totExpense: response.data.vrExpense.reduce((total, obj)=> total += obj.total, 0),
                    totNonIncome: response.data.vrNonIncome.reduce((total, obj)=> total += obj.total, 0),
                    totNonExpense: response.data.vrNonExpense.reduce((total, obj)=> total += obj.total, 0)
                }
                setData({
                    income: response.data.vrIncome,
                    costgoods: response.data.vrGoods,
                    expense: response.data.vrExpense,
                    nonincome: response.data.vrNonIncome,
                    nonexpense: response.data.vrNonExpense,
                    totIncome: vrTotal.totIncome,
                    totCost: vrTotal.totCost,
                    totExpense: vrTotal.totExpense,
                    totNonIncome: vrTotal.totNonIncome,
                    totNonExpense: vrTotal.totNonExpense,
                    grossAmnt: vrTotal.totIncome - vrTotal.totCost,
                    opAmnt: (vrTotal.totIncome - vrTotal.totCost) - vrTotal.totExpense,
                    netAmnt: ((vrTotal.totIncome - vrTotal.totCost) - vrTotal.totExpense) + (vrTotal.totNonIncome - vrTotal.totNonExpense),
                });
                setActiveDate({
                    startDate: response.data.startDate,
                    endDate: response.data.endDate
                });
            }            
        }).catch(err=>console.log(err));
    };

    useEffect(() => {
        fetchData();
    }, [filter]);

    const history = useHistory();
    const openAccntTrans = (accntId) => {
        const qp = new URLSearchParams();
        qp.append('account', accntId);
        qp.append('start', activeDate.startDate);
        qp.append('end', activeDate.endDate);
        history.push(`/reports/accounts/general-ledger-info?${qp.toString()}`);
    }

    return <>
        <div className="row">
            <div className="col col-md-12 input-group d-flex justify-content-start">
                <div className="col-md-4 col-11 px-0 input-icon table-head-search py-0">
                    <Select
                        placeholder="Select"
                        options={reportFilter}
                        styles={selectStyle}
                        value={filter.duration}
                        onChange={(val)=>{
                            setFilter({...filter, ...{duration: val, startDate: null, endDate: null}})
                        }}
                    />
                </div>
                <div className="col-md-4 col-11 px-0 input-icon table-head-search py-0 pl-1">
                    <DatePicker className="form-control" 
                    selectsRange={true}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Date"
                    startDate={filter.startDate} endDate={filter.endDate}
                    onChange={(update) => {
                        setFilter({...filter, ...{ startDate:update[0], endDate:update[1]}})
                    }} isClearable={true} disabled={filter.duration.value == 'custom' ? false : true} />
                </div>
                <span className="input-group-append dropdown"><button className="btn c-lgt-blue text-white" type="button">Download &nbsp; <i className="fe fe-download"></i></button></span>
            </div>
        </div>
        <div className="card">
            <div className="card-status c-blue top-layer"></div>
            <div className="card-body px-1 h-80-scroll">
                <div className="table-responsive ast-table h-100">
                    <table className="table table-vcenter mb-0 text-nowrap">
                        <thead>
                            <tr>
                                <th>Account</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="tbl-grey-head">
                                <td colSpan={2}>Operating Income</td>
                            </tr>
                            {data.income.map((item, i)=>(
                            <tr>
                                <td className="c-pointer" onClick={()=>openAccntTrans(item.accntId)}>{item.account}</td>
                                <td>{item.total}</td>
                            </tr>
                            ))}
                            <tr className="tbl-sub">
                                <td>Total for Operating Income</td>
                                <td>{data.totIncome}</td>
                            </tr>

                            <tr className="tbl-grey-head">
                                <td colSpan={2}>Cost of Goods Sold</td>
                            </tr>
                            {data.costgoods.map((item, i)=>(
                            <tr>
                                <td className="c-pointer" onClick={()=>openAccntTrans(item.accntId)}>{item.account}</td>
                                <td>{item.total}</td>
                            </tr>
                            ))}
                            <tr className="tbl-sub">
                                <td>Total for Cost of Goods Sold</td>
                                <td>{data.totCost}</td>
                            </tr>
                            <tr className="tbl-sub">
                                <td className="text-right">Gross Profit</td>
                                <td>{data.grossAmnt}</td>
                            </tr>

                            <tr className="tbl-grey-head">
                                <td colSpan={2}>Operating Expense</td>
                            </tr>
                            {data.expense.map((item, i)=>(
                            <tr>
                                <td className="c-pointer" onClick={()=>openAccntTrans(item.accntId)}>{item.account}</td>
                                <td>{item.total}</td>
                            </tr>
                            ))}
                            <tr className="tbl-sub">
                                <td>Total for Operating Expense</td>
                                <td>{data.totExpense}</td>
                            </tr>

                            <tr className="tbl-sub">
                                <td className="text-right">Operating Profit	</td>
                                <td>{data.opAmnt}</td>
                            </tr>

                            <tr className="tbl-grey-head">
                                <td colSpan={2}>Non Operating Income</td>
                            </tr>
                            {data.nonincome.map((item, i)=>(
                            <tr>
                                <td className="c-pointer" onClick={()=>openAccntTrans(item.accntId)}>{item.account}</td>
                                <td>{item.total}</td>
                            </tr>
                            ))}
                            <tr className="tbl-sub">
                                <td>Total for Non Operating Income</td>
                                <td>{data.totNonIncome}</td>
                            </tr>

                            <tr className="tbl-grey-head">
                                <td colSpan={2}>Non Operating Expense</td>
                            </tr>
                            {data.nonexpense.map((item, i)=>(
                            <tr>
                                <td className="c-pointer" onClick={()=>openAccntTrans(item.accntId)}>{item.account}</td>
                                <td>{item.total}</td>
                            </tr>
                            ))}
                            <tr className="tbl-sub">
                                <td>Total for Non Operating Expense</td>
                                <td>{data.totNonExpense}</td>
                            </tr>

                            <tr className="tbl-sub">
                                <td className="text-right">Net Profit/Loss</td>
                                <td>{data.netAmnt}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>;
}

export default ReportProfitLoss;
