import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "../../Modal";
import { axiosInstance } from "../../../../config/axios";

function AuditLog() {

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const [mdlLog, setMdlLog] = useState(false);
    const [activeData, setActiveData] = useState([]);

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({ pageNo: 0, noOfPages: 0, paging: "0-0 of 0" });

    const fetchData = () => {
        axiosInstance.post("/inventory/audit/log", {
            startDate: startDate ? (new Date(startDate).toLocaleDateString("en-GB")).replace(/\//g, '-') : null,
            endDate: endDate ? (new Date(endDate).toLocaleDateString("en-GB")).replace(/\//g, '-') : null,
            pageNo: pagination.pageNo,
        }).then(function (response) {
            if(response.data.status === 'success') {
                setPagination({...pagination, ...{
                    noOfPages: response.data.data.noofpages,
                    paging: `${response.data.data.offset}-${response.data.data.limit} of ${response.data.data.count}`
                }});
                setData(response.data.data.rows);
            }
        }).catch(function (error) {
            console.log(error);
        });
    };

    useEffect(() => {
        fetchData();
    }, [dateRange, pagination.pageNo]);

    return <div>
        <div className="row">
            <div className="col col-sm-8 input-group d-flex justify-content-start">
                <div className="col-md-4 col-11 px-0 input-icon table-head-search">
                    <DatePicker className="form-control" 
                        selectsRange={true}
                        dateFormat="dd-MM-yyyy"
                        placeholderText="Select Date"
                        startDate={startDate} endDate={endDate}
                        onChange={(update) => {
                            setDateRange(update);
                        }} isClearable={true} />
                </div>
            </div>
            <div className="col-2 col-sm-4 d-flex justify-content-end mb-1 table-nav">
                <a href="#" onClick={(e) => {
                    e.preventDefault();
                    if (pagination.pageNo > 0) setPagination({...pagination, pageNo:--pagination.pageNo})
                }}>
                    <i className="fe fe-chevron-left pr-sm-1 pr-2"></i>
                </a>
                <span className="px-1 d-none d-sm-block">
                    {pagination.paging}
                </span>
                <a href="#" onClick={(e) => {
                    e.preventDefault();
                    if (pagination.pageNo < pagination.noOfPages) setPagination({...pagination, pageNo:++pagination.pageNo})
                }}>
                    <i className="fe fe-chevron-right pl-sm-1 pl-2"></i>
                </a>
            </div>
        </div>
        <div className="card">
            <div className="card-body px-1">
                <div className="table-responsive ast-table">
                    <table className="table table-striped table-vcenter mb-0 text-nowrap">
                        <thead>
                            <tr>
                                <th className="col-2">User</th>
                                <th className="col-2">Date</th>
                                <th className="col">Log</th>
                                <th className="col">Comments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((itm, i)=>{
                                return <tr key={i}>
                                    <td>{itm.User_Name}</td>
                                    <td>{itm.Audit_Date}</td>
                                    <td><a href="#" className="" onClick={()=>{
                                        setMdlLog(true);
                                        setActiveData(itm.items);
                                    }}>{`${itm.items.length} Items updated`} <i className="fe fe-external-link pl-2"></i></a></td>
                                    <td>{itm.Audit_Remarks}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <Modal title="ITEMS" itemwidth="modal-xl" isOpen={mdlLog} onClose={()=>setMdlLog(false)}>
            <div className="modal-body">
                <div className="row">
                    <div className="table-responsive ast-table">
                        <table className="table table-striped table-vcenter mb-0 text-nowrap">
                            <thead>
                                <tr>
                                    <th className="col-2">Variant</th>
                                    <th className="col">UPC</th>
                                    <th className="col">Expected Qty</th>
                                    <th className="col">Actual Qty</th>
                                    <th className="col">Updated Qty</th>
                                    <th className="col">Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                {activeData.map((itm, i)=>{
                                    return <tr key={i}>
                                        <td>{itm.variant}</td>
                                        <td>{itm.upc}</td>
                                        <td>{parseFloat(itm.expected).toFixed(2)}</td>
                                        <td>{parseFloat(itm.actual).toFixed(2)}</td>
                                        <td>{parseFloat(itm.updated).toFixed(2)}</td>
                                        <td>{itm.reason}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Modal>
    </div>;
}

export default AuditLog;
