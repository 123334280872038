import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { axiosInstance } from "../../../../config/axios";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function UserInfo() {

    const { id } = useParams();
    const history = useHistory();

    const [data, setData] = useState({});

    useEffect(() => {
        axiosInstance
            .get(`/people/user/user-details?id=${id}`)
            .then(function (res) {
                if (res.data.status === "success") {
                    setData(res.data.data);
                } else {
                    history.push('/people/user')
                }
            }).catch((e) => history.push('/people/user'));
    }, []);

    return <>
        {data.User_Id && <div className="section-body content-area">
            <div className="container-fluid">
                <div className="row border-bottom">
                    <div className="col col-sm-8 input-group d-flex justify-content-start">
                        <span className="input-group-append"><Link to={`/people/user/update-user/${data.User_Id}`} className="btn c-red text-white px-4"> Edit User</Link></span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0">User</h6>
                            </div>
                            <div className="card-body pt-2 pb-0">
                                <div className="table-responsive">
                                    <table className="table table-borderless table-vcenter">
                                        <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td>{data.User_Name}</td>
                                            </tr>
                                            <tr>
                                                <td>Gender</td>
                                                <td>{data.User_Gender ? 'Male' : 'Female'}</td>
                                            </tr>
                                            <tr>
                                                <td>Pan No</td>
                                                <td>{data.User_PAN}</td>
                                            </tr>
                                            <tr>
                                                <td>Phone No</td>
                                                <td>{data.User_Phone}</td>
                                            </tr>
                                            <tr>
                                                <td>Email ID</td>
                                                <td>{data.User_Username}</td>
                                            </tr>
                                            <tr>
                                                <td>Address</td>
                                                <td>{data.User_Address}</td>
                                            </tr>
                                            <tr>
                                                <td>City</td>
                                                <td>{data.User_City}</td>
                                            </tr>
                                            <tr>
                                                <td>State</td>
                                                <td>{data.User_State}</td>
                                            </tr>
                                            <tr>
                                                <td>Country</td>
                                                <td>{data.User_Country}</td>
                                            </tr>
                                            <tr>
                                                <td>ZIP Code</td>
                                                <td>{data.User_ZIP}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0">Details</h6>
                            </div>
                            <div className="card-body pt-2 pb-0">
                                <div className="table-responsive">
                                    <table className="table table-borderless table-vcenter">
                                        <tbody>
                                            <tr>
                                                <td>Role</td>
                                                <td>{data.userrole ? data.userrole.Role_Title : '-'}</td>
                                            </tr>
                                            <tr>
                                                <td>Web Access</td>
                                                <td>
                                                {data.webaccess.map((row, i)=>(
                                                    row.branch ? row.branch.Branch_Name + ', ' : ''
                                                ))}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>POS Access</td>
                                                <td>
                                                {data.posaccess.map((row, i)=>(
                                                    row.branch ? row.branch.Branch_Name + ', ' : ''
                                                ))}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header border-bottom pt-3">
                                <h6 className="p-0 m-0">Settings</h6>
                            </div>
                            <div className="card-body pt-2 pb-0">
                                <div className="table-responsive">
                                    <table className="table table-borderless table-vcenter">
                                        <tbody>
                                            <tr>
                                                <td>Device Activation Access</td>
                                                <td>{data.Device_Activation == 1 ? <><i className="fa fa-check pr-1 green"></i> Yes</> : <><i className="fa fa-close pr-1 red"></i> No</>}</td>
                                            </tr>
                                            <tr>
                                                <td>Daily Email Report</td>
                                                <td>{data.Daily_Email_Report == 1 ? <><i className="fa fa-check pr-1 green"></i> Yes</> : <><i className="fa fa-close pr-1 red"></i> No</>}</td>
                                            </tr>
                                            <tr>
                                                <td>Daily SMS Report</td>
                                                <td>{data.Daily_SMS_Report == 1 ? <><i className="fa fa-check pr-1 green"></i> Yes</> : <><i className="fa fa-close pr-1 red"></i> No</>}</td>
                                            </tr>
                                            <tr>
                                                <td>Status</td>
                                                <td>{data.Is_Active == 1 ? <><i className="fa fa-check pr-1 green"></i> Yes</> : <><i className="fa fa-close pr-1 red"></i> No</>}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
    </>;
}

export default UserInfo;
