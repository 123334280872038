import React, { useState, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { axiosInstance } from "../../../config/axios";
import { selectStyle, reportFilter } from '../../../config/constants'
import "react-datepicker/dist/react-datepicker.css";

function ReportAccTypeSummary() {

    const [filter, setFilter] = useState({
        duration: { value: 'this-month', label: 'This Month'},
        startDate: null,
        endDate: null,
    })

    const [data, setData] = useState([]);

    const fetchData = () => {
        axiosInstance.post("/reports/accounts/account-type-summary", filter)
        .then(function (response) {
            if(response.data.status == 'success')
            setData(response.data.data);
        }).catch(err=>console.log(err));
    };

    useEffect(() => {
        fetchData();
    }, [filter]);

    return <>
        <div className="row">
            <div className="col col-md-12 input-group d-flex justify-content-start">
                <div className="col-md-4 col-11 px-0 input-icon table-head-search py-0">
                    <Select
                        placeholder="Select"
                        options={reportFilter}
                        styles={selectStyle}
                        value={filter.duration}
                        onChange={(val)=>{
                            setFilter({...filter, ...{duration: val, startDate: null, endDate: null}})
                        }}
                    />
                </div>
                <div className="col-md-4 col-11 px-0 input-icon table-head-search py-0 pl-1">
                    <DatePicker className="form-control" 
                    selectsRange={true}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Date"
                    startDate={filter.startDate} endDate={filter.endDate}
                    onChange={(update) => {
                        setFilter({...filter, ...{ startDate:update[0], endDate:update[1]}})
                    }} isClearable={true} disabled={filter.duration.value == 'custom' ? false : true} />
                </div>
                <span className="input-group-append dropdown"><button className="btn c-lgt-blue text-white" type="button">Download &nbsp; <i className="fe fe-download"></i></button></span>
            </div>
        </div>
        <div className="card">
            <div className="card-status c-blue top-layer"></div>
            <div className="card-body px-1 h-80-scroll">
                <div className="table-responsive ast-table h-100">
                    <table className="table table-vcenter mb-0 text-nowrap">
                        <thead>
                            <tr>
                                <th className="col">Account Type</th>
                                <th className="col-2">Debit</th>
                                <th className="col-2">Credit</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.map((item, i)=>(
                            <>
                            <tr className="tbl-grey-head" key={i}>
                                <td colSpan={3}>{item.AcMaster_Name}</td>
                            </tr>
                            {item.types.map((type, j)=>(
                                <tr key={i +'itm'+ j}>
                                    <td>{type.AcType_Name}</td>
                                    <td>{type.accounts.reduce((total, itm) => {
                                            return total + parseFloat(itm.Accnt_Debit);
                                        }, 0)}</td>
                                    <td>{type.accounts.reduce((total, itm) => {
                                            return total + parseFloat(itm.Accnt_Credit);
                                        }, 0)}</td>
                                </tr>
                            ))}
                            </>
                        ))}
                        {data.length <= 0 && <tr><td colSpan="3"><p className="w-100 text-center pt-5">No Data Found</p></td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>;
}

export default ReportAccTypeSummary;
