import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../../../config/axios";

function BranchList() {

    const [data, setData] = useState([]);
    const [dataSearch, setDataSearch] = useState("");
    const fetchData = () => {
        axiosInstance.post("/settings/branch-list", {
            search: dataSearch
        }).then(function (response) {
            if(response.data.status === 'success')
            setData(response.data.data);
        }).catch(err => console.log(err));
    };

    useEffect(() => {
        fetchData();
    }, [dataSearch]);

    return (
        <div className="section-body content-area">
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-sm-8 input-group d-flex justify-content-start">
                        <div className="col-md-4 col-11 px-0 input-icon table-head-search">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                onChange={(e) => setDataSearch(e.target.value)}
                                value={dataSearch}
                            />
                            <span className="input-icon-addon">
                                <i className="fe fe-search"></i>
                            </span>
                        </div>
                        <span className="input-group-append">
                            <Link to="/settings/create-branch" className="btn c-red text-white"><i className="fe fe-plus"></i> New Branch
                            </Link>
                        </span>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body px-1">
                        <div className="table-responsive ast-table">
                            <table className="table table-striped table-vcenter mb-0 text-nowrap">
                                {data.length > 0 && (<thead>
                                    <tr>
                                        <th><span className="table-checkbox-head">&nbsp;</span>Branch Name</th>
                                        <th>Contact Phone</th>
                                        <th>Email</th>
                                        <th>Place</th>
                                    </tr>
                                </thead>)}
                                <tbody>
                                {data.map((itm, i) => (
                                    <tr key={i}>
                                        <td className="d-flex">
                                            <label className="custom-control custom-checkbox mb-0">
                                                <input type="checkbox" className="custom-control-input" />
                                                <span className="custom-control-label"> &nbsp; </span>
                                            </label>
                                            <span> <Link to={'/settings/branch-info/' + itm.Branch_Id} className="invo-txt">{itm.Branch_Name}</Link> </span>
                                        </td>
                                        <td>{itm.Branch_Phone}</td>
                                        <td>{itm.Branch_Email}</td>
                                        <td>{itm.Branch_Location}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            {data.length <= 0 && <p className="w-100 text-center pt-5">No Data Found</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BranchList;
