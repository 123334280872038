import React, { useState } from "react";
import { useSelector } from "react-redux";

import { axiosInstance } from "../../../../config/axios";
import { Modal, InputText } from '../../';

function TabGeneral({ data, updateData }) {

    const branchInfo = useSelector(store => store.branch);

    const allowCrLimit = (e) => {
        updateData({...data, Allow_Above_CreditLimit: e.target.checked ? 1 : 0})
        axiosInstance
        .post('/people/customer/allow-credit-limit', {
            custId: data.Cust_Id,
            credit: e.target.checked ? 1 : 0
        }).then(function (res) {
            if (res.data.status === 'success') {
                
            }
        }).catch(e => console.log(e));
    };

    const [loading, setLoading] = useState(false);
    const [mdlCredit, setMdlCredit] = useState(false);

    const saveCreditLimit = ()=> {
        setLoading(true);
        axiosInstance
        .post('/people/customer/update-credit-limit', {
            custId: data.Cust_Id,
            amount: data.tempLimit
        }).then(function (res) {
            if (res.data.status === 'success') {
                setLoading(false);
                setMdlCredit(false);
                updateData({...data, Cust_CreditLimit: data.tempLimit});
            }
        }).catch(e => console.log(e));
    };

    return (<>
        <div className="card">
            <div className="card-body pb-0">
                <div className="row">
                    <div className="col-12">
                        <h6 className="font-weight-bold pl-0 pb-3 border-bottom">Customer Info</h6>
                    </div>
                    <div className="col-6">
                        <div className="table-responsive table-borderless">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>Name</td>
                                        <td>{data.Cust_Name ? data.Cust_Name : 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td>Gender</td>
                                        <td>{data.Cust_Gender == 1 ? 'Male' : (data.Cust_Gender == 0 ? 'Female' : 'N/A')}</td>
                                    </tr>
                                    <tr>
                                        <td>Phone</td>
                                        <td>{data.phone ? data.phone.Cust_STD + ' ' + data.phone.Cust_Phone : 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>{data.Cust_Email ? data.Cust_Email : 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td>Billing Address</td>
                                        <td>
                                            {data.Cust_Address1 ? data.Cust_Address1 + <br /> : null}
                                            {data.Cust_Address2 ? data.Cust_Address2 + <br /> : null}
                                            {data.state ? data.state.title + ', ' : null}
                                            {data.district ? data.district.title + ', ' : null}
                                            {data.area ? data.area.title + ', ' : null}
                                            {data.Cust_PIN}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Delivery Address</td>
                                        <td>
                                            {data.CustDel_Address1 ? data.CustDel_Address1 + <br /> : null}
                                            {data.CustDel_Address2 ? data.CustDel_Address2 + <br /> : null}
                                            {data.delstate ? data.delstate.title + ', ' : null}
                                            {data.deldistrict ? data.deldistrict.title + ', ' : null}
                                            {data.delarea ? data.delarea.title + ', ' : null}
                                            {data.CustDel_PIN}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="table-responsive table-borderless">
                            <table className="table">
                                <tbody>
                                    {data.Cust_GSTIN && <tr><td>GSTIN / VAT</td><td>{data.Cust_GSTIN}</td></tr>}
                                    {data.Cust_PAN && <tr><td>PAN</td><td>{data.Cust_PAN}</td></tr>}
                                    {data.Cust_Business && <tr><td>Business Type</td><td>{data.Cust_Business}</td></tr>}
                                    {data.group && <tr><td>Customer Group</td><td>{data.group.title}</td></tr>}
                                    {data.Cust_Desc && <tr><td>Description</td><td>{data.Cust_Desc}</td></tr>}
                                    {data.Cust_DOB && <tr><td>Birthday</td><td>{data.Cust_DOB}</td></tr>}
                                    {data.Cust_Anniversary && <tr><td>Anniversary</td><td>{data.Cust_Anniversary}</td></tr>}
                                    <tr>
                                        <td>Credit Limit</td>
                                        <td>
                                            {data.Cust_CreditLimit ? parseFloat(data.Cust_CreditLimit).toFixed(branchInfo.Branch_DecimalPoints) : parseFloat(branchInfo.Cust_CreditLimit).toFixed(branchInfo.Branch_DecimalPoints)}
                                            <a href="#" onClick={(e)=>{
                                                e.preventDefault();
                                                setMdlCredit(true);
                                            }} className="pl-2">
                                                <i className="fe fe-external-link"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Stop credit if credit limit exeeded
                                        </td>
                                        <td>
                                            <label className="custom-switch d-flex justify-content-between">
                                                <span className="text-right">
                                                    <input type="checkbox" className="custom-switch-input" checked={data.Allow_Above_CreditLimit ? true : false} onChange={allowCrLimit} />
                                                    <span className="custom-switch-indicator"></span>
                                                </span>
                                            </label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal title="CREDIT LIMIT" itemwidth="modal-sm" isOpen={mdlCredit} onClose={()=>setMdlCredit(false)}>
            <div className="modal-body">
                <div className="row">
                    <InputText field="number" wrapper="col-12" title="AMOUNT" required={true} value={data.tempLimit ? parseFloat(data.tempLimit) : ''} onChange={(e)=>{
                        updateData({...data, tempLimit: e.target.value});
                    }} error="" />
                </div>
            </div>
            <div className="modal-footer">
                {loading ? <button type="button" className="btn btn-danger font-weight-bold px-4">Please Wait...</button> : <button type="button" className="btn btn-danger font-weight-bold px-4" onClick={()=>saveCreditLimit()}>UPDATE</button>}                
            </div>
        </Modal>
    </>);
}

export default TabGeneral;
