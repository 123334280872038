import React, { useState, useEffect } from "react";
import { useHistory, useParams  } from 'react-router-dom'
import Select from "react-select";
import { selectStyle } from '../../../config/constants';
import { axiosInstance } from "../../../config/axios";
import Modal from "../Modal";

function UpdateJournal() {

    const [modalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const history = useHistory();
    const { slug } = useParams();
    
    const defLstItm = {
        itemId: 0,
        account: null,
        description: "",
        contact: null,
        debit: 0,
        credit: 0,
    };
    const initialVal = {
        dataId: 0,
        dataDate: new Date().toISOString().slice(0, 10),
        journalNo: {
            prefix: '',
            nextNo: ''
        },
        dataRef: "",
        dataNotes: "",
        listItem: [defLstItm, defLstItm],
    };
    const [formData, setFormData] = useState(initialVal);
    const onChange = (e) => {
        setError({...error, [e.target.name]: null})
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const itemOnChange = (i, e) => {
        var newArray = formData.listItem.map((obj, index) =>
            index === i ? { ...obj, [e.target.name]: e.target.value } : obj
        );
        setFormData({ ...formData, listItem: newArray });
    };
    const itemAmntChange = (i, e) => {
        setError({...error, listitm: null})
        var newArray = formData.listItem.map((obj, index) =>
            index === i ? { ...obj, [e.target.name]: (e.target.value === '' ? 0 : e.target.value) } : obj
        );
        setFormData({ ...formData, listItem: newArray });
    };
    const onselectionchange = (i, val, col) => {
        setError({...error, listitm: null})
        var newArray = formData.listItem.map((obj, index) =>
            index === i ? { ...obj, [col]: val } : obj
        );
        setFormData({ ...formData, listItem: newArray });
    };
    const removeItem = (i) => {
        setError({...error, listitm: null})
        var tempData = formData.listItem;
        tempData.splice(i, 1);
        setFormData({ ...formData, listItem: tempData });
    };

    const [jourNo, setJourNo] = useState({prefix: '', nextNo: ''})
    const [people, setPeople] = useState([]);
    const [accounts, setAccounts] = useState([]);
    useEffect(() => {
        axiosInstance
            .get(`/accounts/journal-form?id=${slug ? slug : ''}`)
            .then(function (response) {
                if (response.data.status === "success") {
                    let tempData = [];
                    response.data.data.accounts.forEach((item) => {
                        tempData.push({
                            label: item.label,
                            options: item.Accounts,
                        });
                    });
                    setAccounts(tempData);
                    setPeople(response.data.data.people);
                    setJourNo(response.data.data.journalNo);
                    if(response.data.data.activeData)
                        setFormData(response.data.data.activeData)
                    else
                        setFormData({...formData, journalNo: response.data.data.journalNo})
                }
            })
            .catch((err) => console.error(err.message));
    }, []);

    const [userAlert, setUserAlert] = useState({})
    const [loading, setIsLoading] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)
    const [error, setError] = useState([])
    const validate = (val) => {
        const err = {}

        if (!val.dataDate) {
            err.dataDate = 'Please select date'
        } if (!val.dataNotes) {
            err.dataNotes = 'Please enter notes'
        }

        val.listItem.forEach(item => {
            if(!item.account) {
                err.listitm = 'Please enter account transactions'
            } if(!item.debit && !item.credit) {
                err.listitm = 'Please enter account transactions'
            } else if (item.debit > 0 && item.credit > 0) {
                err.listitm = 'Please enter account transactions'
            }
        });

        var dr = formData.listItem.reduce((sum, obj) => sum + (isNaN(obj.debit) ? 0 : parseFloat(obj.debit)), 0);
        var cr = formData.listItem.reduce((sum, obj) => sum + (isNaN(obj.credit) ? 0 : parseFloat(obj.credit)), 0);
        if(dr != cr) err.listitm = 'Please ensure that the Debits and Credits are equal';
        
        return err
    }

    useEffect(() => {
        if (Object.keys(error).length === 0 && isSubmit) {
            setIsLoading(true)
            axiosInstance.post('/accounts/journal-entry', formData)
            .then(function (response) {
                setIsLoading(false)
                setIsSubmit(false)
                if(response.data.status === 'success') {
                    history.push('/accounts/manual-journals');
                }
                setUserAlert(response.data);
            }).catch(err=>console.log(err));
        }
        // eslint-disable-next-line
    }, [error])

    useEffect(() => {
        setIsSubmit(false)
    }, [formData])

    return (
        <>
            <div className="section-body">
                <div className="container-fluid">
                    <div className="d-flex justify-content-start">
                        <span className="input-group-append">
                            {
                                loading ? (formData.status == 1 ? (<button type="button" className="btn c-blue text-white px-5 font-weight-bold text-uppercase">Please wait...</button>) : (<button type="button" className="btn c-blue text-white px-5 font-weight-bold text-uppercase" disabled>Publish</button>)) : (<button type="button" className="btn c-blue text-white px-5 font-weight-bold text-uppercase" onClick={()=>{
                                    setFormData({ ...formData, status: 1 });
                                    setIsSubmit(true)
                                    setError(validate(formData))
                                }}>Publish</button>)
                            }
                        </span>
                        <span className="input-group-append">
                            {
                                loading ? (formData.status == 0 ? (<button type="button" className="btn c-lgt-blue text-white px-5 font-weight-bold text-uppercase">Please wait...</button>) : (<button type="button" className="btn c-lgt-blue text-white px-5 font-weight-bold text-uppercase" disabled>Save To Draft</button>)) : (<button type="button" className="btn c-lgt-blue text-white px-5 font-weight-bold text-uppercase" onClick={()=>{
                                    setFormData({ ...formData, status: 0 });
                                    setIsSubmit(true)
                                    setError(validate(formData))
                                }}>Save To Draft</button>)
                            }
                        </span>
                    </div>
                    <div className="card bg-white">
                        <div className="card-body border-top">
                            <div className="row clearfix">
                                <div className="col-6 col-lg-4">
                                    <div className="form-group">
                                        <label className="form-label-small">
                                            Date <span className="red">*</span>
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={formData.dataDate}
                                            name="dataDate"
                                            onChange={onChange}
                                        />
                                        {error.dataDate && <small className="red mb-2">{error.dataDate}</small>}
                                    </div>
                                </div>
                                <div className="col-6 col-lg-4">
                                    <div className="form-group">
                                        <label className="form-label-small">
                                            Journal
                                        </label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={`${formData.journalNo.prefix ? formData.journalNo.prefix : ''}${formData.journalNo.nextNo}`}
                                                name="dataNo"
                                                readOnly
                                            />
                                            <span className="input-group-append">
                                                <button className="btn btn-primary" type="button" onClick={openModal}>
                                                    <i className="fe fe-edit-3"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-4">
                                    <div className="form-group">
                                        <label className="form-label-small">
                                            Referance No
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={formData.dataRef}
                                            name="dataRef"
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-label-small">
                                            Notes <span className="red">*</span>
                                        </label>
                                        <textarea
                                            rows="3"
                                            className="form-control"
                                            value={formData.dataNotes}
                                            name="dataNotes"
                                            onChange={onChange}
                                        ></textarea>
                                        {error.dataNotes && <small className="red mb-2">{error.dataNotes}</small>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card bg-white">
                        <div className="card-body">
                            <div className="table-responsive ast-table">
                                <table className="table table-striped table-vcenter mb-0 text-nowrap">
                                    <thead>
                                        <tr>
                                            <th className="col-3 pl-0">
                                                Account
                                            </th>
                                            <th className="col-2">
                                                Description
                                            </th>
                                            <th className="col-2">Contact</th>
                                            <th className="col-2">Debit</th>
                                            <th className="col-2">Credit</th>
                                            <th className="col-1">
                                                <span
                                                    onClick={() =>
                                                        setFormData({
                                                            ...formData,
                                                            listItem: [
                                                                ...formData.listItem,
                                                                defLstItm,
                                                            ],
                                                        })
                                                    }
                                                >
                                                    <i className="fe fe-plus-circle overlay-ico mr-1"></i>
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.listItem.map((item, i) => (
                                            <tr key={i}>
                                                <td className="pl-0">
                                                    <Select
                                                        options={accounts}
                                                        styles={selectStyle}
                                                        value={item.account}
                                                        onChange={(val) =>
                                                            onselectionchange(i, val, "account")
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="description"
                                                        onChange={(e) =>
                                                            itemOnChange(i, e)
                                                        }
                                                        value={item.description}
                                                    />
                                                </td>
                                                <td>
                                                    <Select
                                                        options={people}
                                                        styles={selectStyle}
                                                        value={item.contact}
                                                        onChange={(val) =>
                                                            onselectionchange(i, val, "contact")
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="debit"
                                                        onChange={(e) =>
                                                            itemAmntChange(i, e)
                                                        }
                                                        value={item.debit === 0 ? '' : item.debit}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="credit"
                                                        onChange={(e) =>
                                                            itemAmntChange(i, e)
                                                        }
                                                        value={item.credit === 0 ? '' : item.credit}
                                                    />
                                                </td>
                                                <td className="pr-0">
                                                    <a
                                                        href="#"
                                                        onClick={() =>
                                                            removeItem(i)
                                                        }
                                                    >
                                                        <i className="fe fe-minus-circle text-red"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}

                                        <tr>
                                            <td colSpan="3" className="text-right">
                                                Sub Total
                                            </td>
                                            <td className="text-right">
                                            {formData.listItem.reduce((sum, obj) => sum + (isNaN(obj.debit) ? 0 : parseFloat(obj.debit)), 0)}
                                            </td>
                                            <td className="text-right">
                                            {formData.listItem.reduce((sum, obj) => sum + (isNaN(obj.credit) ? 0 : parseFloat(obj.credit)), 0)}
                                            </td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3" className="text-right">
                                                Total
                                            </td>
                                            <td className="text-right">
                                            {formData.listItem.reduce((sum, obj) => sum + (isNaN(obj.debit) ? 0 : parseFloat(obj.debit)), 0)}
                                            </td>
                                            <td className="text-right">
                                            {formData.listItem.reduce((sum, obj) => sum + (isNaN(obj.credit) ? 0 : parseFloat(obj.credit)), 0)}
                                            </td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr className="text-red">
                                            <td colSpan="3" className="text-right">
                                                Difference
                                            </td>
                                            <td className="text-right">
                                                &nbsp;
                                            </td>
                                            <td className="text-right">
                                            {
                                                Math.abs(formData.listItem.reduce((sum, obj) => sum + (isNaN(obj.debit) ? 0 : parseFloat(obj.debit)), 0) - formData.listItem.reduce((sum, obj) => sum + (isNaN(obj.credit) ? 0 : parseFloat(obj.credit)), 0))
                                            }
                                            </td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                title="Journal No."
                itemwidth="modal-md"
                isOpen={modalOpen}
                onClose={closeModal}
            >
                <div className="modal-body">
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <div className="form-group">
                                <label className="form-label-small">
                                    Prefix
                                </label>
                                <input type="text" className="form-control" value={jourNo.prefix} readOnly />
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="form-group">
                                <label className="form-label-small">
                                    Journal No.
                                </label>
                                <input type="text" className="form-control" value={jourNo.nextNo} onChange={(e)=>setJourNo({...jourNo, nextNo: e.target.value})} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={()=>{
                        setFormData({...formData, journalNo: jourNo});
                        closeModal()
                    }}>Save</button>
                </div>
            </Modal>
            {error.listitm && <div className="user-alert"><div className="alert rounded-0 alert-danger">{error.listitm}</div></div>}
            {userAlert.status && <div className="user-alert"><div className={`alert rounded-0 ${userAlert.status === 'success' ? 'alert-success' : 'alert-danger'}`}>{userAlert.message}</div></div>}
        </>
    );
}

export default UpdateJournal;
