import React, { useEffect, useState } from "react";
import Select from "react-select";
import Swal from 'sweetalert2';
import { selectStyle } from '../../../config/constants';
import { axiosInstance } from '../../../config/axios';

function Options() {

    const types = [
        { value: 'brand', label: 'Brand'},
        { value: 'type', label: 'Type'},
        { value: 'color', label: 'Color'},
        { value: 'size', label: 'Size'},
        { value: 'qnty', label: 'Quantity'}
    ];

    const [active, setActive] = useState({id: '', type: 0, title: ''});
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        brand: [],
        type: [],
        color: [],
        size: [],
        qnty: [],
        maxLength: 0
    });

    const [tblData, setTblData] = useState([]);

    const fetchData = () => {
        axiosInstance.get("/catalogue/master/list-options")
        .then(function (res) {
            if (res.data.status === 'success') {
                setData({
                    brand: res.data.data.brand,
                    type: res.data.data.type,
                    color: res.data.data.color,
                    size: res.data.data.size,
                    qnty: res.data.data.qnty
                });
            }
        }).catch(err => console.log(err));
    };

    const formatData = () => {
        let maxLength = data.brand.length;
        if(data.type.length > maxLength) maxLength = data.type.length;
        if(data.color.length > maxLength) maxLength = data.color.length;
        if(data.size.length > maxLength) maxLength = data.size.length;
        if(data.qnty.length > maxLength) maxLength = data.qnty.length;

        let tempData = [];
        for (let i = 0; i < maxLength; i++) {
            let dataRow = {};

            let obj = data.brand[i];
            if(obj) dataRow = {...dataRow, ...obj };
            obj = data.type[i];
            if(obj) dataRow = {...dataRow, ...obj };
            obj = data.color[i];
            if(obj) dataRow = {...dataRow, ...obj };
            obj = data.size[i];
            if(obj) dataRow = {...dataRow, ...obj };
            obj = data.qnty[i];
            if(obj) dataRow = {...dataRow, ...obj };
            
            if(Object.keys(dataRow).length > 0) tempData.push(dataRow);
        }
        
        setTblData(tempData);
    };

    const deleteItem = (type, id) => {
        axiosInstance.post("/catalogue/master/delete-option", {
            type: type,
            id: id
        }).then(function (res) {
            if (res.data.status === 'success') {
                setData({
                    brand: res.data.data.brand,
                    type: res.data.data.type,
                    color: res.data.data.color,
                    size: res.data.data.size,
                    qnty: res.data.data.qnty
                });
            }
        }).catch(err => console.log(err));
    };

    const removeItem = (type, id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You wan't to delete !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteItem(type, id);
            }
        });
    };

    const editItem = (type, id) => {
        let title = '';
        if(type == 'brand') {
            let obj = data.brand.find(i => i.Brand_Id == id);
            if(obj) title = obj.Brand_Name;
        } else if(type == 'type') {
            let obj = data.type.find(i => i.Type_Id == id);
            if(obj) title = obj.Type_Name;
        } else if(type == 'color') {
            let obj = data.color.find(i => i.Color_Id == id);
            if(obj) title = obj.Color_Name;
        } else if(type == 'size') {
            let obj = data.size.find(i => i.Size_Id == id);
            if(obj) title = obj.Size_Name;
        } else if(type == 'qnty') {
            let obj = data.qnty.find(i => i.Quantity_Id == id);
            if(obj) title = obj.Quantity_Name;
        }

        setActive({id, type, title});
    };

    const saveItem = () => {
        
        let proceed = true;
        let err = {};
        if(active.type == 0) {
            err.type = 'Please select';
            proceed = false;
        } if(active.title == '') {
            err.title = 'Please enter data';
            proceed = false;
        }
        setError(err);
        
        if(proceed) {
            axiosInstance.post("/catalogue/master/update-option", active)
            .then(function (res) {
                if (res.data.status === 'success') {
                    setActive({...active, ...{id: '', title: ''}});
                    setData({
                        brand: res.data.data.brand,
                        type: res.data.data.type,
                        color: res.data.data.color,
                        size: res.data.data.size,
                        qnty: res.data.data.qnty
                    });
                }
            }).catch(err => console.log(err));
        }
    };

    useEffect(() => {
        formatData();
    }, [data]);

    useEffect(() => {
        fetchData();
    }, [])    

    return (
        <div className="section-body">
            <div className="container-fluid">
                <div className="card pb-0">
                    <div className="card-body pb-0 pt-4 mb-0">
                        <div className="row">
                            <div className="col-4 form-group">
                                <div className="form-group">
                                    <Select
                                        placeholder="Select"
                                        options={types}
                                        value={active.type != 0 ? types.find((i) => i.value === active.type) : ""}
                                        styles={selectStyle}
                                        onChange={(val) =>{
                                            setActive({id: '', title: '', type: val.value});
                                            setError({...error, type: null });
                                        }}
                                    />
                                    {error.type && (<small className="lbl-error">{error.type}</small>)}
                                </div>
                            </div>
                            <div className="col-4 form-group">
                                <input type="text" className="form-control" placeholder="Title Goes Here" value={active.title} onChange={(e) => {
                                    setActive({...active, title: e.target.value });
                                    setError({...error, title: null });
                                }} />
                                {error.title && (<small className="lbl-error">{error.title}</small>)}
                            </div>
                            <div className="col-4 form-group">
                                {loading ? (<button type="button" className="btn c-blue px-5 btn-block text-white" disabled>Updating...</button>) : (<button type="button" onClick={saveItem} className="btn c-blue px-5 btn-block text-white">Add</button>)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-2">
                    <div className="card-status bg-success"></div>
                    <div className="card-body pt-3 pb-1">
                        <div className="table-responsive ast-table">
                            <table className="table table-vcenter mb-0 text-nowrap">
                                <thead>
                                    <tr>
                                        <th className="font-weight-bold">BRAND</th>
                                        <th className="font-weight-bold">TYPE</th>
                                        <th className="font-weight-bold">COLOR</th>
                                        <th className="font-weight-bold">SIZE</th>
                                        <th className="font-weight-bold">QUANTITY</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {tblData.map((itm, i)=>(
                                    <tr key={i}>
                                        <td>{itm.Brand_Id && (<div className="d-flex justify-content-between"><span className="c-pointer" onClick={()=>editItem('brand', itm.Brand_Id)}>{itm.Brand_Name}</span><span className="text-right" onClick={()=>removeItem('brand', itm.Brand_Id)}><i className="fe fe-x red varient-button c-pointer"></i></span></div>)}</td>
                                        <td>{itm.Type_Id && (<div className="d-flex justify-content-between"><span className="c-pointer" onClick={()=>editItem('type', itm.Type_Id)}>{itm.Type_Name}</span><span className="text-right" onClick={()=>removeItem('type', itm.Type_Id)}><i className="fe fe-x red varient-button c-pointer"></i></span></div>)}</td>
                                        <td>{itm.Color_Id && (<div className="d-flex justify-content-between"><span className="c-pointer" onClick={()=>editItem('color', itm.Color_Id)}>{itm.Color_Name}</span><span className="text-right" onClick={()=>removeItem('color', itm.Color_Id)}><i className="fe fe-x red varient-button c-pointer"></i></span></div>)}</td>
                                        <td>{itm.Size_Id && (<div className="d-flex justify-content-between"><span className="c-pointer" onClick={()=>editItem('size', itm.Size_Id)}>{itm.Size_Name}</span><span className="text-right" onClick={()=>removeItem('size', itm.Size_Id)}><i className="fe fe-x red varient-button c-pointer"></i></span></div>)}</td>
                                        <td>{itm.Quantity_Id && (<div className="d-flex justify-content-between"><span className="c-pointer" onClick={()=>editItem('qnty', itm.Quantity_Id)}>{itm.Quantity_Name}</span><span className="text-right" onClick={()=>removeItem('qnty', itm.Quantity_Id)}><i className="fe fe-x red varient-button c-pointer"></i></span></div>)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Options;
