import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { axiosInstance } from "../../../config/axios";
import { selectStyle, reportFilter } from '../../../config/constants'
import "react-datepicker/dist/react-datepicker.css";

function ReportTrialBalance() {

    const [filter, setFilter] = useState({
        duration: { value: 'this-month', label: 'This Month'},
        startDate: null,
        endDate: null,
    })

    const [total, seTotal] = useState({
        credit: 0,
        debit: 0
    });
    const [data, setData] = useState([]);
    const [activeDate, setActiveDate] = useState({
        startDate: null,
        endDate: null
    })
    const fetchData = () => {
        axiosInstance.post("/reports/accounts/trial-balance", filter)
        .then(function (response) {
            if(response.data.status == 'success') {
                setData(response.data.data);
                seTotal(response.data.total);
                setActiveDate({
                    startDate: response.data.startDate,
                    endDate: response.data.endDate
                });
            }
        }).catch(err=>console.log(err));
    };

    useEffect(() => {
        fetchData();
    }, [filter]);

    const history = useHistory();
    const openAccntTrans = (accntId) => {
        const qp = new URLSearchParams();
        qp.append('account', accntId);
        qp.append('start', activeDate.startDate);
        qp.append('end', activeDate.endDate);
        history.push(`/reports/accounts/general-ledger-info?${qp.toString()}`);
    }

    return <>
        <div className="row">
            <div className="col col-md-12 input-group d-flex justify-content-start">
                <div className="col-md-4 col-11 px-0 input-icon table-head-search py-0">
                    <Select
                        placeholder="Select"
                        options={reportFilter}
                        styles={selectStyle}
                        value={filter.duration}
                        onChange={(val)=>{
                            setFilter({...filter, ...{duration: val, startDate: null, endDate: null}})
                        }}
                    />
                </div>
                <div className="col-md-4 col-11 px-0 input-icon table-head-search py-0 pl-1">
                    <DatePicker className="form-control" 
                    selectsRange={true}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Date"
                    startDate={filter.startDate} endDate={filter.endDate}
                    onChange={(update) => {
                        setFilter({...filter, ...{ startDate:update[0], endDate:update[1]}})
                    }} isClearable={true} disabled={filter.duration.value == 'custom' ? false : true} />
                </div>
                <span className="input-group-append dropdown"><button className="btn c-lgt-blue text-white" type="button">Download &nbsp; <i className="fe fe-download"></i></button></span>
            </div>
        </div>
        <div className="card">
            <div className="card-status c-blue top-layer"></div>
            <div className="card-body px-1 h-80-scroll">
                <div className="table-responsive ast-table h-100">
                    <table className="table table-striped table-vcenter mb-0 text-nowrap tbl-journal">
                        <thead>
                            <tr>
                                <th>Account</th>
                                <th>Net Debit</th>
                                <th>Net Credit</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.map((item, i)=>(
                            <>
                            <tr className="tbl-grey-head" key={i}>
                                <td colSpan={3}>{item.masterName}</td>
                            </tr>
                            {item.accounts.map((row, j)=>(
                            <tr key={`${i+''+j+'ac'}`}>
                                <td className="c-pointer" onClick={()=>openAccntTrans(row.accntId)}>{row.account}</td>
                                <td>{row.debit > 0 && row.debit.toFixed(2)}</td>
                                <td>{row.credit > 0 && row.credit.toFixed(2)}</td>
                            </tr>
                            ))}
                            </>
                        ))}
                        {data.length > 0 && (
                            <tr className="tbl-grey-head">
                                <td>Total</td>
                                <td>{total.debit.toFixed(2)}</td>
                                <td>{total.credit.toFixed(2)}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    {data.length <= 0 && <p className="w-100 text-center pt-5">No Data Found</p>}
                </div>
            </div>
        </div>
    </>;
}

export default ReportTrialBalance;
