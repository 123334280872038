import React from 'react';

const Modal = ({ isOpen, onClose, children, title, itemwidth }) => {
    if (!isOpen) return null;

    return (
        <div className="modal fade show d-block" tabIndex="-1" role="dialog">
            <div className={`modal-dialog ${itemwidth}`} role="document">
                <div className="modal-content">
                    <div className="card-status bg-success"></div>
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div>
                        {children}
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </div>
    );
};

export default Modal;
