import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ReportAccTransaction, ReportAccTypeSummary, ReportAccTypeTransaction, ReportDayBook, ReportGeneralLedger, ReportDetailedGeneralLedger, ReportGeneralLedgerInfo, ReportJournal, ReportTrialBalance, ReportBalanceSheet, ReportProfitLoss, ReportCashFlow } from '.'

function ReportsView() {

    const { slug } = useParams();

    const reportMenu = [
        { title: 'Account Transactions', uri: '/reports/accounts/account-transactions' },
        { title: 'Account Type Summary', uri: '/reports/accounts/account-type-summary' },
        { title: 'Account Type Transactions', uri: '/reports/accounts/account-type-transactions' },
        { title: 'Day Book', uri: '/reports/accounts/day-book' },
        { title: 'General Ledger', uri: '/reports/accounts/general-ledger' },
        { title: 'Detailed General Ledger', uri: '/reports/accounts/detailed-general-ledger' },
        { title: 'Journal Report', uri: '/reports/accounts/journal-report' },
        { title: 'Trial Balance', uri: '/reports/accounts/trial-balance' },
        { title: 'Profit & Loss', uri: '/reports/accounts/profit-loss' },
        { title: 'Cash Flow Statement', uri: '/reports/accounts/cash-flow-statement' },
        { title: 'Balance Sheet', uri: '/reports/accounts/balance-sheet' },
    ]
    const [submenu, setSubmenu] = useState(reportMenu)

    let reportComponent;
    switch(slug) {
        case 'account-transactions':
            reportComponent = <ReportAccTransaction />;
            break;
        case 'account-type-summary':
            reportComponent = <ReportAccTypeSummary />;
            break;
        case 'account-type-transactions':
            reportComponent = <ReportAccTypeTransaction />;
            break;
        case 'day-book':
            reportComponent = <ReportDayBook />;
            break;
        case 'general-ledger':
            reportComponent = <ReportGeneralLedger />;
            break;
        case 'detailed-general-ledger':
            reportComponent = <ReportDetailedGeneralLedger />;
            break;
        case 'general-ledger-info':
            reportComponent = <ReportGeneralLedgerInfo />;
            break;
        case 'journal-report':
            reportComponent = <ReportJournal />;
            break;
        case 'trial-balance':
            reportComponent = <ReportTrialBalance />;
            break;
        case 'balance-sheet':
            reportComponent = <ReportBalanceSheet />;
            break;
        case 'profit-loss':
            reportComponent = <ReportProfitLoss />;
            break;
        case 'cash-flow-statement':
            reportComponent = <ReportCashFlow />;
            break;
        default:
            reportComponent = <div>No Data Found.</div>
    }
    
    return <div>
        <div className="section-body">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-3 order-2 order-lg-1">
                        <div className="card">
                            <div className="card-body h-75-scroll">
                                <div className="row">
                                    <div className="list-group list-widget w-100">
                                        <span className="list-group-item bg-light-blue font-weight-bold">Accounts</span>
                                        {submenu.map((data, i)=>(
                                            <Link key={i} to={data.uri} className={`list-group-item py-2 ${data.uri.includes('/reports/accounts/' + slug) ? 'active font-weight-bold' : ''}`}>{data.title}</Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 order-1 order-lg-2">
                        {reportComponent}
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default ReportsView;
