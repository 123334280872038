import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';

import Select from "react-select";
import { selectStyle } from "../../../../../config/constants";

import { AutosuggestItem, AutosuggestVendor } from '../..';
import PurchaseChannelPrice from './PurchaseChannelPrice';
import { InputText, InputSelect, InputSelectVal, InputCheckBox, AlertUser } from '../../..';
import { axiosInstance } from "../../../../../config/axios";

function Purchase() {

    const { id } = useParams();
    const history = useHistory();

    const branchInfo = useSelector((state)=>{
        return state.branch
    });

    const tempChannel = {
        tempId: Math.floor(Math.random() * 90000),
        channelId: {
            value: 0
        },
        chId: '',
        qnty: '',
    };

    const initialData = {
        purchaseId: id,
        itemName: '',
        tracking: 0,
        unit: 'Unit',
        variant: null,
        qnty: '',
        foc: '',
        prevCost: '',
        listPrice: '',
        costPrice: '',
        mrp: '',
        upc: '',
        stockId: '',
        accnt: '',
        serialNo: '',
        batchNo: '',
        mfgDate: '',
        expType: '',
        expDuration: '',
        expDate: '',
        createCatalogue: 0,
        pricing: []
    };

    const expiryDates = [
        {value: 0, label: 'Date'},
        {value: 1, label: 'Date After Days'},
        {value: 2, label: 'Date After Weeks'},
        {value: 3, label: 'Date After Month'},
        {value: 4, label: 'Date After Year'},
    ];

    const [ajaxResponse, setAjaxResponse] = useState({});
    useEffect(() => {
        setTimeout(() => {
            setAjaxResponse({});
        }, 5000);
    }, [ajaxResponse]);

    const [card, setCard] = useState({ sales: false, item: true, table: true, vendor: true });

    const [formData, setFormData] = useState({
        channel: [],
        taxclass: [],
        igstclass: [],
        accPurchase: [],
        allTaxCls: [],
    });
    const [billInfo, setBillInfo] = useState({});
    const [data, setData] = useState(initialData);
    const [error, setError] = useState({});
    const [variant, setVariant] = useState([]);
    const [grnItems, setGrnItems] = useState({
        items: [],
        grandTotal: 0,
        grandTax: 0
    });
    const [loading, setLoading] = useState({item: false, grn: false});
    const inputChange = (e) => {
        let txt = e.target.value;
        if(e.target.name === 'qnty' || e.target.name === 'foc') {
            let regex = /^\d*\.?\d*$/;
            if(!regex.test(txt)) e.target.value = txt.slice(0, -1);
        }
        setData({...data, [e.target.name]: e.target.value});
        setError({...error, [e.target.name]: null });
    };
    const selectChange = (name, val) => {
        setData({...data, [name]: val});
        setError({...error, [name]: ''});
    };
    const chkChange = (e) => {
        setData({...data, [e.target.name]: e.target.checked ? 1 : 0});
    };

    const onItemSearch = (newValue)=>{
        setData({...data, ...{itemName: newValue, variant: null}});
        setError({...error, itemName: ''});
    };
    const onItemSelected = (e, { suggestion }) => {
        setData({...data, ...{ unit: suggestion.unit, tracking: suggestion.tracking, accnt: suggestion.accPurchase }});
        setVariant(suggestion.variant);
        setError({...error, variant: ''});
    };

    Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    };
    useEffect(() => {
        let mfgDate = data.mfgDate;
        let mfgType = data.expType;
        let expcnt  = data.expDuration;
        if(mfgDate != "" && mfgType != "" && expcnt != "") {
            expcnt = parseInt(expcnt);
            var originalDate = new Date(mfgDate);
            var daysToAdd = 0;
            if(mfgType == 1) {
                daysToAdd = expcnt;
            } else if(mfgType == 2) {
                daysToAdd = expcnt * 7;
            } else if(mfgType == 3) {
                daysToAdd = expcnt * 31;
            } else if(mfgType == 4) {
                daysToAdd = expcnt * 365;
            }

            var finalDate = originalDate.addDays(daysToAdd);
            setData({...data, expDate: finalDate.toISOString().split('T')[0]});
        }
    }, [data.mfgDate, data.expType, data.expDuration]);

    useEffect(() => {
        axiosInstance
        .get('/inventory/purchase/purchase-form')
        .then(function (res) {
            setFormData({
                channel: res.data.data.channel,
                taxclass: res.data.data.taxclass,
                igstclass: res.data.data.igstclass,
                accPurchase: res.data.data.accPurchase,
                allTaxCls: res.data.data.allTaxCls,
            });
        }).catch(e => console.log(e));
    }, []);

    useEffect(() => {
        if(variant.length == 1) {
            setData({...data, ...{
                variant: variant[0],
                mrp: variant[0].mrp,
                upc: variant[0].upc,
                prevCost: variant[0].cost,
                stockId: variant[0].stockId ? variant[0].stockId : ''
            }});
        } else {
            setData({...data, variant: null});
        }
    }, [variant]);

    useEffect(() => {
        setData({...data, costPrice: data.listPrice});
    }, [data.listPrice]);

    // enable sales channel row 
    // if checkbox "createCatalogue" enabled
    useEffect(() => {
        if(data.createCatalogue) {
            setCard({...card, sales: true});
            let tempPrice = [];
            if(data.variant) {
                data.variant.pricing.map((ob, i)=>{
                    let chnl = formData.channel.find(el => el.value === ob.Channel_Id);
                    if(chnl) {
                        console.log(chnl);
                        tempPrice.push({
                            tempId: Math.floor(Math.random() * 90000),
                            channelId: chnl,
                            igstId: ob.IGSTax_Id,
                            taxId: ob.Tax_Id,
                            price: ob.ItemC_Price ? ob.ItemC_Price : '',
                            price2: ob.ItemW_Price ? ob.ItemW_Price : '',
                            chId: ob.Ch_Id,
                            qnty: chnl.isPrimary ? data.qnty : '',
                        });
                    }
                });
            }

            if(tempPrice.length === 0) tempPrice.push(tempChannel);

            setData({...data, pricing: tempPrice});
        } else {
            setCard({...card, sales: false});
            setData({...data, pricing: []});
        }
    }, [data.createCatalogue, data.variant]);
    
    // change selling qnty if purchase qnty changed
    useEffect(() => {
        if(data.qnty && data.pricing.length > 0) {
            let pricing = data.pricing;
            pricing.forEach(obj => obj.qnty = 0);

            let primeChnl = formData.channel.find(el => el.isPrimary === 1);

            const tempObj = pricing.find(obj => obj.channelId.value === primeChnl.value);
            if (tempObj) tempObj.qnty = data.qnty;

            setData({...data, pricing: pricing});
        }

    }, [data.qnty]);

    const onChnlRowChange =(name, val, index)=>{
        let pricing = data.pricing;
        let newArray = pricing.map((obj, i) =>{ 
            return i === index ? { ...obj, ...{[name]: val, errChannel: '', errPrice: ''} } : obj;
        });
        setData({...data, pricing: newArray });
    };

    const createChannelRow = (e)=>{
        if(data.createCatalogue) {
            e.preventDefault();
            if(data.pricing.length < formData.channel.length) {
                tempChannel.tempId = Math.floor(Math.random() * 90000);
                setData({
                    ...data,
                    pricing: [...data.pricing, tempChannel]
                });
            }
        }
    };

    const removeChannelRow = (i)=> {
        if(data.pricing.length > 1) {
            let pricing = data.pricing;
            pricing.splice(i, 1);
            setData({
                ...data,
                pricing: pricing
            });
        }
    };    

    // add item to temp list
    const addItem = ()=> {
        let err = {};
        let proceed = true;

        if(!data.itemName) {
            err.itemName = 'Please select item';
            proceed = false;
        } if(!data.variant) {
            err.variant = 'Please select variant';
            proceed = false;
        } if(!data.qnty) {
            err.qnty = 'Please enter quantity';
            proceed = false;
        } if(!data.listPrice) {
            err.listPrice = 'Please enter value';
            proceed = false;
        } if(!data.costPrice) {
            err.costPrice = 'Please enter value';
            proceed = false;
        } if(!data.mrp) {
            err.mrp = 'Please enter value';
            proceed = false;
        } if(branchInfo.AllowAccounts && !data.accnt) {
            err.accnt = 'Please select';
            proceed = false;
        }

        let tempData;
        if(data.variant) {
            tempData = {...data, variant: data.variant.value};
            
            if(data.createCatalogue && data.pricing.length > 0) {
                let newPricing = [];
                let totalQnty = 0;
                let vrPricing = data.pricing;
                let newArray = vrPricing.map((obj, i) =>{ 
                    if(obj.channelId.value == 0) {
                        obj.errChannel = 'Please select';
                        proceed = false;
                    } if(!obj.price) {
                        obj.errPrice = 'Please enter value';
                        proceed = false;
                    }
                    if(obj.channelId.isPrimary || obj.channelId.allocation) {
                        totalQnty += parseFloat(obj.qnty);
                    } else {
                        obj.qnty = 0;
                    }
                    newPricing.push({
                        channelId: obj.channelId.value,
                        chId: obj.chId,
                        taxId: obj.taxId,
                        igstId: obj.igstId,
                        price: obj.price,
                        price2: obj.price2,
                        qnty: obj.qnty
                    });
                    return obj;
                });
                if(!proceed) setData({...data, pricing: newArray });
    
                tempData = {...tempData, pricing: newPricing };
    
                let availQty = parseInt(data.qnty);
                if(data.foc) {
                    availQty += parseInt(data.foc);
                }
                // check purchase qnty
                if(totalQnty > availQty) {
                    setAjaxResponse({ status:'error', message: "Please check opening stock" });
                    proceed = false;
                }
            }
        } else {
            proceed = false;
        }

        setError(err);
        if(proceed) {            
            axiosInstance
            .post('/inventory/purchase/temp-items', tempData)
            .then(function (res) {
                if (res.data.status === 'success') {
                    setData(initialData);
                    setError({});
                    setVariant([]);
                    setGrnItems(res.data.data);
                }
                setAjaxResponse({ status: res.data.status, message: res.data.message });
            }).catch(e => console.log(e));
        }
    };

    const removeItem = (e, id) => {
        e.preventDefault();
        axiosInstance
        .post(`/inventory/purchase/delete-temp-item`, {
            purchaseId: data.purchaseId ? data.purchaseId : 0, id
        }).then(function (res) {
            setGrnItems(res.data.data);
        }).catch(e => console.log(e));
    };

    // get purchase items if any
    useEffect(() => {
        axiosInstance
        .get(`/inventory/purchase/list-temp-items?id=${data.purchaseId ? data.purchaseId : 0}`)
        .then(function (res) {
            setGrnItems(res.data.data);
            if(res.data.bill) {
                setBillInfo({...billInfo, ...res.data.bill});
            }
        }).catch(e => console.log(e));
    }, []);
    
    const formatOptionLabel = ({ label, subtext }) => (<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <div>{label}</div>
        <div style={{ fontSize: '80%', color: 'gray', marginLeft: '10px', marginTop: '2px' }}>{subtext}</div>
    </div>);

    const [changed, setChanged] = useState([]);

    const updateTableData = () => {
        if(changed.length > 0) {
            const filteredArray = grnItems.items.filter(obj => changed.includes(obj.id));
            let tempData = [];
            filteredArray.forEach(el => {
                tempData.push({
                    id: el.id,
                    quantity: el.quantity,
                    foc: el.foc,
                    tax: el.tax,
                    cost: el.cost,
                    upc: el.upc,
                    accnt: el.accnt,
                });
            });

            if(tempData.length > 0) {
                axiosInstance
                .post('/inventory/purchase/update-items', {
                    purchaseId: data.purchaseId ? data.purchaseId : 0,
                    data: tempData
                }).then(function (res) {
                    if (res.data.status === 'success') {
                        setGrnItems(res.data.data);
                    }
                }).catch(e => console.log(e));
            }
            setChanged([]);
        }
    };

    const onTblDataChange = (e, i) => {
        let tempItems = grnItems.items;
        tempItems = tempItems.map(obj => obj.id === i ? {...obj, [e.target.name]: e.target.value} : obj);
        setGrnItems({...grnItems, items: tempItems});
        let tempIds = [...changed];
        if (!tempIds.includes(i)) {
            tempIds.push(i);
            setChanged(tempIds);
        }
    };
    const onTblDataSelect = (val, name, i) => {
        let tempItems = grnItems.items;
        tempItems = tempItems.map(obj => obj.id === i ? {...obj, [name]: val.value} : obj);
        setGrnItems({...grnItems, items: tempItems});
        let tempIds = [...changed];
        if (!tempIds.includes(i)) {
            tempIds.push(i);
            setChanged(tempIds);
        }
    };

    const onVendorSearch = (newValue)=>{
        setBillInfo({...billInfo, vendor: { ...billInfo.vendor, label: newValue }});
        setError({...error, vendor: ''});
    };
    const onVendorSelected = (e, { suggestion }) => {
        setBillInfo({...billInfo, ...{vendor: suggestion}, emailId: suggestion.email, phoneNo: suggestion.phone});
        setError({...error, vendor: ''});
    };

    const billInfoChange = (e) => {
        setBillInfo({...billInfo, [e.target.name]: e.target.value });
        setError({...error, [e.target.name]: null });
    };

    const updateBill = (status) => {

        let proceed = true;
        let err = {};

        if(!billInfo.vendor) {
            err.vendor = 'Please select vendor';
            proceed = false;
        } if(!billInfo.invId) {
            err.invId = 'Please enter value';
            proceed = false;
        } if(!billInfo.billdate) {
            err.billdate = 'Please select date';
            proceed = false;
        } if(grnItems.items.length == 0) {
            proceed = false;
            setAjaxResponse({ status:'error', message: "Please add items" });
        }

        setError(err);

        if(proceed) {
            setLoading({...loading, grn: true});
            axiosInstance
            .post('/inventory/purchase/update-bill', {
                ...billInfo, 
                vendor: billInfo.vendor.value, 
                purchaseId: data.purchaseId,
                status
            }).then(function (res) {
                setLoading({...loading, grn: false});
                if(res.data.status === 'success') {
                    history.push('/inventory/purchase');
                }
                setAjaxResponse({ status: res.data.status, message: res.data.message });
            }).catch(e => console.log(e));
        }
    };

    return <>
    <div className="section-body">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 input-group d-flex justify-content-start">
                    {loading.grn ? (<span className="input-group-append"><button type="button" className="btn c-red text-white px-4" disabled>Please wait...</button></span>) : (<><span className="input-group-append"><button type="button" className="btn c-red text-white px-4" onClick={()=>updateBill(1)}>Create GRN</button></span>
                    <span className="input-group-append"><button type="button" className="btn c-blue text-white px-4" onClick={()=>updateBill(0)}>Save Draft</button></span></>)}                    
                    {data.purchaseId && <span className="input-group-append dropdown">
                        <button className="btn c-lgt-blue text-white" type="button" data-toggle="dropdown">
                            <i className="fe fe-sliders"></i>
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                <a className="dropdown-item delete-draft" href="#"><i className="dropdown-icon fe fe-trash-2"></i> Delete</a>
                            </div>
                        </button>
                    </span>}                                    
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-header pt-3">
                    <p className="card-title mr-4 font-weight-bold">PURCHASE INFORMATION</p>
                    <div className="card-options">
                        <a href="#" className="card-options-collapse" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a>
                    </div>
                </div>
                <div className="card-body px-3 border-top">
                    <div className="row">
                        <AutosuggestItem onSelect={onItemSelected} onSearch={onItemSearch} title="Item Name" wrapper="col-6 col-lg-3 form-group mb-0" required={true} error={error.itemName} />
                        <InputSelectVal wrapper="col-6 col-lg-3 form-group mb-0" title="Variant" required={true} value={data.variant} name="variant" onSelect={selectChange} data={variant} error={error.variant} />

                        <div className="col-6 col-lg-3 form-group mb-0">
                            <div className="form-group mb-0">
                                <label className="form-label-small">Received Qty <span className="red">*</span></label>
                                <div className="input-group">
                                    <input type="text" className="form-control" name="qnty" value={data.qnty} onChange={inputChange} />
                                    <div className="input-group-append">
                                        <span className="btn c-blue text-white">{data.unit}</span>
                                    </div>
                                </div>
                            </div>
                            {error.qnty && (<small className="lbl-error">{error.qnty}</small>)}
                        </div>
                        <div className="col-6 col-lg-3 form-group mb-0">
                            <div className="form-group mb-0">
                                <label className="form-label-small">FOC Qty <i className="fe fe-help-circle text-gray cursor"></i></label>
                                <div className="input-group">
                                <input type="text" className="form-control" name="foc" value={data.foc} onChange={inputChange} />
                                    <div className="input-group-append">
                                        <span className="btn c-blue text-white">{data.unit}</span>
                                    </div>
                                </div>
                            </div>
                            {error.foc && (<small className="lbl-error">{error.foc}</small>)}
                        </div>

                        <InputText wrapper="col-6 col-lg-3 form-group mb-0" title="Previous Price" required={false} value={data.prevCost} name="prevCost" onChange={inputChange} error={error.prevCost} readonly={true} />
                        <InputText wrapper="col-6 col-lg-3 form-group mb-0" title="List Price" required={true} value={data.listPrice} name="listPrice" onChange={inputChange} error={error.listPrice} type="decimal" />
                        <InputText wrapper="col-6 col-lg-3 form-group mb-0" title="WAC/Cost Price" required={true} value={data.costPrice} name="costPrice" onChange={inputChange} error={error.costPrice} type="decimal" />
                        <InputText wrapper="col-6 col-lg-3 form-group mb-0" title="MRP" required={true} value={data.mrp} name="mrp" onChange={inputChange} error={error.mrp} type="decimal" />

                        <InputSelect wrapper={branchInfo.AllowAccounts ? 'col-6 col-md-3' : 'd-none'} title="Account" required={true} value={data.accnt} name="accnt" onSelect={selectChange} data={formData.accPurchase} error={error.accnt} />

                        <InputText wrapper="col-6 col-lg-3 form-group mb-0" title="UPC/Barcode" required={false} value={data.upc} name="upc" onChange={inputChange} error={error.upc} type="alphanum" />

                        <InputText wrapper="col-6 col-lg-3 form-group mb-0" title="Stock Id" required={false} value={data.stockId ? data.stockId : ''} name="stockId" onChange={inputChange} error={error.stockId} type="alphanum" />

                        { data.tracking == 1 && (<><InputText wrapper="col-6 col-md-3" title="Batch No" required={true} value={data.batchNo} name="batchNo" onChange={inputChange} error={error.batchNo} />
                        <InputText field="date" wrapper="col-6 col-md-3" title="Mfg. Date" required={true} value={data.mfgDate} name="mfgDate" onChange={inputChange} error={error.mfgDate} />
                        <InputSelect wrapper="col-6 col-md-3" title="Exp. Date" required={true} value={data.expType} name="expType" onSelect={selectChange} data={expiryDates} error={error.expType} />
                        <div className="col-6 col-md-3">
                            <label className="form-label-small" data-toggle="tooltip" data-placement="top" title="Choose Date">Choose Period <span className="red">*</span></label>
                            <div className="input-group mb-3">
                                <div className={`input-group-prepend ${data.expType === 0 ? 'd-none' : ''}`}>
                                    <input type="number" className="form-control px-1" style={{width: '60px'}} name="expDuration" value={data.expDuration} onChange={inputChange} />
                                </div>
                                <input type="date" className="form-control px-1" value={data.expDate} name="expDate" onChange={inputChange} />
                            </div>
                        </div></>)}

                        {data.tracking == 2 && <InputText wrapper="col-6 col-md-3" title="Serial No" required={true} value={data.serialNo} name="serialNo" onChange={inputChange} error={error.serialNo} />}

                        <InputCheckBox wrapper="col-6 col-lg-3 mt-3" title="Create GRN without PO" checked={billInfo.createPO} name="createPO" onCheck={chkChange} />

                        <InputCheckBox wrapper="col-6 col-lg-4 mt-3" title="Update Corresponding stock in catalogue" checked={data.createCatalogue} name="createCatalogue" onCheck={chkChange} />
                    </div>
                    <div className={`card mt-4 ${!card.sales && 'card-collapsed'}`}>
                        <div className="card-header mx-0 pt-2 bg-light">
                            <p className="card-title font-weight-bold"><i className="fa fa-long-arrow-right text-red varient-button pr-2"></i> Sales information</p>
                            <div className="card-options">
                                <a className="c-pointer" onClick={createChannelRow}><i className="fe fe-plus-circle overlay-ico mr-1"></i></a>
                                <a className="c-pointer card-options-collapse" onClick={()=>setCard({...card, sales: !card.sales})}><i className="fe fe-chevron-up"></i></a>
                            </div>
                        </div>
                        <div className="card-body px-2 my-0 pb-0">
                        {data.pricing.map((el, i)=>(
                            <PurchaseChannelPrice key={el.tempId + i} index={i} channel={formData.channel} taxclass={formData.taxclass} igstclass={formData.igstclass} onChnlRowChange={onChnlRowChange} data={el} removeItem={removeChannelRow} />
                        ))}
                        </div>
                    </div>
                </div>
                <div className="card-footer py-0 d-flex justify-content-end px-0">
                    <span className="input-group-append"><button type="button" className="btn c-red text-white px-4" onClick={addItem}><i className="fe fe-plus"></i> ADD ITEM</button></span>
                </div>
            </div>
        </div>
    </div>

    <div className="section-body">
        <div className="container-fluid">
            <div className="col-12 bg-white mb-2 px-0 py-2">
                <div className="d-flex">
                    <div className="col border-right pt-2 text-center">
                        <small className="mb-0">Total Amount</small>
                        <h4 className="font-30 font-weight-bold text-col-blue counter">{parseFloat(grnItems.grandTotal).toFixed(branchInfo.Branch_DecimalPoints)}</h4>
                    </div>
                    {branchInfo.View_Tax && (<div className="col border-right text-center pt-2">
                        <small className="mb-0">Tax</small>
                        <h4 className="font-30 font-weight-bold text-col-blue counter">{parseFloat(grnItems.grandTax).toFixed(branchInfo.Branch_DecimalPoints)}</h4>
                    </div>)}                    
                    <div className="col text-center pt-2">
                        <small className="mb-0">Total Products</small>
                        <h4 className="font-30 font-weight-bold text-col-blue counter">{grnItems.items.length}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="section-body">
        <div className="container-fluid">
            <div className={`card my-3 ${!card.item && 'card-collapsed'}`}>
                <div className="card-status bg-success"></div>
                <div className="card-header mx-0 pt-2 bg-light">
                    <p className="card-title font-weight-bold"><i className="fa fa-long-arrow-right text-red varient-button pr-2"></i> Items</p>
                    <div className="card-options">
                        <a className="c-pointer card-options-collapse" onClick={()=>setCard({...card, item: !card.item})}><i className="fe fe-chevron-up"></i></a>
                    </div>
                </div>
                <div className="card-body px-2 my-0 pb-2 pt-1">
                    <div className="table-responsive ast-table">
                        <table className="table table-striped table-vcenter mb-0 text-nowrap">
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>No</th>
                                    <th>Item</th>
                                    <th>Qty</th>
                                    <th>FOC</th>
                                    {branchInfo.View_Tax && <th style={grnItems.items.length > 0 ? {minWidth: '170px'} : {}}>Tax</th>}
                                    <th>Price</th>
                                    <th>WAC/Cost Price</th>
                                    <th>MRP</th>
                                    <th>UPC</th>
                                    {branchInfo.AllowAccounts && <th style={grnItems.items.length > 0 ? {minWidth: '250px'} : {}}>Account</th>}
                                    <th>Batch No</th>
                                    <th>Mfg.Date</th>
                                    <th>Exp. Date</th>
                                    <th>Serial No</th>
                                    {branchInfo.View_Tax ? (branchInfo.Branch_TaxType == 1 && (<>
                                    <th>CGST</th>
                                    <th>SGST</th>
                                    <th>IGST</th>
                                    </>)) : null}
                                    {branchInfo.View_Tax ? (branchInfo.Branch_TaxType == 2 && <th>VAT</th>) : null}
                                    <th>CESS</th>
                                    <th>Tax Total</th>
                                    <th>Total Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {grnItems.items.map((el, i)=>(
                                    <tr key={el.id}>
                                        <td className="px-0">
                                            <span className="d-flex justify-content-start">
                                                <a href="#" className="c-pointer" onClick={(e)=>removeItem(e, el.id)}>
                                                    <i className="fe fe-minus-circle text-red varient-button px-3"></i>
                                                </a>
                                            </span>
                                        </td>
                                        <td>{i + 1}</td>
                                        <td><a href="#">{el.name}</a></td>
                                        <td className="inw-s">
                                            <input type="number" className="form-control" style={{width: '80px'}} name="quantity" value={el.quantity} 
                                            onChange={(e)=>onTblDataChange(e, el.id)}
                                            onBlur={updateTableData} />
                                        </td>
                                        <td className="inw-s">
                                            <input type="number" className="form-control" style={{width: '80px'}} name="foc" value={el.foc > 0 ? el.foc : ''} onChange={(e)=>onTblDataChange(e, el.id)} onBlur={updateTableData} />
                                        </td>
                                        <td>
                                            <Select 
                                                options={formData.allTaxCls} 
                                                styles={selectStyle}
                                                menuPosition="fixed"
                                                menuPlacement="bottom"
                                                value={el.tax !== '' || el.tax !== 0 ? formData.allTaxCls.find((i) => i.value === el.tax) : ""}
                                                onChange={(val)=>onTblDataSelect(val, 'tax', el.id)}
                                                onBlur={updateTableData}
                                            />
                                        </td>
                                        <td className="inw-s">
                                            <input type="number" className="form-control" style={{width: '80px'}} name="cost" value={el.cost} onChange={(e)=>onTblDataChange(e, el.id)} onBlur={updateTableData} />
                                        </td>
                                        <td className="px-4">{el.cost}</td>
                                        <td className="px-4">{el.mrp}</td>
                                        <td className="px-4">
                                            <input type="text" className="form-control" style={{width: '150px'}} name="upc" value={el.upc ? el.upc : ''} onChange={(e)=>onTblDataChange(e, el.id)} onBlur={updateTableData} />
                                        </td>
                                        <td>
                                            <Select 
                                                options={formData.accPurchase} 
                                                styles={selectStyle}
                                                formatOptionLabel={formatOptionLabel}
                                                menuPosition="fixed"
                                                menuPlacement="bottom"
                                                value={el.accnt !== '' || el.accnt !== 0 ? formData.accPurchase.find((i) => i.value === el.accnt) : ""}
                                                onChange={(val)=>onTblDataSelect(val, 'accnt', el.id)}
                                                onBlur={updateTableData}
                                            />
                                        </td>
                                        <td className="px-4 bg-light">{el.batch ? el.batch : '-'}</td>
                                        <td className="px-4 bg-light">{el.mfgDate ? el.mfgDate : '-'}</td>
                                        <td className="px-4 bg-light">{el.expDate ? el.expDate : '-'}</td>
                                        <td className="px-4 bg-light">{el.serialNo ? el.serialNo : '-'}</td>

                                        {branchInfo.View_Tax ? (branchInfo.Branch_TaxType == 1 && (<>
                                        <td className="px-4 bg-light ">{el.taxInfo.cgstAmnt > 0 ? parseFloat(el.taxInfo.cgstAmnt).toFixed(branchInfo.Branch_DecimalPoints) : '-'}</td>
                                        <td className="px-4 bg-light ">{el.taxInfo.sgstAmnt > 0 ? parseFloat(el.taxInfo.sgstAmnt).toFixed(branchInfo.Branch_DecimalPoints) : '-'}</td>
                                        <td className="px-4 bg-light ">{el.taxInfo.igstAmnt > 0 ? parseFloat(el.taxInfo.igstAmnt).toFixed(branchInfo.Branch_DecimalPoints) : '-'}</td>
                                        </>)) : null}
                                        {branchInfo.View_Tax ? (branchInfo.Branch_TaxType == 2 && <td className="px-4 bg-light ">{el.taxInfo.vatAmnt > 0 ? parseFloat(el.taxInfo.vatAmnt).toFixed(branchInfo.Branch_DecimalPoints) : '-'}</td>) : null}
                                        
                                        <td className="px-4 bg-light ">{el.taxInfo.cessAmnt > 0 ? parseFloat(el.taxInfo.cessAmnt).toFixed(branchInfo.Branch_DecimalPoints) : '-'}</td>

                                        <td className="px-4 bg-light ">{el.taxInfo.totalTax > 0 ? parseFloat(el.taxInfo.totalTax).toFixed(branchInfo.Branch_DecimalPoints) : '-'}</td>

                                        <td className="px-4 bg-light">{el.totalAmnt > 0 ? parseFloat(el.totalAmnt).toFixed(branchInfo.Branch_DecimalPoints) : '-'}</td>
                                    </tr>
                                ))}
                                {grnItems.items.length <= 0 && <tr><td colSpan="14"><p className="text-center pt-3 text-gray">No Data Found.</p></td></tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="section-body">
        <div className="container-fluid">
            <div className={`card ${!card.vendor && 'card-collapsed'}`}>
                <div className="card-status bg-success"></div>
                <div className="card-header pt-3">
                    <p className="card-title mr-4 font-weight-bold"><i className="fa fa-long-arrow-right text-red varient-button pr-2"></i> VENDOR DETAILS</p>
                    <div className="card-options">
                        <a className="c-pointer card-options-collapse" onClick={()=>setCard({...card, vendor: !card.vendor})}><i className="fe fe-chevron-up"></i></a>
                    </div>
                </div>
                <div className="card-body pb-0">
                    <div className="row">                        
                        <AutosuggestVendor onSelect={onVendorSelected} onSearch={onVendorSearch} title="Vendor Name" wrapper="col-6 col-lg-3 form-group mb-0" required={true} error={error.vendor} defaultVal={billInfo.vendor} />
                        <InputText wrapper="col-6 col-lg-2 form-group mb-0" title="Invoice ID" required={true} value={billInfo.invId} name="invId" onChange={billInfoChange} error={error.invId} />
                        <InputText field="date" wrapper="col-6 col-lg-2 form-group mb-0" title="Date" required={true} value={billInfo.billdate} name="billdate" onChange={billInfoChange} error={error.billdate} />
                        <InputText wrapper="col-6 col-lg-3 form-group mb-0" title="Email" required={false} value={billInfo.emailId} name="emailId" onChange={billInfoChange} error={error.emailId} />
                        <InputText wrapper="col-6 col-lg-2 form-group mb-0" title="Phone" required={false} value={billInfo.phoneNo} name="phoneNo" onChange={billInfoChange} error={error.phoneNo} />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <AlertUser {...ajaxResponse} />
    </>;
}

export default Purchase;