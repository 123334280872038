import React, { useState, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { axiosInstance } from "../../../config/axios";
import "react-datepicker/dist/react-datepicker.css";

function ReportDayBook() {

    const [filter, setFilter] = useState({
        transDate: new Date()
    })

    const [data, setData] = useState([]);

    const fetchData = () => {
        axiosInstance.post("/reports/accounts/day-book", filter).then(function (response) {
            if(response.data.status == 'success')
                setData(response.data.data);
        }).catch(err=>console.log(err));
    };

    useEffect(() => {
        fetchData();
    }, [filter]);

    return <>
        <div className="row">
            <div className="col col-md-12 input-group d-flex justify-content-start">
                <div className="col-md-4 col-11 px-0 input-icon table-head-search py-0 pl-1">
                    <DatePicker className="form-control" 
                    selectsRange={false}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Date"
                    selected={filter.transDate} onChange={(date) => setFilter({ ...filter, transDate:date})} isClearable={false} />
                </div>
                <span className="input-group-append dropdown"><button className="btn c-lgt-blue text-white" type="button">Download &nbsp; <i className="fe fe-download"></i></button></span>
            </div>
        </div>
        <div className="card">
            <div className="card-status c-blue top-layer"></div>
            <div className="card-body px-1 h-80-scroll">
                <div className="table-responsive ast-table h-100">
                    <table className="table table-striped table-vcenter mb-0 text-nowrap">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Account</th>
                                <th>Transaction <br/> Details</th>
                                <th>Transaction <br/> Type</th>
                                <th>Transaction</th>
                                <th>Referance</th>
                                <th>Debit</th>
                                <th>Credit</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.map((item, i)=>(
                            <tr key={i}>
                                <td>{item.transdate}</td>
                                <td>{item.account}</td>
                                <td>{item.remarks}</td>
                                <td>{item.transType}</td>
                                <td>{item.transaction}</td>
                                <td>{item.referance}</td>
                                <td>{item.debit > 0 ? item.debit : ''}</td>
                                <td>{item.credit > 0 ? item.credit : ''}</td>
                                <td>{item.debit > 0 ? item.debit : item.credit}</td>
                            </tr>
                        ))}
                        {data.length <= 0 && <tr><td colSpan="9"><p className="w-100 text-center pt-5">No Data Found</p></td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>;
}

export default ReportDayBook;
