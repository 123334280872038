import React, { useState, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import Modal from "../../Modal";
import { selectStyle } from "../../../../config/constants";
import { axiosInstance } from "../../../../config/axios";

function DiscardLog(props) {
    const formdata = props.formdata;

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const [dataFilter, setDataFilter] = useState({ search: "", category: [] });
    const [data, setData] = useState([]);
    const [mdlFilter, setMdlFilter] = useState(false);
    const [pagination, setPagination] = useState({
        pageNo: 0,
        noOfPages: 0,
        paging: "0-0 of 0",
    });

    const fetchData = () => {
        console.log(endDate);
        axiosInstance
            .post("/inventory/audit/list-discarded", {
                ...dataFilter,
                startDate: startDate
                    ? new Date(startDate)
                          .toLocaleDateString("en-GB")
                          .replace(/\//g, "-")
                    : null,
                endDate: endDate
                    ? new Date(endDate)
                          .toLocaleDateString("en-GB")
                          .replace(/\//g, "-")
                    : null,
                pageNo: pagination.pageNo,
            })
            .then(function (response) {
                if (response.data.status === "success") {
                    setPagination({
                        ...pagination,
                        ...{
                            noOfPages: response.data.data.noofpages,
                            paging: `${response.data.data.offset}-${response.data.data.limit} of ${response.data.data.count}`,
                        },
                    });
                    setData(response.data.data.rows);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchData();
    }, [dataFilter, pagination.pageNo]);

    const showDialogue = (item) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, restore it!",
            cancelButtonText: "No, cancel!",
            showLoaderOnConfirm: true,
        }).then((result) => {
            if (result.isConfirmed) {
                axiosInstance
                .post("/inventory/audit/restore-discarded", {
                    id: item.id,
                }).then((res) => {
                    fetchData();
                    if (res.data.status === "success") {
                        Swal.fire("Success!", res.data.message, "success");
                    } else {
                        Swal.fire("Failed!", res.data.message, "warning");
                    }
                }).catch((e) => console.log(e));
            }
        });
    };

    return (
        <div>
            <div className="row">
                <div className="col col-sm-8 input-group d-flex justify-content-start">
                    <div className="col-md-4 col-11 px-0 input-icon table-head-search">
                        <input
                            type="text"
                            className="form-control"
                            onChange={(e) =>
                                setDataFilter({
                                    ...dataFilter,
                                    search: e.target.value,
                                })
                            }
                            value={dataFilter.search}
                            placeholder="Search..."
                        />
                        <span className="input-icon-addon">
                            <i className="fe fe-search"></i>
                        </span>
                    </div>
                    <span className="input-group-append">
                        <button
                            onClick={() => setMdlFilter(true)}
                            className="btn c-red text-white"
                            type="button"
                        >
                            <i className="fe fe-filter"></i>
                        </button>
                    </span>
                </div>
                <div className="col-2 col-sm-4 d-flex justify-content-end mb-1 table-nav">
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            if (pagination.pageNo > 0)
                                setPagination({
                                    ...pagination,
                                    pageNo: --pagination.pageNo,
                                });
                        }}
                    >
                        <i className="fe fe-chevron-left pr-sm-1 pr-2"></i>
                    </a>
                    <span className="px-1 d-none d-sm-block">
                        {pagination.paging}
                    </span>
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            if (pagination.pageNo < pagination.noOfPages)
                                setPagination({
                                    ...pagination,
                                    pageNo: ++pagination.pageNo,
                                });
                        }}
                    >
                        <i className="fe fe-chevron-right pl-sm-1 pl-2"></i>
                    </a>
                </div>
            </div>

            <div className="card">
                <div className="card-body px-1">
                    <div className="table-responsive ast-table">
                        <table className="table table-striped table-vcenter mb-0 text-nowrap">
                            <thead>
                                <tr>
                                    <th className="col-1">Variant</th>
                                    <th className="text-center">Quantity</th>
                                    <th>Reason</th>
                                    <th>Date</th>
                                    <th>Stock Id</th>
                                    <th>UPC</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((itm, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{itm.variant}</td>
                                            <td className="text-center">
                                                {itm.qnty}
                                            </td>
                                            <td>{itm.reason}</td>
                                            <td>{itm.createdOn}</td>
                                            <td>{itm.stockId}</td>
                                            <td>{itm.stockUPC}</td>
                                            <td>
                                                {!itm.restored ? (
                                                    <button
                                                        className="btn c-blue text-white btn-sm"
                                                        onClick={() => {
                                                            showDialogue(itm);
                                                        }}
                                                    >
                                                        Restore
                                                    </button>
                                                ) : (
                                                    ""
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Modal
                title="FILTER"
                itemwidth="modal-sm"
                isOpen={mdlFilter}
                onClose={() => setMdlFilter(false)}
            >
                <div className="modal-body">
                    <div className="row">
                        <div className="col-12">
                            <label className="form-label-small w-100">
                                Category
                            </label>
                            <div className="form-group">
                                <Select
                                    options={formdata.category}
                                    styles={selectStyle}
                                    value={dataFilter.category}
                                    onChange={(val) => {
                                        setDataFilter({
                                            ...dataFilter,
                                            category: val,
                                        });
                                    }}
                                    isMulti
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <label className="form-label-small w-100">
                                Date
                            </label>
                            <div className="form-group">
                                <DatePicker
                                    className="form-control"
                                    selectsRange={true}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="Select Date"
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            setMdlFilter(false);
                            fetchData();
                        }}
                    >
                        Search
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default DiscardLog;
