import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { axiosInstance } from "../../../config/axios";
import { selectStyle, reportFilter } from '../../../config/constants'
import "react-datepicker/dist/react-datepicker.css";

function ReportCashFlow() {

    const [filter, setFilter] = useState({
        duration: { value: 'this-year', label: 'This Year'},
        startDate: null,
        endDate: null,
    })
    const [activeDate, setActiveDate] = useState({
        startDate: null,
        endDate: null
    })
    const [data, setData] = useState({
        operating: [],
        investing: [],
        financing: [],
        totIncome: 0
    });

    const fetchData = () => {
        axiosInstance.post("/reports/accounts/cash-flow", filter)
        .then(function (response) {
            if(response.data.status == 'success') {
                let vrTotal = {
                    totOp: response.data.operating.reduce((total, obj)=> total += obj.total, 0),
                    totIn: response.data.investing.reduce((total, obj)=> total += obj.total, 0),
                    totFn: response.data.financing.reduce((total, obj)=> total += obj.total, 0),
                }
                setData({
                    operating: response.data.operating,
                    investing: response.data.investing,
                    financing: response.data.financing,
                    totOp: vrTotal.totOp.toFixed(2),
                    totIn: vrTotal.totIn.toFixed(2),
                    totFn: vrTotal.totFn.toFixed(2),
                });
                setActiveDate({
                    startDate: response.data.startDate,
                    endDate: response.data.endDate
                });
            }            
        }).catch(err=>console.log(err));
    };

    useEffect(() => {
        fetchData();
    }, [filter]);

    const history = useHistory();
    const openAccntTrans = (accntId) => {
        const qp = new URLSearchParams();
        qp.append('account', accntId);
        qp.append('start', activeDate.startDate);
        qp.append('end', activeDate.endDate);
        history.push(`/reports/accounts/general-ledger-info?${qp.toString()}`);
    }

    return <>
        <div className="row">
            <div className="col col-md-12 input-group d-flex justify-content-start">
                <div className="col-md-4 col-11 px-0 input-icon table-head-search py-0">
                    <Select
                        placeholder="Select"
                        options={reportFilter}
                        styles={selectStyle}
                        value={filter.duration}
                        onChange={(val)=>{
                            setFilter({...filter, ...{duration: val, startDate: null, endDate: null}})
                        }}
                    />
                </div>
                <div className="col-md-4 col-11 px-0 input-icon table-head-search py-0 pl-1">
                    <DatePicker className="form-control" 
                    selectsRange={true}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Date"
                    startDate={filter.startDate} endDate={filter.endDate}
                    onChange={(update) => {
                        setFilter({...filter, ...{ startDate:update[0], endDate:update[1]}})
                    }} isClearable={true} disabled={filter.duration.value == 'custom' ? false : true} />
                </div>
                <span className="input-group-append dropdown"><button className="btn c-lgt-blue text-white" type="button">Download &nbsp; <i className="fe fe-download"></i></button></span>
            </div>
        </div>
        <div className="card">
            <div className="card-status c-blue top-layer"></div>
            <div className="card-body px-1 h-80-scroll">
                <div className="table-responsive ast-table h-100">
                    <table className="table table-vcenter mb-0 text-nowrap">
                        <thead>
                            <tr>
                                <th>Account</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="tbl-grey-head">
                                <td>Beginning Cash Balance</td>
                                <td>0.00</td>
                            </tr>
                            <tr className="tbl-grey-head">
                                <td colSpan={2}>Cash Flow from Operating Activities</td>
                            </tr>
                            {data.operating.map((item, i)=>(
                            <tr>
                                <td className="c-pointer" onClick={()=>openAccntTrans(item.accntId)}>{item.account}</td>
                                <td>{item.total.toFixed(2)}</td>
                            </tr>
                            ))}
                            <tr className="tbl-sub">
                                <td>Net cash provided by Operating Activities</td>
                                <td>{data.totOp}</td>
                            </tr>

                            <tr className="tbl-grey-head">
                                <td colSpan={2}>Cash Flow from Investing Activities</td>
                            </tr>
                            {data.investing.map((item, i)=>(
                            <tr>
                                <td className="c-pointer" onClick={()=>openAccntTrans(item.accntId)}>{item.account}</td>
                                <td>{item.total.toFixed(2)}</td>
                            </tr>
                            ))}
                            <tr className="tbl-sub">
                                <td>Net cash provided by Investing Activities</td>
                                <td>{data.totIn}</td>
                            </tr>

                            <tr className="tbl-grey-head">
                                <td colSpan={2}>Cash Flow from Financing Activities</td>
                            </tr>
                            {data.financing.map((item, i)=>(
                            <tr>
                                <td className="c-pointer" onClick={()=>openAccntTrans(item.accntId)}>{item.account}</td>
                                <td>{item.total.toFixed(2)}</td>
                            </tr>
                            ))}
                            <tr className="tbl-sub">
                                <td>Net cash provided by Financing Activities</td>
                                <td>{data.totFn}</td>
                            </tr>

                            <tr className="tbl-sub">
                                <td className="text-right">Net Change in cash</td>
                                <td>0.00</td>
                            </tr>
                            <tr className="tbl-sub">
                                <td className="text-right">Ending Cash Balance	</td>
                                <td>0.00</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>;
}

export default ReportCashFlow;
